.answer-form {
  margin-top: 30px;
  background: #ececec;
  padding: 16px 21px;
  .grid {
    .row {
      flex-basis: 48%;
      button {
        margin-top: 10px;
        font-size: 13px;
        line-height: 13px;
      }
    }
  }
  .form-group {
    label {
      margin: 5px 0;
    }
    .form-control {
      background: #fff;
      padding: 0 20px;
      &.custom-select {
        padding: initial;
        .current-value {
          padding: 0 20px;
        }
      }
    }
    textarea.form-control {
      padding: 20px;
    }
    p.help-block-error {
      a {
        @include default-link($link-secondary-color);
      }
    }
  }
  .custom-select {
    .dropdown {
      background-color: #fff;
      border: 1px solid #e9e9e9;
      .form-group {
        padding: 7px;
        .form-control {
          border: 2px solid #ececec;
        }
      }
      ul {
        background: none;
        li {
          padding: 5px 20px;
          //font-size: 15px;
          color: $secondary-color;
          &:hover {
            background-color: #dfdfdf;
          }
          &.is-active {
            background-color: #daeaf5;
          }
        }
      }
      .empty-result {
        padding: 5px 20px;
      }
    }
  }
}