@include media-breakpoint-down(sm) {
  @include block('site-error') {
    padding: 0 15px;
    margin-bottom: 20px;
    @include element('container') {
      flex-wrap: wrap;
      justify-content: center;
      padding: 15px;
    }
    @include element('image') {
      padding: 0 35px;
    }
    @include element('content') {
      text-align: center;
      padding: 0;
    }
  }
}