@include media-breakpoint-only(lg) {
  .profile {
    .menu-toggle-container {
      margin-bottom: 16px;
      .toggle-btn {
        display: none;
      }
      ul.nav {
        display: flex;
        justify-content: space-around;
        padding: 0;
        background: #fff;
        border-bottom: 1px solid #ebebeb;
        li {
          &:not(:last-child) {
            margin-bottom: 0;
          }
          &.active {
            position: relative;
            margin-bottom: -1px;
            border-bottom: 1px solid #afafaf;
            &::after {
              position: absolute;
              display: block;
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 7px 6.5px 0 6.5px;
              border-color: #afafaf transparent transparent transparent;
              left: 50%;
              margin-left: -4px;
            }
          }
          span {
            text-align: center;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .profile {
    padding: 0 15px;
    .menu-toggle-container {
      margin-bottom: 16px;
      position: relative;
      .toggle-btn {
        display: flex;
        width: 100%;
        height: 61px;
        i.icon {
          display: block;
          margin: auto;
        }
      }
      .toggle-content {
        position: absolute;
        width: 100%;
        display: none;
        z-index: 1;
        &.is-show {
          display: block;
        }
      }
    }

    > .grid .profile-content .orders-container .orders-list {
      .order-item {
        .order-panel {
          .id {
            flex-basis: 15%;
          }
          .date {
            flex-basis: 12%;
          }
          .completed {
            flex-basis: 22%;
          }
        }
        .order-info .order-statuses .order-status:not(:last-child):after {
          margin: auto 0;
        }
      }
    }
  }
  .wishlist-container .offers-list-container .offers-list {
    justify-content: space-around;
    .offer-item {
      width: 40%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .profile {
    .panel {
      .icon-wrapper {
        span.label {
          display: none;
        }
      }
    }

    > .grid .profile-content .orders-container .orders-list {
      .order-item {
        .order-panel {
          flex-wrap: wrap;
          height: auto;
          padding: 0 25px;
          > div {
            padding: 10px 0;
            flex-basis: 33% !important;
            span {
              line-height: 22px;
              &.label {
                display: block !important;
                font-weight: bold;
              }
            }
          }
        }

        .order-info {
          .delivery {
            .grid {
              flex-wrap: wrap;
              .row {
                width: 100%;
              }
            }
          }
          .order-statuses {
            &:empty {
              display: none;
            }
            flex-wrap: wrap;
            padding: 10px;
            .order-status {
              flex-wrap: wrap;
              width: 100%;
              &:not(.is-completed) {
                display: none;
              }
              .inner {
                width: 100%;
                display: flex;
                align-items: center;
                .date {
                  margin-bottom: 0;
                  padding-right: 15px;
                }
                .icon-container {
                  width: 90px;
                  height: auto;
                  flex-shrink: 0;
                  .icon-order-checked {
                    display: none;
                  }
                }
              }
              &:not(:last-child):after {
                margin: 0 0 0 42px;
                content: "\2022";
              }
            }
          }

          .cart-items {
            &.table-view {
              display: none;
            }
            &.grid-view {
              display: block;
              padding: 17px;
              > header {
                font-weight: bold;
                margin-bottom: 20px;
              }
              .cart-item {
                &:last-child {
                  .separator {
                    display: none;
                  }
                }
                .info {
                  display: flex;
                  align-items: center;
                  .image {
                    flex-shrink: 0;
                  }
                  .name {
                    padding-left: 10px;
                    flex-grow: 1;
                    a {
                      @include default-link($link-secondary-color);
                    }
                  }
                }
                .price {
                  display: flex;
                  justify-content: space-between;
                  margin: 12px 0;
                  span {
                    font-weight: bold;
                  }
                }
                .separator {
                  width: 80%;
                  margin: 20px auto;
                  height: 0;
                  border-bottom: 1px solid #e0e0e0;
                }
              }
            }
          }

          .order-rating {
            .grid {
              .row {
                &:first-child {
                  display: none;
                }
              }
            }

            .rating-comment {
              flex-wrap: wrap;
              .form-control {
                height: 100px;
              }
              button {
                width: 100%;
              }
            }
          }

          .actions-container {
            .grid {
              flex-wrap: wrap;
              justify-content: center;
              .gifts {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .account-container form .grid .row,
    .password-container form .grid .row {
      flex-basis: 100%;
    }

    .wishlist-container {
      .actions {
        flex-wrap: wrap;
        .askbuy {
          width: 100%;
          order: 2;
          margin: 15px 0;
          .icon-wrapper {
            justify-content: center;
          }
        }
      }
      .offers-list-container {
        .offers-list {
          padding: 0 30px;
          justify-content: center;
          .offer-item {
            width: 100%;
          }
        }
      }
    }

    .addressbook-container {
      .profiles-list-container {
        .profiles-list {
          flex-wrap: wrap;
          .profile-item {
            width: 100%;
          }
        }
      }
      .profiles-list-select {
        padding: 0 20px;
        margin-top: 10px;
        .form-group {
          label {
            margin-bottom: 5px;
          }
        }
        .custom-select {
          background: #fff;
          .current-value {
            padding: 0 10px;
          }
          .dropdown {
            background-color: #fff;
            border: 1px solid #e9e9e9;
            .form-group {
              padding: 7px;
              .form-control {
                border: 2px solid #ececec;
              }
            }
            ul {
              background: none;
              li {
                padding: 0 10px;
                //font-size: 15px;
                color: $secondary-color;
                &:hover {
                  background-color: #dfdfdf;
                }
                &.is-active {
                  background-color: #daeaf5;
                }
              }
            }
            .empty-result {
              padding: 5px 20px;
            }
          }
        }

        .btn {
          .icon-wrapper {
            justify-content: center;
            span {
              padding-right: 10px;
            }
          }
          &.btn-link {
            .icon-wrapper {
              span {
                font-weight: bold;
              }
            }
          }
        }
      }
      .edit-form {
        .grid {
          flex-wrap: wrap;
          .row {
            width: 100%;
          }
        }
        form {
          .form-group {
            .btn-block {
              width: 100%;
            }
          }
        }
      }
    }

    .popup {
      margin-top: 0;
    }
  }

  @include block('bonus-history') {
    @include element('content') {
      flex-wrap: wrap;
      padding: 0 15px;
    }
    @include element('group') {
      width: 50%;
      margin-bottom: 10px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .profile {
    .panel {
      padding: 0 20px;
      .total-container {
        span {
          &.label {
            display: block;
            font-weight: bold;
          }
        }
      }
      .logout-container {
        span {
          display: none;
        }
      }
    }

    .filters-select {
      padding: 0 20px;
      .custom-select {
        margin-top: 10px;
        .current-value {
          background: #fff;
          padding: 0 10px;
        }
        .dropdown {
          background-color: #fff;
          border: 1px solid #e9e9e9;
          .form-group {
            padding: 7px;
            .form-control {
              border: 2px solid #ececec;
            }
          }
          ul {
            background: none;
            li {
              padding: 0 10px;
              //font-size: 15px;
              color: $secondary-color;
              &:hover {
                background-color: #dfdfdf;
              }
              &.is-active {
                background-color: #daeaf5;
              }
            }
          }
          .empty-result {
            padding: 5px 20px;
          }
        }
      }
    }

    > .grid .profile-content .orders-container .orders-list {
      .order-item {
        .order-panel {
          padding: 0 12px;
          > div {
            flex-basis: 32% !important;
          }
        }
        .order-info {
          .order-statuses .order-status .inner .date {
            padding-right: 0;
          }
          .order-rating .grid .row span {
            display: none;
          }
        }
      }
    }

    .wishlist-container {
      padding: 10px;
      .actions {
        padding: 10px;
        .row {
          width: 100%;
          margin: 10px 0;
          &.askbuy {
            .icon-wrapper {
              justify-content: flex-start;
            }
          }
        }
      }
      .offers-list-container {
        padding: 0;
        .offers-list {
          padding: 0;
        }
      }
    }
  }
}