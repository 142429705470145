@include block('instagram-posts') {
  margin-bottom: 60px;
  @include element('header') {
    margin-bottom: 10px;
    .grid {
      align-items: center;
      flex-wrap: wrap;
    }
  }
  @include element('slide') {
    text-align: center;
  }
  @include element('follow-link') {
    padding: 12px 30px;
  }
  @include element('post-link') {
    @include image(auto, 320px);
  }

  @include block('header') {
    display: flex;
    align-items: center;
    background: #464646;
    padding: 8px 15px;
    @include element('icon') {
      img {
        display: block;
        width: 50px;
        height: 50px;
      }
    }
    @include element('text') {
      padding: 0 15px;
      flex-grow: 1;
    }
    @include element('link') {
      @include default-link(#fff);
      font-size: 16px;
    }
  }

  @include block('info') {
    display: flex;
    @include element('column') {
      text-align: center;
      padding: 15px;
    }
    @include element('count') {
      font-weight: bold;
    }
    @include element('label') {
      font-size: 13px;
      color: #cecece;
    }
  }
}