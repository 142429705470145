@include block('product-wrapper') {
  position: relative;
  width: 270px;
  min-height: $product-item-height;
}

@include block('product-item') {
  pointer-events: auto;
  position: relative;
  margin-bottom: 20px;
  @include element('background') {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    right: -5px;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.5);
    transition: all 0.3s;
    background-color: #fff;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.25);
  }

  @include element('visible') {
    position: relative;
  }

  @include element('image') {
    @include image(100%, 280px);
  }

  @include element('code') {
    font-size: 12px;
    width: 124px;
    margin: 0 auto;
    border: 2px dotted #ffaf8b;
    text-align: center;
    line-height: 17px;
    color: $secondary-color;
    > span {
      color: #3c3c3c;
    }
  }

  @include element('names') {
    display: block;
    margin-top: 5px;
    text-align: center;
    height: 90px;
    @include default-link(#333333);
    span {
      display: block;
      overflow: hidden;
    }
  }
  @include element('alias-name') {
    margin-top: 5px;
    color: $secondary-color;
    padding: 0 2px;
  }

  @include element('unavailable-text') {
    color: #333333;
    font-size: 15px;
    text-align: center;
    min-height: 32px;
    line-height: 32px;
  }

  @include element('hidden') {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    backface-visibility: hidden;
    transition: all 0.3s;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .buy-container {
      width: 193px;
      margin: 12px auto;
      justify-content: center;
      .row:last-child {
        flex-grow: 1;
      }
      .wishlist-link,
      .preview-link {
        margin-right: 27px;
      }
      .btn-buy {
        font-size: 12px;
      }
    }
    .icon-wrapper {
      justify-content: center;
    }
    .available-reminder-container {
      padding: 0 27px;
      margin: 13px 0;
      .btn {
        width: 216px;
      }
    }
  }

  @include block('product-bonus') {
    position: absolute;
    right: 16px;
    top: 12px;
  }

  @include block('product-price') {
    justify-content: center;
    margin-top: 6px;
  }

  @include state('active') {
    width: 100%;
    z-index: 2;
    @include block('product-item') {
      @include element('background') {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      @include element('overlay') {
        display: none;
      }
      @include element('hidden') {
        opacity: 1;
        z-index: 2;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  @include state('unavailable') {
    @include block('product-item') {
      @include element('image') {
        opacity: 0.45;
      }
    }
  }

  @include modifier('average') {
    @include block('product-price') {
      margin-top: 14px;
    }
    @include block('offers-chooser') {
      margin: 14px 0;
    }
    .btn-small {
      font-size: 13px;
    }
    @include block('product-item') {
      @include element('image') {
        height: 200px;
      }
      @include element('names') {
        //height: auto;
      }
    }
  }
}

@include block('product-bonus') {
  @include element('value') {
    color: #e57521;
    margin-right: 5px;
  }
}

@include block('product-price') {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 33px;
  font-size: 15px;

  @include modifier('center') {
    justify-content: center;
  }

  @include element('label') {
    margin-right: 5px;
  }

  @include element('current') {
    color: #333;
  }

  @include state('discount', 'has') {
    @include block('product-price') {
      @include element('current') {
        border: 1px solid #e9bac4;
        color: #d98596;
        padding: 7px 11px;
        border-radius: 2px;
        > span {
          font-weight: 600;
        }
      }

      @include element('old') {
        color: #d0d0d0;
        text-decoration: line-through;
        margin-left: 9px;
      }
    }
  }

  @include element('economy') {
    width: 100%;
    font-size: 13px;
    padding: 7px 7px 0;
  }

  @include modifier('large') {
    @include block('product-price') {
      font-size: 17px;
      @include element('current') {
        font-size: 19px;
        > span {
          font-weight: 600;
        }
      }
    }
  }
}

.product-wrapper {
  .product-item {
    .label-offer-container {
      position: absolute;
      top: 5px;
      left: 5px;
      justify-content: center;
      width: 100%;
      .label {
        color: #fff;
        padding: 6px 19px;
        font-weight: 300;
        &.label-discount {
          $r: 50px;
          background-color: rgba(241, 67, 75, 0.7);
          border-radius: 100%;
          height: $r;
          width: $r;
          text-align: center;
          line-height: $r;
          font-size: 17px;
          padding: 0;
        }
      }
    }
    .label-product-container {
      position: absolute;
      top: 11px;
      left: 15px;
    }
    .rating-widget {
      margin: 5px auto;
    }
  }
}

.modal-offer-choose {
  .modal-content {
    width: 731px;
    .modal-title {
      text-align: left;
      padding-top: 0;
      font-size: 14px;
      h3 {
        font-size: 15px;
        color: #333333;
        margin-bottom: 8px;
      }
      h4 {
        font-size: 13px;
        color: $secondary-color;
      }
      .row {
        &.names {
          flex-basis: 70%;
        }
        &.info {
          margin-right: 47px;
          .code {
            font-size: 12px;
            color: $secondary-color;
            margin-bottom: 8px;
            > span {
              color: #333333;
            }
          }
        }
      }
    }
    .chooser {
      .list-scrollbar {
        max-height: 300px;
      }
      .preview,
      .list-vertical {
        background-color: #fff;
      }
      .preview {
        @include image(266px, auto);
      }
      .list-vertical {
        flex-basis: 426px;
        ul {
          padding: 14px 30px;
          li {
            display: flex;
            align-items: center;
            color: $secondary-color;
            height: 40px;
            margin: 1px 0;
            &.is-active {
              background-color: #f3f3f5;
            }
            &.is-disabled:not(.is-active) {
              opacity: 0.3;
            }
            &:hover {
              cursor: pointer;
            }
            img {
              max-width: 40px;
              max-height: 40px;
            }
            span {
              padding: 13px 19px;
              &.icon-container {
                padding: 0 15px 0 0;
                margin-left: auto;
              }
            }
          }
        }
      }
      .list-horizontal {
        margin-top: 2px;
        background: #fff;
        padding: 0 30px;
        max-height: 120px;
        &.is-scrollable {
          position: relative;
          max-width: 100%;
          &:before,
          &:after {
            position: absolute;
            display: block;
            content: '';
            background: url('/build/images/vue-slider-arrows.png') no-repeat 0 0;
            width: 10px;
            height: 18px;
            top: calc(50% - 18px);
            opacity: 0.5;
          }
          &:before {
            left: 12px;
            background-position: -6px -31px;
          }
          &:after {
            right: 12px;
            background-position: -20px -31px;
          }
          ul {
            overflow: auto;
            justify-content: flex-start;
          }
        }
        ul {
          //padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          //flex-wrap: wrap;
          li {
            position: relative;
            color: $secondary-color;
            flex-basis: 75px;
            min-width: 75px;
            text-align: center;
            padding: 10px 0;
            border: 4px solid transparent;
            //transition: 0.3s all ease;
            &.is-active {
              border-color: #f3f3f5;
              padding: 10px;
              //margin: 10px 0;
            }
            &.is-disabled:not(.is-active) {
              opacity: 0.3;
            }
            > span {
              display: block;
              &.image {
                width: 47px;
                margin: 0 auto;
              }
              &.name {
                padding-top: 8px;
              }
              &.icon-container {
                position: absolute;
                top: 3px;
                left: 12px;
              }
              img {
                //max-width: 46px;
                max-width: 100%;
                width: auto;
              }
            }
          }
        }
      }
      .current-offer-fullname {
        width: 100%;
        margin-top: 2px;
        background-color: #fff;
        text-align: center;
        padding: 14px 0;
      }
    }
    .prices {
      justify-content: center;
      align-items: baseline;
      font-style: italic;
      margin-top: 25px;
      margin-bottom: 13px;
      .price-old {
        color: #f16e26;
        font-size: 17px;
        text-decoration: line-through;
        opacity: 0.3;
        margin-right: 8px;
      }
      .price-current {
        font-size: 23px;
        color: #333333;
        margin-right: 8px;
      }
      .discount {
        font-size: 13px;
        color: $secondary-color;
      }
    }
    .unavailable-text {
      margin-top: 32px;
      margin-bottom: 16px;
      text-align: center;
      color: #333333;
    }
  }
}
