


































































/*.v-spinner
{
    margin: 100px auto;
    text-align: center;
}
*/

@-webkit-keyframes v-pulseStretchDelay
{
    0%,
    80%
    {
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-opacity: 1;             
                opacity: 1;
    }
    45%
    {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
        -webkit-opacity: 0.7;             
                opacity: 0.7;
    }
}

@keyframes v-pulseStretchDelay
{
    0%,
    80%
    {
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-opacity: 1;             
                opacity: 1;
    }
    45%
    {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
        -webkit-opacity: 0.7;             
                opacity: 0.7;
    }
}
























/*# sourceMappingURL=question_item.vue.map */

/*# sourceMappingURL=FormWizard.vue.map */


/*# sourceMappingURL=WizardButton.vue.map */


/*# sourceMappingURL=WizardStep.vue.map */
