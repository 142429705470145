@include media-breakpoint-down(lg) {
  .slider-content {
    margin: 12px auto;
    .slider-wrapper {
      .VueCarousel-slide {
        img {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .slider-content {
    margin: 0;
    .slider-wrapper {
      max-width: inherit;
      //width: 100%;
      .thumbnails {
        ul > li {
          padding: 0 5px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .slider-content {
    .slider-wrapper {
      .VueCarousel-pagination {
        display: inherit;
      }
      .thumbnails {
        display: none;
      }
    }
  }
}
