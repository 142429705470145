/* Глобальные настройки */
@import "variables";

/* Различные миксины */
@import "mixins/mixins";

/* Различные хелперы */
@import "utils/helpers";

/* Подлкючаем компоненты */
@import "components/icons";
@import "components/buttons";
@import "components/inputs";
@import "components/modals";
@import "components/tabs";
@import "components/popup";
@import "components/product_item";
@import "components/scrollbar";
@import "components/tooltip";
@import "components/main_menu";
@import "components/swipe";
@import "components/pagination";
@import "components/cart";
@import "components/breadcrumbs";
@import "components/zoomer";
@import "components/product_informer";
@import "components/stars_rating";
@import "components/rating_widget";
@import "components/progressbar";
@import "components/review_item";
@import "components/custom_select";
@import "components/answer_form";
@import "components/answer_item";
@import "components/wizard";
@import "components/custom_checkbox";
@import "components/offer_item";
@import "components/offers_chooser";
@import "components/available_informer";
@import "components/show_more";
@import "components/instagram_posts";
@import "components/search";
@import "components/questions";
@import "components/lottery";
@import "components/stock-list";

/* Страницы сайта */
@import "pages/index";
@import "pages/catalog";
@import "pages/product";
@import "pages/signup";
@import "pages/order";
@import "pages/order_make";
@import "pages/news";
@import "pages/articles";
@import "pages/profile";
@import "pages/stock";
@import "pages/contacts";
@import "pages/delivery";
@import "pages/brand";
@import "pages/search";
@import "pages/error";

* {
  box-sizing: border-box;
}
html,
body {
  background-color: #fff;
  //font-family: 'Open Sans', sans-serif;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  color: $main-color;
  //line-height: 18px;
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
}

html {
  &.is-blocked {
    overflow: hidden;
    touch-action: none;
  }
}

.schema {
  display: none;
}

@keyframes pagepreloader {
  0%   { transform: scale(0.5); }
  50% { transform: scale(1); }
  100% { transform: scale(0.5); }
}

.site-informer {
  background: #f44336;
  color: #fff;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  height: 22px;
  line-height: 22px;
  padding: 0 15px;
  .icon-wrapper {
    justify-content: center;
    span {
      padding: 0 5px;
    }
  }
}
.top-menu-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  .notification {
    text-align: center;
    padding: 5px;
    &.warning {
      color: #2731da;
      background: #e8e525;
    }
    &.danger {
      color: #ffff;
      background: #b62c25;
      border-bottom: 3px dotted #1e1e1e;
    }
  }
}
.top-menu {
  //top: 22px;
  background: #1e1e1e;
  height: 38px;
  line-height: 38px;
  color: #fff;
  //padding-left: 35px;
  > .grid {
    > .row {
      &:first-child {
        //flex-basis: 43%;
      }
      &:nth-child(2) {
        flex-grow: 1;
      }
      &:not(:last-child) {
      }
      .city-chooser-container {
        position: relative;
        .city-chooser {
          //flex-basis: 41%;
          span {
            font: {
              size: 14px;
            }
            padding-bottom: 2px;
            border-bottom: 1px dotted #ffffff;
            margin-right: 15px;
            &:after {
              position: absolute;
              margin-left: 6px;
              content: "\25BC";
              color: #ffffff;
              float: right;
              font-size: 8px;
              margin-right: 10px;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
        .popup-city-chooser,
        .popup-city-confirm {
          position: absolute;
          margin-top: -4px;
          z-index: 5;
          background: #fff;
          border: 1px solid #b6b6b6;
          font-size: 14px;
          padding: 14px 12px;
          .popup-close {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
          }
        }
        .popup-city-confirm {
          .grid {
            justify-content: flex-start;
            > .row:last-child {
              padding-left: 15px;
            }
          }
          p {
            color: $main-color;
            line-height: normal;
            margin-bottom: 8px;
            span {
              color: #000000;
            }
          }
          .grid {
            width: 305px;
            align-items: baseline;
            button {
              width: 44px;
              height: 44px;
              font-size: 14px;
            }
            a {
              text-decoration: none;
              color: $important_color;
              padding-bottom: 2px;
              border-bottom: 1px dotted $important_color;
            }
          }
        }
        .popup-city-chooser {
          width: 350px;
          color: $main-color;
          > div {
            position: relative;
            .v-spinner {
              position: absolute;
              right: 7px;
              top: 1px;
            }
          }
          input[type="text"] {
            border: 1px solid #c0c0c0;
            background: #fff;
            padding: 8px;
            box-shadow: none;
            width: 100%;
            font-size: 13px;
            line-height: normal;
            height: auto;
            box-sizing: border-box;
            margin-bottom: 10px;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              //flex-basis: 50%;
              width: 50%;
              line-height: 25px;
              a {
                &:before {
                  display: inline-block;
                  content: '-';
                  padding-right: 5px;
                }
                text-decoration: none;
                color: $main-color;
                display: block;
                white-space: nowrap; /* Запрещаем перенос строк */
                overflow: hidden; /* Обрезаем все, что не помещается в область */
                text-overflow: ellipsis; /* Добавляем многоточие */
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .auth-form-container {
        position: relative;
        .profile-link {
          text-align: center;
          a {
            display: inline-block;
            color: #fff;
            text-decoration: none;
            //width: 150px;
            text-align: center;
            span {
              padding-left: 5px;
            }
          }
        }
        .modal-sticked {
          height: auto;
          line-height: normal;
          margin-left: -250px;
          left: 50%;
          .modal-content {
            width: 500px;
            .grid {
              margin-top: 28px;
              line-height: normal;
            }
            a {
              color: #4a4a4a;
              text-decoration: none;
              margin: 0 20px;
            }
            .help-block-error {
              text-align: center;
            }
            .loader {
              text-align: center;
            }
          }
        }
        .panel-info {
          padding: 15px;
          margin-top: 28px;
          background: #da4040;
          color: #fff;
          p.title {
            font-weight: bold;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

@include block('landing-link') {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #fff no-repeat fixed 50% 0;
}

@include block('layout') {
  display: flex;
  flex-direction: column;
  .page-preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #e8e8e8;
    font-size: 24px;
    font-weight: 300;
    > img {
      animation: pagepreloader 2s infinite;
    }
  }
  @include element('header') {
    //padding-top: 60px;
    padding-top: 38px;
    flex-shrink: 0;
    .middle-menu {
      > .grid {
        align-items: center;
        height: 140px;
        .search-wrapper {
          position: relative;
          width: 490px;
          .search-input {
            padding: 15px 45px 15px 15px;
            border: 1px solid #c0c0c0;
            box-shadow: none;
            line-height: normal;
            background: #fff;
            height: auto;
            width: 100%;
            box-sizing: border-box;
          }
          .search-submit {
            position: absolute;
            top: 14px;
            right: 14px;
            width: 26px;
            height: 26px;
            //noinspection CssUnknownTarget
            background: url(/build/images/search_title_icon.png) 0 0 no-repeat;
            &:hover {
              cursor: pointer;
            }
          }
          .search-input:focus + .search-submit {
            background-position: 0 -26px;
          }
        }
        .phones {
          p {
            line-height: 23px;
            a {
              text-decoration: none;
              color: inherit;
            }
          }
        }
        .request-call-container {
          .request-call {
            a {
              color: $main-color;
              font-size: 14px;
              text-decoration: none;
              padding-bottom: 2px;
              border-bottom: 1px dotted $main-color;
            }
          }
          .request-call-mobile {
            //padding: 0 35px 0 20px;
            //border-left: 1px solid #949494;
            padding-right: 35px;
          }
        }
      }
      &.mobile {
        > .grid {
          justify-content: space-between;
          align-items: center;
          .row {
            &.actions {
              padding: 0 25px;
            }
            &.mobile-logo {
              flex-grow: 1;
              text-align: center;
              .mobile-only {
                display: none;
              }
              .desktop-only {
                display: block;
              }
            }
            &.work-time {
              //flex-basis: 150px;
            }
            p:not(:last-child) {
              margin-bottom: 8px;
            }
            .request-call-container {
              .request-call-mobile {
                padding-right: 15px;
              }
            }
          }
        }
      }
    }
  }
  .mobile-menu {
    .swipe-content {
      background: rgba(24, 24, 24, 0.95);
      width: 460px;
      transform: translateX(-460px);
      > header {
        margin: 30px 0;
        padding: 0 15px;
        .logo {
          margin-left: 25px;
        }
        .swipe-close {
          margin-top: 20px;
        }
      }
    }
    .mobile-menu-tabs {
      .tabs {
        $color: rgba(101,101,101,0.5);
        padding: 0 15px;
        ul {
          li {
            border: 1px solid $color;
            text-align: center;
            a {
              padding: 11px 0;
              font-size: 16px;
              @include default-link(#585858);
            }
            &.is-active {
              background-color: $color;
              a {
                color: #fff;
              }
            }
          }
        }
      }
      .tabs-content {
        margin-top: 13px;
        .list-scrollbar {
          background: none;
          overflow-y: scroll;
        }
        ul {
          li {
            a {
              display: block;
              font-size: 16px;
              padding: 0 15px;
              //&:hover {
              //  background: rgba(69,69,69,0.5);
              //}
              > span {
                display: block;
                padding: 24px 15px;
                border-bottom: 1px solid #333;
              }
              @include default-link(#fff);
            }
          }
        }
        .child-list {
          li {
            a {
              padding: 0 25px;
            }
          }
        }
        .back-link {
          padding: 16px 34px;
          font-size: 16px;
          > span {
            margin-left: 20px;
          }
          @include default-link(#fff);
        }
      }
    }
  }
  .modal-mobile-search {
    .modal-content {
      width: 460px;
      //padding: 14px 16px;
      border: 1px solid #c0c0c0;
      //border-top: 1px solid #e5e5e5;
      border-top-color: #e5e5e5;
      margin-top: 2px;
      z-index: 9;
      .form-group {
        position: relative;
        //margin-top: 35px;
        .form-control {
          text-align: left;
          padding: 0 20px;
        }
        .icon {
          position: absolute;
          top: 13px;
          right: 10px;
        }
      }
      .v-spinner {
        text-align: center;
      }
      .search-result-item {
        text-decoration: none;
        min-height: 60px;
        margin-bottom: 10px;
        padding: 15px 5px;
        background: #fff;
        p {
          color: #727272;
          &.second-name {
            color: #b2b2b2;
            margin-top: 10px;
          }
        }
        &.grid {
          justify-content: flex-start;
          align-items: center;
          .row {
            &.image {
              flex-basis: 70px;
              text-align: center;
            }
            &.content {
              flex-basis: calc(100% - 70px);
            }
          }
        }
      }
    }
  }

  @include element('body') {
    flex: 1 0 auto;
  }

  @include element('footer') {
    flex: 0 0 auto;
    background: #ececec;
    padding-top: 30px;
    color: $footer-color;
    $footer-padding: 10px;
    .footer-blocks {
      position: relative;
      margin-bottom: 12px;
      header {
        font-size: 15px;
        margin-bottom: 16px;
        font-weight: 300;
      }
      .contacts {
        flex-basis: 200px;
        padding-left: $footer-padding;
        .block {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
          line-height: 26px;
          //color: #ccc;
          .title {
            font-weight: 600;
          }
        }
      }
      .subscribe {
        > header {
          text-align: center;
        }
        .form-container {
          width: 500px;
          background-color: #fff;
          margin-bottom: 8px;
          form {
            input[type="text"] {
              $p: 11px;
              margin: $p 0 $p $p;
              padding-right: $p;
              //width: 320px;
              flex-grow: 1;
              height: 38px;
              line-height: 38px;
              text-align: center;
              border: none;
              outline: none;
              border-right: 2px solid #ececec;
            }
            button {
              width: 160px;
              color: $footer-color;
              cursor: pointer;
            }
          }
          p {
            background: #ececec;
            font-size: 12px;
            padding-top: 4px;
            text-align: center;
            a {
              color: #626161;
              font-size: inherit;
            }
          }
        }
        .phones-container {
          > p {
            text-align: center;
            padding: 16px 0;
          }
          .phones {
            text-align: center;
            width: 250px;
            margin: 0 auto;
            flex-wrap: wrap;
            .row {
              flex-basis: 50%;
              color: $main-color;
              line-height: 24px;
              a {
                color: inherit;
                text-decoration: none;
              }
            }
          }
        }
        .language-container {
          margin-top: 50px;
          text-align: center;
          a {
            color: $footer-color;
            line-height: 26px;
            padding: 0 17px;
            text-decoration: none;
            &:not(:last-child) {
              border-right: 1px solid #b4b4b4;
            }
            &.active {
              opacity: 0.4;
            }
          }
        }
      }
      .abuse {
        flex-basis: 220px;
        > p {
          margin-bottom: 25px;
          text-align: center;
        }
        @include block('social') {
          margin-bottom: 41px;
          justify-content: center;
          display: flex;
          @include element('link') {
            display: flex;
            width: 35px;
            height: 35px;

            background-size: contain;

            @include modifier('facebook') {
              background-image: url("/build/images/facebook.svg");
            }

            @include modifier('google-plus') {
              background-image: url("/build/images/google-plus.svg");
            }

            @include modifier('instagram') {
              background-image: url("/build/images/instagram.svg?v=1");
            }

            @include modifier('twitter') {
              background-image: url("/build/images/twitter.svg");
            }

            @include modifier('telegram') {
              background-image: url("/build/images/telegram.svg");
            }

            @include modifier('pinterest') {
              background-image: url("/build/images/pinterest.svg");
            }

            margin-right: 11px;
            @include state('last') {
              margin-right: 0;
            }
          }
        }
        .btn-inverse {
          color: $footer-color;
          padding: 25px;
          //width: 100%;
        }
      }
      .copyright {
        position: absolute;
        bottom: 0;
        //right: $footer-padding;
        right: 0;
        font-size: 11px;
      }
    }
    .footer-menu {
      //border-top: 1px solid #333;
      padding: 16px 0;
      font-size: 13px;
      background-color: #fff;
      ul {
        display: flex;
        justify-content: flex-start;
        li {
          padding: 0 $footer-padding;
          line-height: normal;
          &:not(:last-child) {
            border-right: 1px solid #ececec;
          }
          a {
            color: $footer-color;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .mobile-action {
    position: fixed;
    //bottom: 110px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: rgba(141, 141, 141, 0.5);
    display: flex;
    transition: 0.3s all ease;
    > i.icon {
      margin: auto;
    }
    &.is-active {
      background-color: #ed6c2c;
    }
    &.move-top {
      left: 20px;
    }
    &.hot-actions {
      right: 20px;
    }
  }

  .mobile-bottom-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 90px;
    > .grid {
      height: inherit;
      background-color: #fff;
      align-items: baseline;
      > .row {
        text-align: center;
        a {
          display: flex;
          height: 100%;
          flex-direction: column;
          padding: 15px;
          @include default-link();
          > span {
            position: relative;
            //flex-grow: 1;
            > span {
              position: absolute;
              width: 21px;
              height: 21px;
              background: #ed6c2c;
              color: #fff;
              line-height: 21px;
              border-radius: 100%;
              margin-left: 6px;
              margin-top: -2px;
            }
          }
          p {
            padding: 12px 0 19px 0;
          }
        }
      }
    }
    //transition: 0.3s all ease;
    //transform: translateY(-90px);
    //&.is-active {
    //  transform: translateY(0px);
    //}
  }

  .seo-text-container {
    margin-bottom: 88px;
    //position: relative;
    &.z-mode {
      .fix-wrapper {
        //z-index: -1;
      }
    }
    //.fix-wrapper {
      //position: relative;
      .seo-text {
        color: #959595;
        column-count: 2;
        column-gap: 35px;
        overflow: hidden;
        //height: 78px;
        overflow-y: hidden;
        //transition: all ease 0.3s; /* not working */
        @include editor-content();

        .no-margin {
          margin: 0 !important;
        }

        &.is-active {
          height: auto;
        }
      }
      .overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }
    //}
    .show-more-container {
      position: relative;
      z-index: 0;
      padding: 31px 0 0;
      display: flex;
      justify-content: center;
      a {
        display: block;
        position: absolute;
        color: #959595;
      }
    }
    .seo-price {
      margin-top: 20px;
    }
  }
}

.modal-request-call {
  .modal-content {
    width: 430px;
    p.result {
      text-align: center;
    }
    .phones {
      margin-bottom: 22px;
      > a {
        background: #fff;
        justify-content: center;
        margin-bottom: 8px;
        font-size: 16px;
        padding: 10px 0;
        @include default-link;
        i.icon {
          margin-right: 26px;
        }
      }
    }
  }
}

.modal-abuse {
  .modal-content {
    width: 460px;
    .abuse-type-container {
      label {
        line-height: 45px;
        margin-top: 8px;
      }
    }
    textarea {
      background: #fff;
      color: #6d6d6d;
      text-align: left;
    }
  }
}

.alert {
  &-danger {
    background: #ff3633;
    color: #fff;
  }
  &-success {
    background: #00ba2f;
    color: #fff;
  }
  &-info {
    background: #0b93d5;
    color: #fff;
  }
  padding: 25px;
  margin-bottom: 20px;
  > header {
    font-size: 18px;
  }
  pre {
    margin: 10px 0;
  }
}

// hide google recaptcha badge
.grecaptcha-badge {display: none;}

[v-cloak] {
  display: none;
}

.editor-content {
  @include editor-content();
}

.VueCarousel-navigation-button {
  background: url('/build/images/vue-slider-arrows.png') no-repeat 0 0;
  width: 14px;
  height: 24px;
  text-indent: -9999px;
  top: 40%;
  &.VueCarousel-navigation-prev {
    background-position: -1px -1px;
  }
  &.VueCarousel-navigation-next {
    background-position: -20px -1px;
  }
  &.VueCarousel-navigation--disabled {
    opacity: 0.2;
  }
}
.VueCarousel-pagination {
  float: none !important;
}

@include block('help-block') {
  @include modifier('error') {
    color: red;
  }
  @include modifier('success') {
    color: green;
  }
}

.responsive {
  width: auto;
  max-width: 100%;
}

.products {
  @include element('content') {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include grid_reset();
  }
}

@include block('error') {
  &:not(:empty) {
    padding: 25px;
  }
  background: #cece;
  @include element('title') {
    font-weight: bold;
  }
  @include element('message') {

  }
}

.VueCarousel-slide {
  .product-wrapper {
    width: calc(100% - 15px);
  }
}

/* Адаптивность */
@import "responsible/responsible";