.stock-list {
  @include page_header;

  .filters {
    display: flex;
    .form-group {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .custom-select {
      background-color: #fff;
      border: 2px solid #ececec;
      line-height: 38px;
      .current-value {
        padding: 0 40px 0 18px;
      }
      .dropdown {
        border: 2px solid #ececec;
        width: auto;
        min-width: 100%;
      }
      ul {
        background-color: #fff;
        border-width: 2px;
        li {
          padding: 0 18px;
          white-space: nowrap;
        }
      }
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      height: 0;
      width: 30%;
      content: "";
    }

    .stock-item {
      flex-basis: 30%;
      &.is-finished {
        a > img {
          opacity: 0.5;
        }
      }
      .condition {
        padding: 20px 0;
        height: 40px;
        box-sizing: content-box;
        display: flex;
        flex-direction: column-reverse;
      }
      a {
        @include default-link();
        position: relative;
        display: block;
        span {
          &.finished-label {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            display: flex;
            width: 130px;
            height: 130px;
            background-color: #8bc870;
            color: #fff;
            font-weight: bold;
            border-radius: 100%;
            z-index: 1;
            > span {
              margin: auto;
              display: block;
            }
          }
        }
        > img {
          max-width: 100%;
        }
      }
      .title {
        margin: 26px 0 16px 0;
        font-size: 15px;
        font-weight: bold;
      }
      .preview-text {
        margin-bottom: 24px;
      }
      .detail {
        margin: 20px 0;
        text-align: right;
        @include default-link($link-secondary-color);
        font-weight: bold;
        justify-content: flex-end;
        span {
          padding-right: 18px;
        }
      }
    }
  }

  .pagination {
    padding: 25px 0;
  }
}

.stock-full-page {
  > header {
    h1 {
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      margin: 20px 0;
    }
  }

  .stock-full-container {
    &.is-finished {
      > .header {
        position: relative;
      }
      .finished-label {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        display: flex;
        width: 200px;
        height: 200px;
        background-color: #8bc870;
        color: #fff;
        font-weight: bold;
        border-radius: 100%;
        font-size: 18px;
        z-index: 1;
        > span {
          margin: auto;
          display: block;
        }
      }
      picture {
        > img {
          opacity: 0.5;
        }
      }
    }
    > .header {
      text-align: center;
      &.has-gift {
        display: flex;
        align-items: center;
        .row {
          &:first-child {
            img {
              max-width: 920px;
            }
          }
          &:last-child {
            flex-grow: 1;
          }
        }
      }
    }
    > .body {
      margin-top: 40px;
      padding: 20px;
      &.has-gift {
        //padding: 0 25px;
        display: flex;
        justify-content: space-around;
        .gift-container {
          width: 270px;
          flex-shrink: 0;
          > header {
            text-align: center;
            p {
              font-weight: bold;
              &:nth-of-type(1) {
                font-size: 15px;
              }
              &:nth-of-type(2) {
                font-size: 18px;
                color: #4998cc;
              }
            }
          }
          .gift-item-container {
            margin-top: 30px;
            &::before {
              display: block;
              content: "";
              width: 58px;
              height: 23px;
              margin: 0 auto;
              background-image: url('/build/images/stock-gift-before.png');
            }
            .gift-item {
              border: 2px solid #4998cc;
              padding: 25px;
              text-align: center;
              .image-container {
                width: 192px;
                height: 192px;
                margin: 0 auto;
                padding-bottom: 10px;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                  margin: auto;
                }
              }
            }
          }
        }
        .description-container {
          padding-left: 40px;
        }
      }
    }
    .products {
      > header {
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        margin: 20px 0;
      }
      .products-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        position: relative;
      }
    }
    .pagination {
      padding: 25px 0;
    }
  }
}

.stock {
  .countdown-container {
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    .countdown-title {
      margin-bottom: 5px;
    }
    span {
      color: #e89191;
    }
  }
}