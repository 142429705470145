input,
select,
textarea {
  outline: none;
  //font-family: 'Open Sans', sans-serif;
  font-family: inherit;
  font-size: 14px;
}
input[type="submit"] {
  border: none;
}
textarea {
  //min-height: 200px;
  height: auto;
  resize: none;
}
input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

.form-group {
  margin-bottom: 8px;
  line-height: normal;
  label {
    display: block;
  }
  .form-control {
    background-color: #e9e9e7;
    height: 42px;
    line-height: 42px;
    color: #6d6d6d;
    display: block;
    width: 100%;
    border: none;
    &:disabled {
      cursor: not-allowed;
    }
    &.custom-select {
      ul {
        background-color: #f0f0ee;
        li {
          &:hover {
            background: #f3f3f1;
          }
          &.is-active {
            background: #daeaf5;
          }
        }
      }
    }
    &.custom-select,
    &.custom-checkbox {
      &.is-disabled {
        cursor: not-allowed;
      }
    }
  }
  textarea.form-control {
    padding: 24px 17px;
    height: auto;
    line-height: normal;
  }
  .hint {

  }
  .help-block-error {
    color: #ff0000;
    font-size: 12px;
  }
}

.form-errors {
  background: #ff0000;
  color: #fff;
  margin-bottom: 8px;
}
