$popper-background-color: #fff;
$popper-border-color: #e3e3e3;

.tooltip {
  background-color: $popper-background-color;
  box-sizing: border-box;
  //min-width: 120px;
  //max-width: 320px;
  width: 120px;
  padding: 6px 10px;
  //border-radius: 3px;
  z-index: 100;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid $popper-border-color;

  .tooltip-inner {
    text-align: center;
    color: $secondary-color;
    text-align: center;
    font-size: 12px;
  }

  // .popper-close {
  //  position: absolute;
  //  top: 2px;
  //  right: 4px;
  //  color: black;
  //  background: transparent;
  //  border: none;

  //  &:active,
  //  &:focus {
  //      outline: none;
  //  }
  // }

  .tooltip-arrow {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-color: $popper-background-color transparent transparent transparent;
      bottom: -5px;
      left: calc(50% - 10px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent $popper-background-color transparent;
      top: -5px;
      left: calc(50% - 10px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent $popper-background-color transparent transparent;
      left: -5px;
      top: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $popper-background-color;
      right: -5px;
      top: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0;
    }
  }
}