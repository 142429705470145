@include media-breakpoint-up(md) {
  .modal-sticked {
    position: absolute;
    z-index: auto;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    .modal-background {
      position: fixed;
      z-index: $modal-background-index;
    }
    .modal-content {
      z-index: $modal-content-index;
      //position: initial;
      position: relative;
    }
  }
}

@include media-breakpoint-down(md) {
  .modal {
    .modal-content {
      .modal-close {
        top: 7px;
        right: 15px;
        .close {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .modal {
    .modal-content {
      width: 100% !important;
      height: 100% !important;
      overflow: auto;
      .modal-title {
        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}

