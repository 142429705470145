@include media-breakpoint-down(lg) {
  .stock-list {
    padding: 0 15px;
  }
  .stock-full-page {
    padding: 0 15px;
    .stock-full-container {
      .header {
        flex-wrap: wrap;
        .row {
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .stock-list {
    &-container {
      .stock-item {
        flex-basis: 48%;
      }
    }
  }
  .stock-full-page {
    .stock-full-container {
      .header {
        img {
          max-width: 100% !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .stock-list {
    .filters {
      padding: 0 35px;
      flex-wrap: wrap;
      .form-group {
        &:not(:last-child) {
          margin-right: 0;
        }
        width: 100%;
      }
    }

    &-container {
      .stock-item {
        flex-basis: 100%;
      }
    }
  }
  .stock-full-page .stock-full-container > .body {
    padding: 0;
    &.has-gift {
      flex-wrap: wrap;
      > div {
        width: 100%;
      }
      .gift-container {
        margin-top: 20px;
        order: 1;
      }
      .description-container {
        padding-left: 0;
      }
    }
  }
}