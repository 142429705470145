.container {
  max-width: 1170px;
  margin: 0 auto;
}
.grid {
  display: flex;
  justify-content: space-between;
}
.text-center {
  text-align: center;
}