.custom-select {
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    background: url(/build/images/menu_arrows_sprite.png) no-repeat -4px -33px;
    width: 5px;
    height: 3px;
    transition: 0.3s all ease;
  }
  &.is-active {
    &:after {
      transform: rotate(180deg);
    }
  }
  &.is-disabled {
    .current-value {
      cursor: not-allowed;
    }
  }
  .current-value {
    cursor: pointer;
    height: 100%;
    overflow: hidden;
  }
  .dropdown {
    position: absolute;
    left: 0;
    z-index: 5;
    width: 100%;
    .vb-content {
      width: calc(100% + 20px) !important;
      max-height: 300px;
    }
    .autocomplete,
    .live-search {
      .form-control {
        //width: 100%;
      }
    }
    ul {
      list-style: none;
      li {
        cursor: pointer;
      }
    }
  }
}

.custom-select-enter-active {
  animation: customFadeInUp 500ms;
}
.custom-select-leave-active {
  //animation: customFadeInUp .5s reverse;
  animation: customFadeOut 300ms;
}

@keyframes customFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes customFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}