$side_padding: 5px;
$filter_width: 300px;
$filter_color: #ececec;
$category_item_padding_width: 21px;
$category_item_padding_height: 14px;

$max_preload_width: $filter_width - $side_padding * 2 - $category_item_padding_width;
$category_child_height: 117px;
$category_child_repeat: 30px;
$line_category_width: $max_preload_width;
$line_category_height: 14px;
$line_category_position: $side_padding $side_padding;

$filter_wrapper_height: 300px;
$filter_wrapper_repeat: 150px;
$filter_group_width: 150px;
$filter_group_height: 24px;
$filter_group_position: $side_padding $side_padding;
$filter_item_checkbox_width: 19px;
$filter_item_checkbox_height: 19px;
$filter_item_title_width: 170px;
$filter_item_title_height: 10px;

$filter_item_padding_top1: ($side_padding * 3 + $filter_group_height);
$filter_item_checkbox_position1: $side_padding $filter_item_padding_top1;
$filter_item_title_position1: ($side_padding * 2 + $filter_item_checkbox_width) ($filter_item_padding_top1 + ($filter_item_checkbox_height / 4));

$filter_item_padding_top2: $filter_item_padding_top1 * 2 - 11px;
$filter_item_checkbox_position2: $side_padding $filter_item_padding_top2;
$filter_item_title_position2: ($side_padding * 2 + $filter_item_checkbox_width) ($filter_item_padding_top2 + ($filter_item_checkbox_height / 4));

$filter_item_padding_top3: $filter_item_padding_top1 * 3 - 22px;
$filter_item_checkbox_position3: $side_padding $filter_item_padding_top3;
$filter_item_title_position3: ($side_padding * 2 + $filter_item_checkbox_width) ($filter_item_padding_top3 + ($filter_item_checkbox_height / 4));

@keyframes loading-categories {
  to {
    background-position:
      150% 0, /* move highlight to right */
      $line_category_position;
  }
}

@keyframes loading-filters {
  to {
    background-position: -350% 0, /* move highlight to right */
    $filter_group_position,
    $filter_item_checkbox_position1,
    $filter_item_title_position1,
    $filter_item_checkbox_position2,
    $filter_item_title_position2,
    $filter_item_checkbox_position3,
    $filter_item_title_position3;
  }
}

.category-wrapper {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  .category-description-wrapper {
    h1 {
      text-align: center;
      font-size: 21px;
      color: #484848;
      margin-bottom: 20px;
    }
    .category-description {
      text-align: center;
      .logo {
        padding-bottom: 26px;
      }
      .short-description:not(:empty) {
        border: 1px solid #ececec;
        padding: 15px;
      }
    }
  }

  > aside {
    width: $filter_width;
    .aside-content {
      padding: $side_padding;
      background: $filter_color;
      margin-bottom: 15px;
      .vb-content {
        max-height: 430px;
      }
      .vb-dragger {
        right: 0 !important;
        .vb-dragger-styler {
          background-color: #d4d2d2;
        }
      }
    }
  }
  > section {
    width: calc(100% - #{$filter_width} - 14px);
    .sort-panel {
      display: flex;
      align-items: center;
      margin: 20px 0;
      background: #ececec;
      > div {
        display: flex;
        align-items: center;
        padding: 11px 26px;
      }
      a {
        @include default-link();
        i.icon {
          margin-right: 10px;
        }
        &.filter-btn {
          padding: 19px 50px;
          background-color: #0092d8;
          color: #fff;
          //height: 10vh;
        }
        &.sort-btn {
          padding: 19px 50px;
        }
      }
      .panel-text {
        margin-right: 30px;
      }
      .sort-item {
        display: flex;
        align-items: center;
        .sort-item-name {
          padding: 8px 14px;
        }
        .grid {
          margin: 0 10px;
        }
        .sort-type {
          width: 20px;
          height: 20px;
          display: flex;
          &:first-child {
            margin-right: 2px;
          }
          &:last-child {
            margin-left: 2px;
          }
          a {
            display: block;
            background: url('/build/images/sort_arrows.png') no-repeat;
            width: 12px;
            height: 6px;
            margin: auto;
            &.asc {
              background-position: 0 -2px;
            }
            &.desc {
              background-position: 0 -12px;
            }
          }
          &.is-active {
            background-color: #fff;
          }
        }
        &.is-active {
          &:not(:last-child) {
            margin-right: 10px;
          }
          .sort-item-name {
            background: #fff;
          }
        }
      }
    }
    .filter-panel {
      display: flex;
      flex-wrap: wrap;
      padding: 0 25px;
      margin-bottom: 18px;
      .filter-item {
        @include default-link(#767676);
        position: relative;
        padding: 7px 30px 7px 23px;
        background: #ececec;
        margin-right: 17px;
        margin-bottom: 5px;
        // ... ending of overflow content
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span.close {
          position: absolute;
          right: 10px;
          top: 7px;
        }
      }
      .show-full {
        display: block;
        text-align: center;
        width: 100%;
        @include default-link();
        font-size: 17px;
        margin: 20px 0;
      }
    }
    .category-loader {
      text-align: center;
      margin-bottom: 30px;
      @include grid_reset();
    }
  }
  .products-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .product-wrapper {

    }
  }
  .pagination {
    &:not(:empty) {
      margin: 30px 0;
    }
  }
  .empty-products {
    text-align: center;
    margin: 20px 0;
  }
  
  .category-banner {
    padding: 0 10px;
  }

  @-webkit-keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.filter-wrapper {
  .child-categories {
    ul {
      li {
        &:not(:last-child) {
          border-bottom: 1px solid $filter_color;
        }
        a {
          display: block;
          position: relative;
          @include default-link();
          padding: $category_item_padding_height 33px $category_item_padding_height $category_item_padding_width;
          background: #fff;
          font-size: 13px;
          &:after {
            display: block;
            position: absolute;
            right: 23px;
            top: calc(50% - 3px);
            content: "";
            width: 4px;
            height: 7px;
            background: url("/build/images/filter_mini_arrow.png") no-repeat;
          }
          &.important {
            color: $important_color;
          }
        }
      }
    }
  }

  .filter-group,
  .filter-price {
    &:not(:last-child) {
      border-bottom: 2px solid #fff;
    }
    padding: 15px;
    .filter-title {
      position: relative;
      padding: 10px 13px;
      color: #484848;
      cursor: pointer;
      &:after {
        display: block;
        content: '';
        position: absolute;
        background: url('/build/images/filter_arrows.png') no-repeat -6px -14px;
        width: 8px;
        height: 15px;
        top: calc(50% - 7px);
        right: 3px;
      }
    }
    .filter-options {
      ul {
        li {
          a {
            display: flex;
            padding: 9px;
            margin-bottom: 1px;
            @include default-link();
            cursor: pointer;
            span {
              display: block;
              line-height: normal;
              &.checkbox {
                width: 19px;
                height: 19px;
                background: #fff;
              }
              &.text {
                padding-left: 17px;
                flex-grow: 1;
                line-height: normal;
              }
            }
            &.is-active {
              background: #fff;
              span {
                &.checkbox {
                  background: $filter_color url(/build/images/filter_checked.png) center center no-repeat;
                }
              }
            }
            &.is-disabled {
              cursor: default;
              span {
                &.checkbox {
                  background: #f9f9f9;
                }
                &.text {
                  color: #b4b4b4;
                }
              }
            }
          }
        }
      }
      a.show-more-link {
        line-height: normal;
        @include default-link();
      }
    }
    &.is-expand {
      .filter-title {
        &:after {
          right: 0;
          top: calc(50% - 4px);
          background-position: -2px -4px;
          width: 15px;
          height: 8px;
        }
      }
      .filter-options {
        &.is-scroll {
          .vb-content {
            max-height: 370px;
          }
          .vb-dragger {
            right: 0;
          }
        }
      }
    }
  }
  .filter-price {
    .filter-options {
      .vue-slider-dot {
        box-shadow: none !important;
        border: 1px solid #e4e4e4;
      }
      .grid {
        margin: 20px 0;
        .row {
          input[type="number"],
          input[type="text"] {
            border: none;
            width: 65px;
            padding: 4px 0;
            text-align: center;
            color: #767676;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.mobile-filter,
.mobile-sort {
  .swipe-content {
    background: #ececec;
    width: 300px;
    transform: translateX(-300px);
    > header {
      margin: 30px 0;
      padding: 0 15px;
      .logo {
        margin-left: 25px;
      }
      .swipe-close {
        margin-top: 20px;
      }
    }
  }
}
.mobile-filter {
  .scrolled {
    overflow: scroll;
  }
}
.mobile-sort {
  ul {
    &:not(:last-child) {
      margin-bottom: 22px;
    }
    li {
      a {
        @include default-link();
        padding: 20px 33px 20px 21px;
        font-size: 16px;
        display: block;
        background: #fff;
      }
      &.is-active {
        a {
          background-color: #4998cc;
          color: #fff;
        }
      }
    }
  }
}

@include block('reviews-last') {
  margin-bottom: 20px;
  @include element('title') {
    font-weight: bold;
    text-align: center;
  }
  @include element('total-rating') {
    text-align: center;
    .stars-rating {
      margin: 5px 0;
      justify-content: center;
    }
  }
}