@include media-breakpoint-up(lg) {
  @include block('layout') {
    height: 100%;
    @include modifier('landing') {
      @include block('layout') {
        @include element('container') {
          background: #fff;
          z-index: 2;
          width: 1230px;
          margin: 0 auto;
        }
      }
    }
  }
  
  @include block('landing-link') {
    display: block;
  }
}

@include media-breakpoint-down(lg) {
  @include block('layout') {
    @include element('header') {
      .top-menu {
        > .grid {
          > .row {
            .city-chooser-container {
              padding-left: 15px;
            }
          }
        }
      }
      .middle-menu {
        > .grid {
          height: 120px;
          .logo {
            padding-left: 15px;
          }
          .search-wrapper {
            width: 380px;
          }
        }
      }
    }
    @include element('footer') {
      .container {
        padding: 0 $gutter;
      }
      .footer-blocks {
        .contacts {
          //padding-left: 0;
        }
        .copyright {
          right: $gutter;
        }
        .subscribe {
          .form-container {
            width: 400px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .top-menu-container {
    position: inherit;
  }
  .top-menu {
    //margin-top: 22px;
    height: 62px;
    line-height: 62px;
    .grid > {
      .row {
        //.auth-form-container,
        //.cart-informer-container {
        //  .modal-sticked {
        //    left: 0;
        //    margin-left: 0;
        //  }
        //}
      }
    }
  }

  @include block('layout') {
    @include element('header') {
      padding-top: 0;
      .middle-menu {
        > .grid {
          height: 100px;
        }
        &.mobile {
          > .grid {
            .row {
              &.auth,
              &.request-call {
                //padding: 0 25px;
              }
              &.work-time {
                padding-right: 15px;
              }
              .request-call-container {
                .request-call-mobile {
                  //padding: 0;
                }
              }
            }
          }
        }
      }

    }
    @include element('footer') {
      .footer-blocks {
        flex-wrap: wrap;
        .row {
          text-align: center;
        }
        .contacts,
        .abuse {
          flex-basis: 50%;
        }
        .subscribe {
          padding-top: 41px;
          flex-basis: 100%;
          order: 2;
          .form-container {
            margin: auto;
            form {
              button {
                width: auto;
                padding: 0 36px;
                text-indent: -9999px;
                background: url(/build/images/subscribe-submit-bg.png) center center no-repeat;
              }
            }
          }
        }
      }
    }
    .seo-text-container {
      //.fix-wrapper {
      .seo-text {
        padding: 0 15px;
        column-count: 1;
        ol, ul, dl {
          padding: 20px;
        }
      }
      //}
    }
  }
}

@include media-breakpoint-down(sm) {
  @include block('layout') {
    @include element('header') {
      .top-menu {
        height: 46px;
        line-height: 46px;
        > .grid {
          > .row {
            &:not(:last-child) {
              flex-basis: auto;
            }
            .auth-form-container,
            .cart-informer-container {
              .modal-sticked {
                left: 0;
                margin-left: 0;
                .modal-content {
                  padding: 28px 16px;
                  .modal-close,
                  .modal-title {
                    height: auto;
                    line-height: normal;
                  }
                }
              }
            }
            .city-chooser-container {
              .city-chooser {
                font-size: 14px;
                span {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .middle-menu {
        > .grid {
          .request-call-container {
            .request-call-mobile {
              padding: 0;
              border-left: none;
            }
          }
        }
        &.mobile {
          > .grid {
            height: 67px;
            .row {
              &.actions,
              &.auth,
              &.request-call {
                padding: 0 20px;
              }
              &.mobile-logo {
                img {
                  max-width: 170px;
                }
              }
              .request-call-container {
                .request-call-mobile {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .mobile-menu {
      .swipe-content {
        width: 360px;
        transform: translateX(-360px);
      }
    }
    @include element('footer') {
      .footer-blocks {
        flex-wrap: wrap;
        > .row {
          flex-basis: 100%;
        }
        .subscribe {
          .form-container {
            width: 100%;
            form {
              input[type="text"] {
                width: 80%;
              }
            }
          }
        }
      }
      .footer-menu {
        ul {
          display: block;
          li {
            display: block;
            text-align: center;
            border: none;
            padding: 5px 10px;
          }
        }
      }
      .copyright {
        background: #fff;
        text-align: center;
        padding-bottom: 10px;
      }
    }

    .modal-mobile-search {
      .modal-content {
        margin: 0;
        border: none;
      }
    }
  }

  #yii-debug-toolbar {
    display: none !important;
  }
}

//@include media-breakpoint-down(ms) {
@media (max-width: 360px) {
  @include block('layout') {
    //@include element('header') {
      .middle-menu.mobile > .grid .row {
        &.mobile-logo {
          .mobile-only {
            display: block;
          }
          .desktop-only {
            display: none;
          }
          img {
            max-width: 132px;
          }
        }
        &.actions {
          padding: 0 10px;
        }
      }
    //}
  }
}

@include media-breakpoint-down(xs) {
  @include block('layout') {
    //@include element('header') {
      .top-menu {
        > .grid {
          > .row {
            .city-chooser-container {
              .popup-city-confirm {
                margin-left: 15px;
                .grid {
                  width: 270px;
                  flex-wrap: wrap;
                  .row {
                    flex-basis: 100%;
                    text-align: center;
                    button {
                      display: block;
                      width: 100%;
                    }
                  }
                }
              }
              .popup-city-chooser {
                width: 290px;
              }
              .city-chooser {
                margin-left: 15px;
                span {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
      .middle-menu {
        &.mobile {
          > .grid {
            .row {
              &.actions,
              &.auth,
              &.request-call {
                padding: 0 10px;
              }
              &.mobile-logo {
                img {
                  max-width: 140px;
                }
              }
            }
          }
        }
      }
    }
    @include element('footer') {
      .footer-blocks {
        .subscribe {
          .phones-container {
            .phones {
              .row {
                flex-basis: 100%;
              }
            }
          }
        }
      }
    }
    .mobile-menu {
      .swipe-content {
        width: 100%;
        transform: translateX(-100%);
      }
    }
  //}
}