@import "../variables";

.modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  //align-items: center;
  //display: none;
  //justify-content: center;
  //overflow: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 1986;
  display: flex;
  transition: opacity .3s ease;
  .modal-background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background-color: rgba(10, 10, 10, 0.86);
    &.transparent {
      background: transparent;
    }
  }
  .modal-content {
    position: relative;
    margin: auto;
    color: $main-color;
    padding: 28px 16px;
    background-color: #f3f3f5;
    transition: all .3s ease;
    .modal-close {
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
      z-index: 10;
      .close {
        background-image: url("/build/images/icon_close.svg");
        background-size: contain;
        fill: $main-color;
        stroke: $main-color;
        display: block;
        width: 25px;
        height: 25px;
      }
    }
    .modal-title {
      //padding-top: 13px;
      //padding-bottom: 28px;
      padding-bottom: 10px;
      text-align: center;
      color: #333333;
      font-size: 16px;
    }
  }

  .form-group * {
    text-align: center;
  }
}



.is-modal-active {
  position: relative;
  z-index: $modal-content-index;
}

// modal animations
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-content,
.modal-leave-active .modal-content {
  transform: scale(1.1);
}