.cart-informer-container {
  position: relative;
  height: 100%;
  .cart-informer {
    padding: 0 10px;
    height: 100%;
    &.is-active {
      //background: #0092d8;
      &:hover {
        cursor: pointer;
        //background: #0485c3;
      }
    }
    //padding: 0 15px;
    .count {
      position: relative;
      width: 25px;
      height: 28px;
      margin-right: 10px;
      i.icon {
        position: absolute;
      }
      span {
        position: absolute;
        color: #000;
        top: 7px;
        width: 100%;
        /* height: 100%; */
        line-height: normal;
        text-align: center;
      }
    }
  }
}

.modal-cart {
  .modal-content {
    padding: 19px 0 0;
  }

  .error-message {
    margin: 5px 19px;
    padding: 15px;
    background: #da4040;
    color: #fff;
  }

  .empty-cart {
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loader-wrapper {
      position: absolute;
    }
    p {
      margin-top: 20px;
      font-size: 15px;
      color: $secondary-color;
    }
  }

  .cart-informer-content {
    padding: 0 19px;
    .loader-wrapper {
      &.is-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.4);
      }
    }
    .cart-informer-block {
      position: relative;
      background: #fff;
      padding: 15px;
      &:not(:last-child) {
        border-bottom: 1px solid #f3f3f5;
      }
      .grid {
        align-items: center;
        .image {
          a {
            width: 120px;
            height:  120px;
            display: flex;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: auto;
          }
        }
        .name-container {
          padding: 10px;
          //width: 300px;
          flex-grow: 1;
          .name {
            margin-bottom: 12px;
            a {
              @include default-link(#333333);
            }
          }
          .name2 {
            color: $secondary-color;
          }
          .properties {
            border-top: 1px solid #f3f3f5;
            margin-top: 9px;
            padding-top: 5px;
            li {
              span {
                &.name {
                  color: $secondary-color;
                }
                &.value {
                  color: #4c4c4c;
                }
              }
            }
          }
        }
        .quantity-container {
          width: 150px;
          flex-shrink: 0;
          > div {
            display: flex;
            justify-content: center;
            a {
              @include default-link();
              &.is-disabled {
                cursor: not-allowed;
              }
            }
            a,
            .quantity {
              display: block;
              width: 38px;
              height: 38px;
              line-height: 38px;
              text-align: center;
            }
            .quantity {
              background: #f3f3f5;
            }
          }
          .help-block-error {
            color: #ff0000;
            text-align: center;
          }
        }
        .price-container {
          width: 115px;
          font-size: 14px;
          text-align: center;
          flex-shrink: 0;
          &.has-discount {
            .price {
              display: inline-block;
              border: 1px solid #e9bac4;
              color: #d98596;
              padding: 7px 11px;
            }
            .old-price {
              text-decoration: line-through;
              color: #adadad;
              padding: 7px 11px;
            }
          }
        }
        .need-wait-block {
          color: red;
        }
      }
      .btn-remove {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 21px;
        height: 21px;
        display: flex;
        > i {
          margin: auto;
        }
        .popup-cart-confirm {
          right: 0;
          background: #fff;
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
          a {
            @include default-link($secondary-color);
            width: 137px;
            height: 115px;
            display: block;
            text-align: center;
            &:not(:last-child) {
              border-right: 1px solid #f3f3f5;
            }
            span {
              display: block;
              &.text {
                padding-top: 10px;
              }
            }
            > span {
              //margin: auto;
              padding: 5px;
              margin-top: 21px;
              display: flex;
              flex-direction: column;
            }
            .icon-cart-container {

            }
          }
        }
      }
    }
    .total-container {
      //height: 173px;
      padding: 30px 20px;
      font-size: 13px;
      .grid {
        .row {
          width: 280px;
          &.stats {
            display: flex;
            flex-direction: column;
            //align-items: flex-end;
            margin-top: auto;
          }
        }
      }
      table {
        width: 100%;
        //margin-top: 23px;
        margin-bottom: 10px;
        tr {
          &.economy {
            color: #e89191;
          }
          td {
            padding: 10px 0;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      .coupon-container {
        height: 60px;
        text-align: center;
        > a {
          @include default-link($secondary-color);
          text-decoration: underline;
        }
        .coupon-form {
          .form-control {
            background: #fff;
            width: 100%;
          }
          a {
            display: block;
            @include default-link($secondary-button-color);
          }
          .discount-success {
            span {
              color: $primary-button-color;
            }
          }
          .discount-error {
            color: #ff0000;
          }
        }
      }
    }
    .progress-bar-container {
      margin: 12px 0;
      padding: 0 9px;
      text-align: center;
      font-size: 13px;
      .hint {
        padding: 5px 0;
        span {
          color: #333333;
        }
      }
      .progress-bar {
        width: 262px;
        height: 8px;
        background-color: #d3d3d3;
        .indicator {
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8bc870+0,76bf56+100 */
          background: #8bc870; /* Old browsers */
          background: -moz-linear-gradient(top, #8bc870 0%, #76bf56 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #8bc870 0%,#76bf56 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #8bc870 0%,#76bf56 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc870', endColorstr='#76bf56',GradientType=0 ); /* IE6-9 */
        }
      }
    }
  }

  .products-viewed {
    background: #fff;
    height: 100%;
    line-height: normal;
    padding: 0 15px;
    h3 {
      font-size: 14px;
      color: $secondary-color;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 23px;
    }
    .cart-product-viewed {
      padding: 10px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #f3f3f5;
      }
      .image {
        text-align: center;
        a {
          width: 120px;
          height: 120px;
          display: flex;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: auto;
          }
        }
      }
      .names {
        flex-grow: 1;
        padding-left: 10px;
        .primary-name {
          padding-bottom: 10px;
          a {
            @include default-link();
            //color: #333;
            font-size: 13px;
          }
        }
        .secondary-name {
          color: $secondary-color;
          font-size: 13px;
        }
      }
      .actions {
        padding: 0 37px;
        > div {
          width: 193px;
        }
        .price-container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 33px;
          font-size: 14px;
          .label {
            margin-right: 5px;
          }
          .price-current {
            color: #333333;
          }
          &.with-discount {
            .price-current {
              border: 1px solid #e9bac4;
              color: #d98596;
              padding: 5px 11px;
              border-radius: 2px;
              > span {
                //font-weight: 600;
              }
            }
            .price-old {
              color: #d0d0d0;
              text-decoration: line-through;
              margin-left: 9px;
            }
          }
        }
        .unavailable-text {
          color: #333333;
          text-align: center;
          min-height: 32px;
          line-height: 32px;
        }
        .btn {
          width: 100%;
          margin-top: 6px;
        }
      }
    }
  }

  @include block('offers-chooser'){
    margin-top: 6px;
    @include block('simple') {
      @include element('list-item') {
        font-size: 13px;
      }
    }
    @include block('extended') {
      width: 100%;
    }
  }
}



.modal-average {
  .modal-content {
    width: 1160px;
    background: #fff;
    padding: 0;
    .modal-title {
      padding: 28px 16px;
    }
  }

  @include block('average') {
    padding: 0 25px;
    //.VueCarousel-wrapper {
    //  margin: 0px 0 -150px 0;
    //  padding: 0px 0px 150px 0;
    //  z-index: 1;
    //}
  }

  @include block('controls') {
    padding: 25px;
    display: flex;
    justify-content: space-around;
    @include element('button') {
      width: 35%;
    }
  }
}