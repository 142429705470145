.show-more {
  width: 270px;
  //margin: 0 auto;
  min-height: $product-item-height;
  height: 100%;
  text-align: center;
  display: flex;
  .icon-wrapper {
    width: 64px;
    height: 64px;
    margin: 0 auto 8px auto;
    border-radius: 50%;
    justify-content: center;
    background-color: #6ca2c5;
    &.loading {
      animation: rotation 0.7s infinite ease;
    }
  }
  > a {
    display: block;
    margin: auto;
    font-size: 16px;
    color: #6ca2c5;
    font-weight: 600;
    line-height: 26px;
    text-decoration: none;
  }
}