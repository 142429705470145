.product-informer {
  margin: 0 20px;
  justify-content: flex-start;
  .image {
    a {
      display: flex;
      width: 110px;
      height: 110px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto;
    }
  }
  .description {
    //width: 210px;
    padding: 0 15px;
    p.title {
      a {
        @include default-link();
        span {
          font-size: 13px;
          &.name {
            display: block;
            font-weight: bold;
          }
          &.name-alias {

          }
        }
      }
    }
    > .grid {
      margin-top: 18px;
      align-items: center;
      .price {
        font-size: 15px;
        span {
          font-weight: bold;
        }
      }
      .offer-value {
        font-size: 13px;
        color: $secondary-color;
      }
    }
  }
}