.stars-rating {
  display: flex;
  &:not(.read-only) {
    li:hover {
      cursor: pointer;
    }
  }
  li {
    display: block;
    background: url('/build/images/stars_sprite.png') no-repeat 0 0;
    width: 14px;
    height: 14px;
    &.is-active {
      background-position: -15px 0;
    }
  }
}