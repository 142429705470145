.brand-page {
  .fixed {
    position: fixed;
  }
  @include page_header;
  .brand-panel {
    background: #ececec;
    ul {
      display: flex;
      justify-content: center;
      padding: 13px 0;
      li {
        a {
          @include default-link(#949494);
          display: block;
          margin: 12px;
          font-weight: bold;
          font-size: 15px;
          &.disabled {
            color: #d7d7d7;
          }
        }
      }
    }
  }
  .brand-list {
    .row {
      display: flex;
      border-bottom: 1px solid #ececec;
      // fix for anchor
      padding-top: 100px;
      margin-top: -100px;
      .letter-key {
        flex-basis: 170px;
        flex-shrink: 0;
        padding: 60px 0 0 35px;
        font-size: 20px;
        color: #949494;
        font-weight: bold;
      }
      .list {
        display: flex;
        flex-wrap: wrap;

        .brand-item {
          @include default-link(#767676);
          text-align: center;
          margin: 45px 15px;
          .image {
            display: flex;
            width: 100px;
            height: 35px;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              margin: auto;
            }
          }
          .title {
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }
}