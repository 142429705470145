.contacts-page {
  @include page_header;
  .grid {
    flex-wrap: wrap;
    justify-content: space-between;
    @include grid_reset;
    .row {
      flex-basis: 30%;
      display: flex;
      margin-bottom: 61px;
      .icon-container {
        flex-basis: 70px;
        flex-shrink: 0;
      }
      .text-container {
        > header {
          font-weight: bold;
          margin-bottom: 21px;
        }
        p {
          margin-bottom: 15px;
        }
        a.link {
          @include default-link($link-secondary-color);
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
}

.modal-contact {
  p {
    text-align: center;
    &.label {
      margin-bottom: 18px;
    }
  }
}