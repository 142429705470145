// custom 1
.vue-scrollbar__scrollbar-vertical {
  $scroll-width: 5px;
  width: $scroll-width;
  right: 5px;
  opacity: 1;
  background: none;
  &:hover {
    background: none;
  }
  .scrollbar {
    width: $scroll-width;
    background: #e4e4e4;
    border-radius: 25px;
    &:hover {
      cursor: pointer;
    }
  }
}

// custom 2
//.vb-dragger {
//  width: 12px;
//  right: 0;
//  .vb-dragger-styler {
//    backface-visibility: hidden;
//    transform: rotate3d(0,0,0,0);
//    transition:
//            background-color 100ms ease-out,
//            margin 100ms ease-out,
//            height 100ms ease-out;
//    margin: 5px 5px 5px 0;
//    background: #e4e4e4;
//    border-radius: 25px;
//    height: calc(100% - 10px);
//    display: block;
//  }
//}
//
//.vb {
//  .vb-scrolling-phantom >  .vb-dragger .vb-dragger-styler {
//    background-color: rgba(255,255,255,.3);
//  }
//  &:hover .vb-dragger-styler {
//    background-color: rgba(255,255,255,.5);
//    margin: 0px;
//    height: 100%;
//  }
//  &.vb-dragging >  .vb-dragger .vb-dragger-styler {
//    background-color: rgba(255,255,255,.5);
//    margin: 0px;
//    height: 100%;
//  }
//  &.vb-dragging-phantom >  .vb-dragger .vb-dragger-styler {
//    background-color: rgba(255,255,255,.5);
//  }
//}

.vb > .vb-dragger {
  z-index: 5;
  width: 5px;
  right: 5px;
}

.vb > .vb-dragger > .vb-dragger-styler {
  //backface-visibility: hidden;
  //transform: rotate3d(0,0,0,0);
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  background-color: #e5e5e5;
  //margin: 5px 5px 5px 0;
  border-radius: 25px;
  //height: calc(100% - 10px);
  height: 100%;
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: #d0d0d0;
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: #d0d0d0;
  //margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: #d0d0d0;
  //margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: #d0d0d0;
}