.profile {
  $bg_color: #ececec;
  padding-bottom: 40px;
  > header {
    margin: 30px 0;
    h1 {
      font-size: 15px;
      color: #333333;
      text-align: center;
    }
  }
  .panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $bg_color;
    height: 61px;
    padding: 0 40px 0 30px;
    .icon-wrapper {
      span {
        padding: 0 10px;
      }
    }
    .bonus-container {
      .icon-wrapper {
        span.value {
          color: #e57521;
        }
      }
    }
    .discount-container {
       .icon-wrapper {
         span.value {
           color: #ac4a6b;
         }
       }
    }
    .logout-container {
      a {
        @include default-link();
      }
    }
  }

  ul.nav {
    padding: 4px;
    background: $bg_color;
    li {
      background: #fff;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      &.active {
        a {
          font-weight: bold;
        }
      }
      a {
        @include default-link();
        padding: 0 15px;
        height: 53px;
        span {
          padding-left: 10px;
        }
      }
    }
  }

  > .grid {
    margin-top: 15px;
    aside {
      flex-basis: 293px;
      flex-shrink: 0;
      margin-right: 10px;

    }
    .profile-content {
      flex-grow: 1;
      .profile-container {
        background: $bg_color;
        padding-bottom: 20px;
        margin-bottom: 20px;
        > header {
          padding: 25px 0;
          border-bottom: 1px solid #fff;
          text-align: center;
        }
      }
      .orders-container {
        .filters {
          display: flex;
          //flex-wrap: wrap;
          padding: 0 20px;
          a {
            @include default-link();
            padding: 27px 15px;
            &.is-active {
              font-weight: bold;
            }
          }
        }
        .orders-list {
          padding: 0 20px;
          .order-item {
            .order-panel {
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              height: 51px;
              background: #fff;
              align-items: center;
              padding: 0 17px;
              .label {
                padding-right: 5px;
              }
              .date,
              .paid {
                .label {
                  display: none;
                }
              }
              .id {
                flex-basis: 17%;
              }
              .date {
                flex-basis: 18%;
                .value {
                  color: #ac4a6b;
                }
              }
              .total {
                flex-basis: 20%;
              }
              .paid {
                flex-basis: 19%;
                .value {
                  color: #ff9239;
                  &.is-paid {
                    color: #8bc870;
                  }
                }
              }
              .completed {
                flex-basis: 19%;
                .value {
                  color: #e89191;
                  &.is-complete {
                    color: #8bc870;
                  }
                }
              }
              .details {
                color: $secondary-color;
                .icon-wrapper {
                  span {
                    opacity: 0.5;
                    padding-right: 5px;
                  }
                }
              }
            }
            .order-info {
              $border_color: #e0e0e0;
              background: #fff;
              .delivery {
                padding: 21px 26px;
                border-top: 1px solid $border_color;
                > header {
                  font-weight: bold;
                  margin-bottom: 23px;
                }
                .icon-wrapper {
                  margin: 10px 0;
                }
                .delivery-type {
                  i.icon {
                    margin-right: 14px;
                  }
                }
                .delivery-value {
                  i.icon {
                    margin-left: 5px;
                    margin-right: 12px;
                  }
                }
                .manager-info {
                  i.icon {
                    margin-right: 10px;
                  }
                  span > span {
                    padding-left: 10px;
                  }
                }
                .manager-message {
                  i.icon {
                    margin-right: 11px;
                  }
                  a {
                    @include default-link(#7db103);
                    font-weight: bold;
                  }
                }
              }
              .order-statuses {
                border-top: 1px solid $border_color;
                display: flex;
                padding: 30px 0;
                justify-content: center;
                .order-status {
                  display: flex;
                  &:not(:last-child):after {
                    margin: 25px 10px 0 10px;
                    display: block;
                    content: "\2022\2022\2022\2022";
                    color: #9f9f9f;
                  }
                  .inner {
                    width: 90px;
                    .icon-container {
                      position: relative;
                      display: flex;
                      height: 60px;
                      .icon-order-checked {
                        position: absolute;
                        right: 10%;
                      }
                      .status-icon {
                        margin: auto;
                      }
                    }
                    .date {
                      font-size: 12px;
                      color: #a5a5a5;
                      margin-bottom: 8px;
                    }
                    .name {
                      text-align: center;
                      font-size: 13px;
                    }
                  }
                }
              }
              .cart-items {
                &.grid-view {
                  display: none;
                }
                .cart-item {
                  .image {
                    min-width: 40px;
                    text-align: center;
                    margin-right: 10px;
                  }
                }
                border-top: 1px solid $border_color;
                table {
                  width: 100%;
                  thead {
                    tr {
                      border-bottom: 1px solid $border_color;
                      th {
                        &:first-child {
                          padding-left: 24px;
                          width: 55%;
                        }
                        font-weight: bold;
                        padding: 15px;
                        text-align: left;
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        &:first-child {
                          padding-left: 24px;
                        }
                        padding: 5px 15px;
                        vertical-align: middle;
                        .grid {
                          justify-content: flex-start;
                          align-items: center;

                        }
                        a {
                          //margin-left: 10px;
                          @include default-link($link-secondary-color);
                        }
                      }
                    }
                  }
                }
              }
              .order-rating {
                border-top: 1px solid $border_color;
                padding: 10px 0;
                &.is-active {
                  .grid {
                    .row.rating-item:not(.is-active) {
                      opacity: 0.3;
                    }
                  }
                }
                .grid {
                  justify-content: space-around;
                  align-items: center;
                  margin: 10px 0;
                  .row {
                    cursor: pointer;
                    span {
                      display: block;
                      padding: 10px 0;
                    }
                  }
                }
                .unacceptable {
                  color: #e10019;
                }
                .bad {
                  color: #9f1c19;
                }
                .normal {
                  color: #68341a;
                }
                .good {
                  color: #20531b;
                }
                .excellent {
                  color: #01601b;
                }
                .success-message {
                  text-align: center;
                  color: #8cc870;
                }
                .rating-comment {
                  display: flex;
                  padding: 0 20px;
                  .form-control {
                    flex-grow: 1;
                    padding: 11px 20px;
                    border-color: #e0e0e0;
                  }
                  button {
                    height: 100%;
                  }
                  &-content {
                    padding: 0 20px;
                    p:not(:last-child) {
                      margin-bottom: 8px;
                    }
                  }
                }
              }
              .actions-container {
                border-top: 1px solid $border_color;
                padding: 20px;
                .grid {
                  justify-content: flex-end;
                  align-items: center;
                  .gifts {
                    flex-grow: 1;
                    table {
                      width: 100%;
                      tbody {
                        tr {
                          td {
                            padding: 10px 0;
                            &.quantity {
                              width: 15%;
                            }
                          }
                        }
                      }
                    }
                    .bonus-container {
                      .icon-wrapper {
                        span > span {
                          color: #f16e26;
                          padding: 0 5px;
                        }
                      }
                    }
                  }
                  .actions {
                    a.icon-wrapper {
                      margin: 10px 0;
                      @include default-link();
                      span {
                        padding-left: 10px;
                      }
                    }
                    .pay-container {
                      > .grid .row:first-child {
                        padding-right: 10px;
                      }

                      .popup {
                        padding: 10px;
                      }
                      .payments {
                        display: flex;
                        .payment {
                          &:not(:last-child) {
                            border-right: 1px solid #ededed;
                          }
                          a {
                            display: flex;
                            padding: 0 20px;
                            height: 60px;
                            i.icon {
                              display: block;
                              margin: auto;
                            }
                          }
                          .form {
                            display: none;
                          }
                        }
                      }
                    }
                    .cancel-container {
                      a.icon-wrapper {
                        i.icon {
                          margin-left: 3px;
                        }
                        span {
                          padding-left: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
            margin-bottom: 10px;
          }
        }
        .pagination {
          padding: 20px 0;
        }
      }
    }
  }

  .popup {
    $border: #ededed;
    $width: 8px;
    margin-top: 10px;
    border: 2px solid $border;
    background-color: #fff;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: $width solid $border;
      border-right: $width solid transparent;
      border-left: $width solid transparent;
    }
  }

  .popup-container {
    position: relative;
  }

  .profile-account-index {
    .link {
      @include default-link($link-secondary-color);
      text-decoration: underline;
      display: block;
      padding: 15px 0;
    }
  }

  .account-container,
  .password-container {
    .label {
      padding: 15px 0;
      text-align: center;
    }
    form {
      padding: 20px;
      .grid {
        flex-wrap: wrap;
        .row {
          flex-basis: 48%;
        }
      }
      .btn-submit {
        width: 50%;
        margin: 10px auto;
      }
      .form-group {
        margin-bottom: 15px;
        label {
          margin-bottom: 5px;
        }
        .form-control {
          padding: 0 15px;
          background: #fff;
          height: 51px;
          line-height: 51px;
        }
        .custom-select {
          .dropdown {
            background-color: #fff;
            border: 1px solid #e9e9e9;
            .form-group {
              padding: 7px;
              .form-control {
                border: 2px solid #ececec;
              }
            }
            ul {
              background: none;
              li {
                padding: 0 20px;
                //font-size: 15px;
                color: $secondary-color;
                &:hover {
                  background-color: #dfdfdf;
                }
                &.is-active {
                  background-color: #daeaf5;
                }
              }
            }
            .empty-result {
              padding: 5px 20px;
            }
          }
        }
      }
    }
  }

  .account-container {
    margin-bottom: 20px;
  }
  .password-container {
    .grid {
      .row {
        &:first-child {
          flex-basis: 100%;
        }
      }
    }
  }

  .wishlist-container {
    .actions {
      padding: 27px 57px;
      .custom-checkbox {
        .checkbox {
          background: #fff;
        }
      }
      a {
        @include default-link()
      }
      .askbuy {
        .icon-wrapper {
          span {
            padding: 0 15px;
          }
        }
        .tooltip {
          width: 300px;
        }
      }
      .remove {
        .icon-wrapper {
          span {
            color: #e89191;
            padding-left: 15px;
          }
        }
      }
      .is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .offers-list-container {
      padding: 0 20px;
      .offers-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &::after {
          height: 0;
          width: 32%;
          content: "";
        }
        .offer-item {
          width: 32%;
          margin-bottom: 15px;
        }
      }
    }
  }

  .addressbook-container {
    .label {
      padding: 15px 0;
      text-align: center;
    }
    .profiles-list-container {
      padding: 20px;
      .profiles-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &::after {
          height: 0;
          width: 32%;
          content: "";
        }
        .profile-item {
          width: 32%;
          margin-bottom: 35px;
          padding: 16px;
          &.is-active {
            background: #e5ebee;
          }
          .primary-container {
            margin-bottom: 40px;
            .custom-checkbox {
              justify-content: center;
              .checkbox {
                background: #fff;
              }
            }
          }
          .icon-wrapper {
            margin: 15px 0;
          }
          .delivery-type {
            i.icon {
              margin-right: 17px;
            }
          }
          .delivery-value {
            i.icon {
              margin-left: 6px;
              margin-right: 13px;
            }
          }
          .link {
            margin-top: 15px;
            @include default-link($link-secondary-color);
            text-align: center;
            text-decoration: underline;
          }
        }
        .profile-item-add {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          > span {
            display: block;
            flex-basis: 100%;
          }

          a {
            @include default-link();
            display: block;
            font-weight: bold;
            margin: auto;
            text-align: center;
          }
        }
      }
    }
    .edit-form {
      padding: 20px;
      .grid {
        .row {
          width: 48%;
        }
      }
      form {
        .form-group {
          margin-bottom: 15px;
          label {
            margin-bottom: 5px;
          }
          .form-control {
            padding: 0 15px;
            background: #fff;
            height: 51px;
            line-height: 51px;
          }
          .custom-select {
            .dropdown {
              background-color: #fff;
              border: 1px solid #e9e9e9;
              .form-group {
                padding: 7px;
                .form-control {
                  border: 2px solid #ececec;
                }
              }
              ul {
                background: none;
                li {
                  padding: 0 20px;
                  //font-size: 15px;
                  color: $secondary-color;
                  &:hover {
                    background-color: #dfdfdf;
                  }
                  &.is-active {
                    background-color: #daeaf5;
                  }
                }
              }
              .empty-result {
                padding: 5px 20px;
              }
            }
          }
          .btn-block {
            width: 50%;
            margin: 0 auto;
          }
          .btn-link {
            color: #e89191;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.modal-ask-buy {
  .modal-content {
    width: 400px;
  }
}

.bonus-profile-page {
  padding: 0 25px 25px 25px;
}

@include block('bonus-history') {
  margin-bottom: 15px;
  padding: 15px;
  background: #fff;
  @include element('content') {
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
  }
  @include element('group') {
    width: 25%;
  }
  @include element('key') {
    font-weight: bold;
    margin-bottom: 8px;
  }
  @include element('value') {
    .grid {
      justify-content: flex-start;
    }
    img {
      width: 17px;
      margin-right: 5px;
    }
    @include modifier('empty') {
      color: #767676;
      opacity: 0.5;
    }
    @include modifier('date') {
      color: #c27a92;
    }
    @include modifier('add') {
      color: #8bc870;
    }
    @include modifier('remove') {
      color: #e89191;
    }
  }
}
