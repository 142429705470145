// main
$main-color: #626161;
$secondary-color: #767676;
$footer-color: $secondary-color;
$modal-background-index: 10;
$modal-content-index: 20;

// buttons
$primary-button-color: #8bc870;
$primary-button-color-hover: #76bf56;
$secondary-button-color: #4998cc;
$secondary-button-color-hover: #3d8dc1;
$danger-button-color: #e89191;
$danger-button-color-hover: #ef7e7e;
$info-button-color: #c5e1f3;
$info-button-color-hover: #c5e1f3;
$orange-button-color: #ee8759;
$orange-button-color-hover: #e57b4c;
$grey-button-color: #dadada;
$grey-button-color-hover: #cac7c7;
$light-grey-button-color: #eeeeee;
$light-grey-button-color-hover: #dedcdc;
$inverse-button-color: #fff;
$inverse-button-color-hover: #afafaf;

// links
$link-primary-color: $main-color;
$link-secondary-color: $secondary-button-color;

// main-menu
$main-menu-colors: (
  #1788d2,
  #734ea5,
  #822f89,
  #ac4a6b,
  #c75756,
  #dc6948,
  #f16e26,
  #f18e58,
  #fe584c,
);
$important_color: #0078c1;

// breakpoints
$grid-breakpoints: (
  xx: 0,
  xs: 320px,
  //ms: 360px,
  sm: 460px,
  md: 768px,
  lg: 992px,
  xl: 1170px
) !default;

$gutter: 15px;

// catalog
$product-item-height: 450px;