.zoomer {
  position: relative;
  border: 0;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  //box-shadow: 0px 5px 10px #000;

  transition: all .5s ease-out;

  &:hover {
    // border-radius: 50%;
    cursor: move;
    .normal {
      opacity: 0;
      transform: scale(1.1);
    }
    .plus {
      opacity: 0;
      transform: scale(.8);
    }
  }
  .img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: white no-repeat center center;
    transition: transform ease-out .3s;
  }

  .normal {
    z-index: 5;
    &[lazy=loaded] {
      background-size: contain;
    }
  }

  img.normal {
    opacity: 0;
    width: 100%;
  }

  .zoom {
    z-index: 4;
    transition: none;
  }

  .plus {
    position: absolute;
    transition: all ease-out .4s;
    z-index: 6;
    right: 0;
    bottom: 60px;
  }
}



