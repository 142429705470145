.articles-list {
  padding-bottom: 40px;
  h1 {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    color: $secondary-color;
  }
  .articles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include grid_reset;
  }

  .article-item {
    width: 335px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 55px;
    > header {
      font-size: 15px;
      color: $secondary-color;
      font-weight: bold;
      margin-bottom: 12px;
      a {
        @include default-link(inherit);
      }
    }
    .articles-image {
      img {
        max-width: 100%;
      }
    }
    .info {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 13px;
      .date {
        color: $link-secondary-color;
      }
      .viewed {
        i.icon {
          margin-right: 5px;
        }
      }
    }
    .detail {
      margin: 20px 0;
      text-align: right;
      @include default-link($link-secondary-color);
      font-weight: bold;
      justify-content: flex-end;
      span {
        padding-right: 18px;
      }
    }
  }
}

.article-page {
  padding-bottom: 40px;
  @include element('container') {
    padding-bottom: 40px;
    max-width: 650px;
    margin: 0 auto;
  }
  @include element('header') {
    h1 {
      font-size: 27px;
      text-align: center;
      color: $secondary-color;
      font-weight: bold;
    }
  }
  @include element('info') {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
  }
  @include element('date') {
    color: $link-secondary-color;
    font-weight: bold;
  }
  @include element('viewed') {
    i.icon {
      margin-right: 5px;
    }
  }
  @include element('footer-title') {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
  }
}