.slider-content {
  margin-top: 12px;
  .row {
    &.pull-left {
      float: left;
    }
    &.pull-right {
      float: right;
    }
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  .slider-wrapper {
    position: relative;
    width: 100%;
    //max-width: 960px;
    //height: 345px;
    //.slides {
    //  position: relative;
    //  > div {
    //    //position: absolute;
    //  }
    //}
    .VueCarousel-pagination {
      display: none;
      position: absolute;
      bottom: 12px;
      .VueCarousel-dot {
        .VueCarousel-dot-inner {
          border: 1px solid #007dc6;
        }
        &--active {
          .VueCarousel-dot-inner {
            border: 2px solid #007dc6;
          }
        }
      }
    }
    .thumbnails {
      position: absolute;
      bottom: 11px;
      width: 100%;
      ul {
        display: flex;
        justify-content: center;
        > li {
          padding: 0 10px;
          img {
            border: 4px solid transparent;
            opacity: 0.65;
            cursor: pointer;
          }
          &.active {
            img {
              border-color: #0092d8;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// animation for slider
//.slider-animation-enter-active, .slider-animation-leave-active {
//  transition: all 1s;
//}
//.slider-animation-enter, .slider-animation-leave-active {
//  opacity: 0;
//}
//.slider-animation-enter {
//  //transform: translateX(400px);
//}
//.slider-animation-leave-active {
//  //transform: translateX(-400px);
//}
