.vue-scrollbar-transition, .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__scrollbar-horizontal {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.vue-scrollbar-transition--scrollbar {
  transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
}

.vue-scrollbar__wrapper {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background: white;
}
.vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-horizontal {
  opacity: 1;
}
.vue-scrollbar__scrollbar-vertical, .vue-scrollbar__scrollbar-horizontal {
  opacity: 0.5;
  position: absolute;
  background: transparent;
}
.vue-scrollbar__scrollbar-vertical:hover, .vue-scrollbar__scrollbar-horizontal:hover {
  background: rgba(0, 0, 0, 0.3);
}
.vue-scrollbar__scrollbar-vertical .scrollbar, .vue-scrollbar__scrollbar-horizontal .scrollbar {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
}
.vue-scrollbar__scrollbar-vertical {
  width: 10px;
  height: 100%;
  top: 0;
  right: 0;
}
.vue-scrollbar__scrollbar-vertical .scrollbar {
  width: 10px;
}
.vue-scrollbar__scrollbar-horizontal {
  height: 10px;
  width: 100%;
  bottom: 0;
  right: 0;
}
.vue-scrollbar__scrollbar-horizontal .scrollbar {
  height: 10px;
}
