//.wizard {
//  .steps {
//    display: flex;
//    justify-content: center;
//  }
//}

.vue-form-wizard {
  .wizard-navigation {
    .wizard-nav {
      display: flex;
      .wizard-icon-circle {
        display: none;
      }
    }
  }
}