@include block('search-list') {
  display: flex;
  @include element('categories') {
    width: 50%;
    flex-shrink: 0;
  }
  @include element('products') {
    flex-grow: 1;
  }
  @include element('title') {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  @include element('category-item') {
    a {
      @include default-link(#727272);
    }
  }
  @include element('brand-logo') {
    max-width: 120px;
    padding: 8px 0;
  }
  @include element('product-item') {
    margin-bottom: 8px;
    a {
      @include default-link();
    }
    .grid {
      justify-content: flex-start;
      .row {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @include element('product-image') {
    @include image();
    width: 50px;
    flex-shrink: 0;
  }
  @include element('product-name') {
    color: #727272;
    width: 100%;
  }
  @include element('product-name-alias') {
    color: #b2b2b2;
    width: 100%;
  }
}

.popup-search-results {
  width: 800px;
  padding: 14px 26px;
  border: 1px solid #c0c0c0;
  //border-top: 1px solid #e5e5e5;
  border-top-color: #e5e5e5;
  margin-top: 2px;
  z-index: 9;
  background: #fff;
  .v-spinner {
    text-align: center;
  }
  .vb-content {
    max-height: 420px;
  }
  .search-result-item {
    text-decoration: none;
    min-height: 60px;
    padding-bottom: 10px;
    &:not(:first-child) {
      margin-top: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #cacaca;
    }
    p {
      color: #727272;
      &.second-name {
        color: #b2b2b2;
        margin-top: 10px;
      }
    }
    &.grid {
      justify-content: flex-start;
      align-items: center;
      .row {
        &.image {
          //width: 70px;
          flex-basis: 70px;
          text-align: center;
        }
        &.content {
          flex-basis: calc(100% - 70px);
        }
      }
    }
  }
}