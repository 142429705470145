@include block('stock-single-list') {
  margin: 15px 0;
}

@include block('stock-single-item') {
  display: flex;
  align-items: center;
  border: 1px solid #97c9e4;
  padding: 19px 12px;
  text-decoration: none;
  @include modifier('slim') {
    padding: 9px 12px;
  }
  @include modifier('centered') {
    justify-content: center;
  }

  span {
    display: block;
  }

  @include element('icon') {
    margin-right: 18px;
  }

  @include element('content') {
    color: #4998cc;
  }

  @include element('name') {
    margin-bottom: 10px;
    font-weight: bold;
  }

  @include element('description') {

  }
}