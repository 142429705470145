.order-make {
  width: 578px;
  margin: 0 auto;
  text-align: center;
  > header {
    display: flex;
    justify-content: center;
  }
  h4 {
    margin: 24px 0;
  }
  .greeting {
    margin-bottom: 70px;
    font-size: 15px;
    span {
      color: $primary-button-color;
      font-weight: bold;
      &.order-id {
        font-size: 18px;
      }
    }
  }

  > .grid {
    > .row {
      .grid {
        justify-content: flex-start;
        align-items: center;
        .row {
          &.label,
          &.value {
            font-weight: bold;
            font-size: 16px;
          }
          &.label {
            color: $secondary-color;
          }
          &.value {
            padding-left: 10px;
            color: $primary-button-color;
            .icon-wrapper {
              span {
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  p.hint {
    margin-top: 10px;
    text-align: left;
    width: 230px;
    font-size: 13px;
  }

  &.guest-mode {
    > .grid.pay-info {
      justify-content: center;
    }
  }

  .separator {
    width: 260px;
    margin: 40px auto 40px auto;
    border-bottom: 1px solid #ededed;
  }

  .user-budget {
    text-align: left;
    margin-bottom: 40px;
    span {
      &.amount {
        color: $primary-button-color;
        font-weight: bold;
      }
      &.container {
        position: relative;
        a {
          @include default-link($link-secondary-color);
          text-decoration: underline;
        }
        .popup {
          width: 326px;
          padding: 15px 25px;
          &:after {
            margin-left: 42px;
          }
          .grid {
            justify-content: space-around;
          }
          .form-group {
            .form-control {
              width: 94px;
              height: 38px;
              line-height: 38px;
              background: #ededed !important;
              font-weight: bold;
            }
            .help-block-error {
              font-size: inherit;
              color: #e89191;
              text-align: left;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .popup {
    $border: #ededed;
    $width: 8px;
    margin-top: 10px;
    border: 2px solid $border;
    background-color: #fff;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: $width solid $border;
      border-right: $width solid transparent;
      border-left: $width solid transparent;
    }
  }

  .popup-container {
    position: relative;
  }

  .payments-container {
    > .grid .row:first-child {
      padding-right: 10px;
    }

    .popup {
      padding: 10px;
    }
    .payments {
      display: flex;
      .payment {
        &:not(:last-child) {
          border-right: 1px solid #ededed;
        }
        a {
          display: flex;
          padding: 0 20px;
          height: 60px;
          i.icon {
            display: block;
            margin: auto;
          }
        }
        .form {
          display: none;
        }
      }
    }
  }

  .payments-details-container {
    > .grid .row:first-child {
      padding-right: 10px;
    }

    .popup {
      width: 310px;
      padding: 25px;
      .grid {
        justify-content: space-around;
      }
      a {
        @include default-link($link-secondary-color);
        text-decoration: underline;
        font-weight: bold;
      }

      .icon-wrapper {
        span {
          padding-left: 10px;
        }
      }
    }
  }

  .btn-small {
    width: 94px;
  }

  .actions {
    margin-top: 40px;
    margin-bottom: 80px;
    .btn {
      width: 220px;
    }
  }
}