@include media-breakpoint-down(sm) {
  .answer-form {
    .grid {
      flex-wrap: wrap;
      .row {
        flex-basis: 100%;
      }
      &.actions {
        .row:first-child {
          order: 1;
        }
      }
    }
  }
}