.swipe-wrapper {
  //position: fixed;
  //left: 0;
  //top: 0;
  //bottom: 0;
  //width: 100%;
  //height: 100%;
  //visibility: hidden;
  &.is-active {
    .swipe-content {
      transform: translateX(0px) !important;
    }
  }
  .swipe-blocker {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .swipe-content {
    position: fixed;
    top: 0;
    height: 100%;
    width: 500px;
    transition: 0.3s all ease;
    transform: translateX(-500px);
    z-index: 12;
  }
}