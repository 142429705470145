/*
 Большинство иконок находятся в /build/css/icons.css
 Подробнее в > gulp sprite

 Тут находятся только базовый клас для иконок wrapper, кастомные спрайты,
 а также дополнительные стили для базовых иконок
*/

.icon {
  display: inline-block;
  &.icon-top-cart {
    margin-right: 5px;
    margin-top: -2px;
  }
  &.icon-request-call {
    margin-right: 10px;
  }
  &.icon-search-mini {
    //margin-top: 15px;
    margin-right: 12px;
    background: url("/build/images/icon_search_mini.png") no-repeat 0 -1px;
    width: 17px;
    height: 17px;
  }
}
.icon-wrapper {
  display: flex;
  align-items: center;
  .icon {
    flex-shrink: 0;
  }
}