.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  > .checkbox {
    display: flex;
    background: #e8e8e8;
    width: 18px;
    height: 18px;
    > i.icon {
      display: block;
      margin: auto;
    }
  }
  > span {
    padding-left: 10px;
  }
}

.fade-enter-active {
  transition: opacity .5s;
}
.fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}