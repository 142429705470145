@include media-breakpoint-up(lg) {
  .fixed {
    top: 38px;
    width: 1170px;
  }
}

@include media-breakpoint-down(lg) {
  .fixed {
    top: 0;
    left: 0;
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .brand-page {
    padding: 0 15px;
    .brand-panel {
      ul {
        flex-wrap: wrap;
      }
    }
    .brand-list {
      .row {
        .letter-key {
          flex-basis: 65px;
          padding: 60px 0 0 15px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .brand-page {
    .brand-panel {
      display: none;
    }
    .brand-list {
      .row {
        flex-wrap: wrap;
        .letter-key {
          flex-basis: 100%;
          text-align: center;
          padding: 30px 0;
        }
      }
    }
  }
}