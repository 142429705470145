.search-page-wrapper {
  margin: 20px 0;
  .search-page-title {
    span {
      font-weight: bold;
    }
  }
  .search-page-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include grid_reset;
  }
}