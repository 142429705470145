@include block('site-error') {
  padding: 50px 0;
  @include element('container') {
    display: flex;
    border: 1px solid #e0dede;
    background: #f5f4f4;
  }

  @include element('image') {
    display: block;
    width: auto;
    max-width: 300px;
  }
  @include element('content') {
    align-self: center;
    padding: 0 35px;
  }
  @include element('message') {
    color: #af3734;
    font-size: 28px;
    font-weight: 700;
  }
}