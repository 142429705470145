@include media-breakpoint-down(lg) {
  .main-tabs {
    .tabs-content {
      .VueCarousel {
        .VueCarousel-navigation-button {
          &.VueCarousel-navigation-prev {
            left: $gutter;
          }
          &.VueCarousel-navigation-next {
            right: $gutter;
          }
        }
      }
    }
  }
  #root {
    > main {
      .slider-content {
        //justify-content: center;
      }
    }
  }

  .slider-content {
    .VueCarousel {
      .VueCarousel-navigation-button {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-tabs {
    .tabs-content {
      //.product-wrapper {
      //  width: 220px !important;
      //}
      .VueCarousel {
        .VueCarousel-wrapper {
          margin: 0px 0 -150px 0;
          padding: 0px 0px 150px 0;
          .VueCarousel-inner {
            max-height: $product-item-height;
          }
        }
        .VueCarousel-navigation-button {
          background: url('/build/images/vue-slider-arrows.png') no-repeat 0 0;
          width: 10px;
          height: 18px;
          text-indent: -9999px;
          top: 40%;
          &.VueCarousel-navigation-prev {
            background-position: 0px -31px;
          }
          &.VueCarousel-navigation-next {
            background-position: -20px -31px;
          }
          &.VueCarousel-navigation--disabled {
            opacity: 0.2;
          }
        }
      }
    }
  }
}