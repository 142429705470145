@include block('questions') {
  @include element('button') {
    padding: 12px 35px;
  }
  @include element('sorry-message') {
    text-align: center;
    margin-bottom: 18px;
  }
  @include element('button-ask') {
    text-align: center;
    margin-bottom: 20px;
  }
  @include element('list') {

  }
}