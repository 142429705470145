.answer-item-container {
  margin-top: 20px;
  padding-left: 30px;
  .answer-icon {
    padding-right: 20px;
  }
  .answer-item {
    flex-grow: 1;
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;
    > header {
      justify-content: space-between;
      .name {
        color: $secondary-color;
        span {
          color: #333;
        }

        &.is-admin {
          span {
            color: #1b88d3;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
      .date {
        color: #a4a4a4;
      }
    }
    > article {
      color: $secondary-color;
      margin: 20px 0;
      .signature {
        font-style: italic;
        margin-top: 15px;
      }
    }
    > footer {
      a {
        @include default-link(#4998cc);
      }
    }
  }

  .children {
    padding-left: 50px;
    .show-more-child {
      padding: 20px 0;
      a {
        justify-content: center;
        @include default-link($main-color);
        i.icon {
          margin-left: 10px;
        }
      }
    }
  }
}