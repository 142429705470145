@include media-breakpoint-up(md) {
  #root.order-mode {
    @include block('layout') {
      @include element('header') {
        .top-menu {
          > .grid {
            > .row {
              .auth-form-container {
                .modal-sticked {
                  left: auto;
                  margin-left: -200px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #root.order-mode {
    @include block('layout') {
      @include element('header') {
        .middle-menu {
          > .grid {
            justify-content: center;
            > .row {
              &.logo {
                padding: 0 100px;
                flex-grow: 0;
              }
              &.request-call {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    .order-page .vue-form-wizard .wizard-tab-content {
      flex-wrap: wrap;
      .total-container {
        display: block;
        width: 100%;
        .cart-items {
          display: none;
        }
        .cart-carousel {
          display: block;
          padding: 0 30px;
          background-color: #fff;

        }
      }
      .wizard-tab-container {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #root.order-mode {
    @include block('layout') {
      @include element('header') {
        .middle-menu {
          > .grid {
            justify-content: space-around;
            > .row {
              &.logo {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .top-menu > .grid > .row .city-chooser-container {
      .city-chooser {
        margin-left: 15px;
        span {
          border-bottom: none;
        }
      }
    }

    .order-page {
      .vue-form-wizard {
        .wizard-navigation .wizard-nav {
          .wizard-icon-circle {
            height: auto;
          }
          li {
            a {
              padding: 15px 25px;
              display: flex;
            }
            span.stepTitle {
              display: none;
            }
          }
        }
        .wizard-tab-content {
          .wizard-tab-container {
            .grid.center {
              flex-wrap: wrap;
              > .row {
                width: 100%;
              }
            }
          }
        }
      }

      form .actions {
        .row:first-child {
          order: 1;
          .icon-wrapper {
            justify-content: center;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  #root.order-mode {
    .top-menu > .grid > .row:first-child {
      flex-basis: 100%;
    }

    @include block('layout') {
      @include element('header') {
        .middle-menu {
          .logo {
            img {
              max-width: 180px;
            }
          }
        }
      }
    }

    .order-page {
      .vue-form-wizard {
        .wizard-navigation .wizard-nav {
          li {
            a {
              padding: 5px 15px;
              display: flex;
            }
            &:not(:last-child):after {
              margin: auto 15px;
            }
          }
        }
      }
    }
  }
}