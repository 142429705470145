@include media-breakpoint-up(xl) {
  .modal-cart {
    .modal-content {
      > .vb {
        .vb-content {
          width: calc(100% + 17px) !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .modal-cart {
    .modal-content {
      width: 731px;
    }
  }
}

@include media-breakpoint-down(md) {
  .modal-cart {
    .cart-informer-content {
      .scroll-wrapper {
        .vb-dragger {
          right: 0 !important;
        }
      }
      .total-container {
        font-size: 15px;
      }
      .progress-bar-container {
        font-size: 14px;
      }
    }
    .products-viewed {
      .cart-product-viewed {
        .names {
          width: calc(100% - 170px);
          .primary-name a,
          .secondary-name {
            font-size: 14px;
          }
        }
        .actions {
          .price-container {
            font-size: 15px;
          }
          .btn-small {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .modal-cart {
    width: 100%;
    .cart-informer-content {
      .cart-informer-block {
        .grid {
          &:not(.no-wrap) {
            flex-wrap: wrap;
          }
          .image a {
            width: 90px;
            height: 90px;
          }
          .name-container {
            width: calc(100% - 120px);
          }
          .quantity-container {
            margin-left: 120px;
            a {
              font-weight: 600;
            }
          }
        }
      }
      .total-container {
        display: flex;
        flex-direction: column-reverse;
        .grid {
          flex-wrap: wrap;
          &.info {
            flex-direction: column-reverse;
            .discounts-wrapper {
              display: flex;
              flex-direction: column-reverse;
            }
          }
          .row {
            width: 100%;
            .btn {
              margin: 5px 0;
            }
          }
        }
      }
      .progress-bar-container {
        font-size: 15px;
        .progress-bar {
          width: 100%;
        }
      }
    }
    .products-viewed {
      .cart-product-viewed {
        display: block;
        &:after {
          display: block;
          content: '';
          clear: both;
        }
        //flex-wrap: wrap;
        //.grid {
        //  flex-wrap: wrap;
        //}
        .image {
          float: left;
          a {
            width: 120px;
          }
        }
        .names {
          float: left;
          width: calc(100% - 120px);
        }
        .actions {
          float: left;
          padding: 0;
          > div {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
          .price-container {
            width: 100%;
            padding: 14px 0;
          }
          .offers-chooser {
            flex-basis: 50%;
            .extended {
              width: 193px;
            }
          }
          .btn-buy {
            width: 38px;
            height: 32px;
            margin-left: 20px;
            padding: 7px;
          }
        }
      }
    }
  }

  .modal-average {
    .modal-content {
      .modal-title {
        padding: 28px 16px;
      }
    }
    @include block('controls') {
      flex-wrap: wrap;
      padding-top: 0;
      @include element('button') {
        width: 100%;
        @include modifier('last') {
          order: -1;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .modal-cart {
    .cart-informer-content {
      .cart-informer-block {
        .grid {
          .quantity-container {
            margin-left: 0;
          }
        }
      }
    }
    .products-viewed {
      .cart-product-viewed {
        .actions {
          width: 100%;
        }
      }
    }
  }
}