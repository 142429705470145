@mixin editor-content() {
  &:after {
    display: block;
    content: '';;
    clear: both;
  }

  a {
    @include default-link($link-secondary-color);
  }

  ol, ul, dl {
    /* IE7: reset rtl list margin. (#7334) */
    *margin-right: 0px;
    /* preserved spaces for list items with text direction other than the list. (#6249,#8049)*/
    padding: 0 40px;

  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 20px;
    &:not(:first-child) {
      margin-top: 20px;
    }
    font-size: inherit;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  i, em {
    font-style: italic;
  }
}