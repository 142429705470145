@include media-breakpoint-down(md) {
  .breadcrumbs {
    padding: 0 15px;
  }
}

@include media-breakpoint-down(xs) {
  .breadcrumbs {
    ul {
      li:not(:last-child) {
        display: none;
      }
    }
  }
}