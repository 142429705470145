@include block('review-item') {
  margin: 20px 0;

  @include block('review-item') {
    margin-left: 50px;
  }

  //@include modifier('simple') {
  //  @include element('name') {
  //    flex-basis: 140px;
  //  }
  //}

  @include element('header') {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include element('name') {
    color: #333;
  }
  @include element('rating') {
    padding-left: 12px;
  }
  @include element('recommendations') {
    flex-grow: 1;
    .icon-wrapper {
      i.icon {
        margin-right: 5px;
      }
    }
  }
  @include element('recommendations-content') {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include element('recommendations-row') {
    padding: 0 15px;
    @include modifier('first') {
      .icon-wrapper {
        span {
          &.up {
            color: #8bc870;
          }
          &.down {
            color: #e89191;
          }
        }
      }
    }
    @include modifier('last') {
      .icon-wrapper {
        span {
          color: #8bc870;
        }
      }
    }
  }
  @include element('date') {
    color: #a4a4a4;
  }

  @include element('content') {
    color: $secondary-color;
    a {
      @include default-link($link-secondary-color);
      display: inline-block;
      margin: 5px 0;
    }
  }

  $list: parameters, advantages, limitations, files;
  @each $el in $list {
    @include element($el) {
      margin-top: 12px;
      span {
        &.label {
          color: #333;
          padding-right: 5px;
        }
      }
    }
  }
  @include element('parameters') {
    display: flex;
    margin: 0 0 12px;
  }
  @include element('parameters-row') {
    padding-right: 35px;
  }

  @include element('files') {
    > div {
      margin-bottom: 20px;
    }
  }

  @include element('files-list') {
    display: flex;
    align-items: center;
    > a {
      margin: 5px;
    }
  }

  @include element('footer') {
    margin-top: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
  }
  @include element('answer-button') {
    @include default-link(#4998cc);
  }
  @include element('recommendation-label') {
    flex-grow: 1;
    text-align: right;
    padding-right: 30px;
  }
  @include element('stats') {
    display: flex;
  }
  @include element('stats-row') {
    padding: 0 5px;
  }
  @include element('stats-action') {
    @include default-link();
    @include state('disabled') {
      cursor: default;
    }
    span {
      padding: 0 5px;
    }
  }
}

@include block('review-last-item') {
  padding: 0 5px;
  @include element('image-link') {
    @include image(100%);
  }
  @include element('rating') {
    margin-bottom: 5px;
  }
  @include element('link') {
    @include default-link();
    font-weight: bold;
  }

  @include element('content') {
    margin: 8px 0;
    color: $secondary-color;
  }
}