@charset "UTF-8";
/* Глобальные настройки */
/* Различные миксины */
/* Различные хелперы */
.container {
  max-width: 1170px;
  margin: 0 auto; }

.grid {
  display: flex;
  justify-content: space-between; }

.text-center {
  text-align: center; }

/* Подлкючаем компоненты */
/*
 Большинство иконок находятся в /build/css/icons.css
 Подробнее в > gulp sprite

 Тут находятся только базовый клас для иконок wrapper, кастомные спрайты,
 а также дополнительные стили для базовых иконок
*/
.icon {
  display: inline-block; }
  .icon.icon-top-cart {
    margin-right: 5px;
    margin-top: -2px; }
  .icon.icon-request-call {
    margin-right: 10px; }
  .icon.icon-search-mini {
    margin-right: 12px;
    background: url("/build/images/icon_search_mini.png") no-repeat 0 -1px;
    width: 17px;
    height: 17px; }

.icon-wrapper {
  display: flex;
  align-items: center; }
  .icon-wrapper .icon {
    flex-shrink: 0; }

a.btn {
  display: inline-block; }

.btn {
  outline: none;
  border: none;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  line-height: normal;
  padding: 12px 10px; }
  .btn:hover {
    cursor: pointer; }
  .btn.btn-block {
    display: block;
    width: 100%; }
  .btn.btn-small {
    padding: 9px 10px;
    font-size: 12px; }
  .btn.btn-medium {
    padding: 12px 10px; }
  .btn.btn-big {
    padding: 15px 10px;
    font-size: 15px; }
  .btn.btn-primary {
    background: #8bc870; }
    .btn.btn-primary:hover {
      background: #76bf56; }
  .btn.btn-secondary {
    background: #4998cc; }
    .btn.btn-secondary:hover {
      background: #3d8dc1; }
  .btn.btn-danger {
    background-color: #e89191; }
    .btn.btn-danger:hover {
      background-color: #ef7e7e; }
  .btn.btn-info {
    background-color: #c5e1f3; }
    .btn.btn-info:hover {
      background-color: #c5e1f3; }
  .btn.btn-orange {
    background-color: #ee8759; }
    .btn.btn-orange:hover {
      background-color: #e57b4c; }
  .btn.btn-grey {
    color: #767676;
    background-color: #dadada; }
    .btn.btn-grey:hover {
      background-color: #cac7c7; }
  .btn.btn-light-grey {
    color: #767676;
    background-color: #eeeeee; }
    .btn.btn-light-grey:hover {
      background-color: #dedcdc; }
  .btn.btn-inverse {
    background: #fff;
    color: #626161; }
    .btn.btn-inverse:hover {
      color: #fff !important;
      background-color: #afafaf; }
  .btn.btn-link {
    color: #626161;
    background: none;
    font-family: inherit; }
    .btn.btn-link:hover {
      background: none; }
  .btn:disabled {
    opacity: 0.5; }
    .btn:disabled:hover {
      cursor: not-allowed; }

input,
select,
textarea {
  outline: none;
  font-family: inherit;
  font-size: 14px; }

input[type="submit"] {
  border: none; }

textarea {
  height: auto;
  resize: none; }

input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent; }

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent; }

/* IE 10+ */
.form-group {
  margin-bottom: 8px;
  line-height: normal; }
  .form-group label {
    display: block; }
  .form-group .form-control {
    background-color: #e9e9e7;
    height: 42px;
    line-height: 42px;
    color: #6d6d6d;
    display: block;
    width: 100%;
    border: none; }
    .form-group .form-control:disabled {
      cursor: not-allowed; }
    .form-group .form-control.custom-select ul {
      background-color: #f0f0ee; }
      .form-group .form-control.custom-select ul li:hover {
        background: #f3f3f1; }
      .form-group .form-control.custom-select ul li.is-active {
        background: #daeaf5; }
    .form-group .form-control.custom-select.is-disabled, .form-group .form-control.custom-checkbox.is-disabled {
      cursor: not-allowed; }
  .form-group textarea.form-control {
    padding: 24px 17px;
    height: auto;
    line-height: normal; }
  .form-group .help-block-error {
    color: #ff0000;
    font-size: 12px; }

.form-errors {
  background: #ff0000;
  color: #fff;
  margin-bottom: 8px; }

.modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  overflow-y: auto;
  position: fixed;
  z-index: 1986;
  display: flex;
  transition: opacity .3s ease; }
  .modal .modal-background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background-color: rgba(10, 10, 10, 0.86); }
    .modal .modal-background.transparent {
      background: transparent; }
  .modal .modal-content {
    position: relative;
    margin: auto;
    color: #626161;
    padding: 28px 16px;
    background-color: #f3f3f5;
    transition: all .3s ease; }
    .modal .modal-content .modal-close {
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
      z-index: 10; }
      .modal .modal-content .modal-close .close {
        background-image: url("/build/images/icon_close.svg");
        background-size: contain;
        fill: #626161;
        stroke: #626161;
        display: block;
        width: 25px;
        height: 25px; }
    .modal .modal-content .modal-title {
      padding-bottom: 10px;
      text-align: center;
      color: #333333;
      font-size: 16px; }
  .modal .form-group * {
    text-align: center; }

.is-modal-active {
  position: relative;
  z-index: 20; }

.modal-enter {
  opacity: 0; }

.modal-leave-active {
  opacity: 0; }

.modal-enter .modal-content,
.modal-leave-active .modal-content {
  transform: scale(1.1); }

.tabs ul {
  display: flex;
  justify-content: space-between; }
  .tabs ul li {
    width: 100%;
    text-align: center; }
    .tabs ul li a {
      display: block; }

.popup {
  position: absolute; }

.product-wrapper {
  position: relative;
  width: 270px;
  min-height: 450px; }

.product-item {
  pointer-events: auto;
  position: relative;
  margin-bottom: 20px; }
  .product-item__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    right: -5px;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.5);
    transition: all 0.3s;
    background-color: #fff;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.25); }
  .product-item__visible {
    position: relative; }
  .product-item__image {
    display: flex;
    width: 100%;
    height: 280px; }
    .product-item__image img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  .product-item__code {
    font-size: 12px;
    width: 124px;
    margin: 0 auto;
    border: 2px dotted #ffaf8b;
    text-align: center;
    line-height: 17px;
    color: #767676; }
    .product-item__code > span {
      color: #3c3c3c; }
  .product-item__names {
    display: block;
    margin-top: 5px;
    text-align: center;
    height: 90px;
    color: #333333;
    text-decoration: none;
    outline: none; }
    .product-item__names:hover {
      text-decoration: none; }
    .product-item__names span {
      display: block;
      overflow: hidden; }
  .product-item__alias-name {
    margin-top: 5px;
    color: #767676;
    padding: 0 2px; }
  .product-item__unavailable-text {
    color: #333333;
    font-size: 15px;
    text-align: center;
    min-height: 32px;
    line-height: 32px; }
  .product-item__hidden {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    backface-visibility: hidden;
    transition: all 0.3s;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .product-item__hidden .buy-container {
      width: 193px;
      margin: 12px auto;
      justify-content: center; }
      .product-item__hidden .buy-container .row:last-child {
        flex-grow: 1; }
      .product-item__hidden .buy-container .wishlist-link,
      .product-item__hidden .buy-container .preview-link {
        margin-right: 27px; }
      .product-item__hidden .buy-container .btn-buy {
        font-size: 12px; }
    .product-item__hidden .icon-wrapper {
      justify-content: center; }
    .product-item__hidden .available-reminder-container {
      padding: 0 27px;
      margin: 13px 0; }
      .product-item__hidden .available-reminder-container .btn {
        width: 216px; }
  .product-item .product-bonus {
    position: absolute;
    right: 16px;
    top: 12px; }
  .product-item .product-price {
    justify-content: center;
    margin-top: 6px; }
  .product-item.is-active {
    width: 100%;
    z-index: 2; }
    .product-item.is-active .product-item__background {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .product-item.is-active .product-item__overlay {
      display: none; }
    .product-item.is-active .product-item__hidden {
      opacity: 1;
      z-index: 2;
      transform: translate3d(0, 0, 0); }
  .product-item.is-unavailable .product-item__image {
    opacity: 0.45; }
  .product-item--average .product-price {
    margin-top: 14px; }
  .product-item--average .offers-chooser {
    margin: 14px 0; }
  .product-item--average .btn-small {
    font-size: 13px; }
  .product-item--average .product-item__image {
    height: 200px; }

.product-bonus__value {
  color: #e57521;
  margin-right: 5px; }

.product-price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 33px;
  font-size: 15px; }
  .product-price--center {
    justify-content: center; }
  .product-price__label {
    margin-right: 5px; }
  .product-price__current {
    color: #333; }
  .product-price.has-discount .product-price__current {
    border: 1px solid #e9bac4;
    color: #d98596;
    padding: 7px 11px;
    border-radius: 2px; }
    .product-price.has-discount .product-price__current > span {
      font-weight: 600; }
  .product-price.has-discount .product-price__old {
    color: #d0d0d0;
    text-decoration: line-through;
    margin-left: 9px; }
  .product-price__economy {
    width: 100%;
    font-size: 13px;
    padding: 7px 7px 0; }
  .product-price--large .product-price {
    font-size: 17px; }
    .product-price--large .product-price__current {
      font-size: 19px; }
      .product-price--large .product-price__current > span {
        font-weight: 600; }

.product-wrapper .product-item .label-offer-container {
  position: absolute;
  top: 5px;
  left: 5px;
  justify-content: center;
  width: 100%; }
  .product-wrapper .product-item .label-offer-container .label {
    color: #fff;
    padding: 6px 19px;
    font-weight: 300; }
    .product-wrapper .product-item .label-offer-container .label.label-discount {
      background-color: rgba(241, 67, 75, 0.7);
      border-radius: 100%;
      height: 50px;
      width: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 17px;
      padding: 0; }

.product-wrapper .product-item .label-product-container {
  position: absolute;
  top: 11px;
  left: 15px; }

.product-wrapper .product-item .rating-widget {
  margin: 5px auto; }

.modal-offer-choose .modal-content {
  width: 731px; }
  .modal-offer-choose .modal-content .modal-title {
    text-align: left;
    padding-top: 0;
    font-size: 14px; }
    .modal-offer-choose .modal-content .modal-title h3 {
      font-size: 15px;
      color: #333333;
      margin-bottom: 8px; }
    .modal-offer-choose .modal-content .modal-title h4 {
      font-size: 13px;
      color: #767676; }
    .modal-offer-choose .modal-content .modal-title .row.names {
      flex-basis: 70%; }
    .modal-offer-choose .modal-content .modal-title .row.info {
      margin-right: 47px; }
      .modal-offer-choose .modal-content .modal-title .row.info .code {
        font-size: 12px;
        color: #767676;
        margin-bottom: 8px; }
        .modal-offer-choose .modal-content .modal-title .row.info .code > span {
          color: #333333; }
  .modal-offer-choose .modal-content .chooser .list-scrollbar {
    max-height: 300px; }
  .modal-offer-choose .modal-content .chooser .preview,
  .modal-offer-choose .modal-content .chooser .list-vertical {
    background-color: #fff; }
  .modal-offer-choose .modal-content .chooser .preview {
    display: flex;
    width: 266px;
    height: auto; }
    .modal-offer-choose .modal-content .chooser .preview img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  .modal-offer-choose .modal-content .chooser .list-vertical {
    flex-basis: 426px; }
    .modal-offer-choose .modal-content .chooser .list-vertical ul {
      padding: 14px 30px; }
      .modal-offer-choose .modal-content .chooser .list-vertical ul li {
        display: flex;
        align-items: center;
        color: #767676;
        height: 40px;
        margin: 1px 0; }
        .modal-offer-choose .modal-content .chooser .list-vertical ul li.is-active {
          background-color: #f3f3f5; }
        .modal-offer-choose .modal-content .chooser .list-vertical ul li.is-disabled:not(.is-active) {
          opacity: 0.3; }
        .modal-offer-choose .modal-content .chooser .list-vertical ul li:hover {
          cursor: pointer; }
        .modal-offer-choose .modal-content .chooser .list-vertical ul li img {
          max-width: 40px;
          max-height: 40px; }
        .modal-offer-choose .modal-content .chooser .list-vertical ul li span {
          padding: 13px 19px; }
          .modal-offer-choose .modal-content .chooser .list-vertical ul li span.icon-container {
            padding: 0 15px 0 0;
            margin-left: auto; }
  .modal-offer-choose .modal-content .chooser .list-horizontal {
    margin-top: 2px;
    background: #fff;
    padding: 0 30px;
    max-height: 120px; }
    .modal-offer-choose .modal-content .chooser .list-horizontal.is-scrollable {
      position: relative;
      max-width: 100%; }
      .modal-offer-choose .modal-content .chooser .list-horizontal.is-scrollable:before, .modal-offer-choose .modal-content .chooser .list-horizontal.is-scrollable:after {
        position: absolute;
        display: block;
        content: '';
        background: url("/build/images/vue-slider-arrows.png") no-repeat 0 0;
        width: 10px;
        height: 18px;
        top: calc(50% - 18px);
        opacity: 0.5; }
      .modal-offer-choose .modal-content .chooser .list-horizontal.is-scrollable:before {
        left: 12px;
        background-position: -6px -31px; }
      .modal-offer-choose .modal-content .chooser .list-horizontal.is-scrollable:after {
        right: 12px;
        background-position: -20px -31px; }
      .modal-offer-choose .modal-content .chooser .list-horizontal.is-scrollable ul {
        overflow: auto;
        justify-content: flex-start; }
    .modal-offer-choose .modal-content .chooser .list-horizontal ul {
      display: flex;
      align-items: center;
      justify-content: center; }
      .modal-offer-choose .modal-content .chooser .list-horizontal ul li {
        position: relative;
        color: #767676;
        flex-basis: 75px;
        min-width: 75px;
        text-align: center;
        padding: 10px 0;
        border: 4px solid transparent; }
        .modal-offer-choose .modal-content .chooser .list-horizontal ul li.is-active {
          border-color: #f3f3f5;
          padding: 10px; }
        .modal-offer-choose .modal-content .chooser .list-horizontal ul li.is-disabled:not(.is-active) {
          opacity: 0.3; }
        .modal-offer-choose .modal-content .chooser .list-horizontal ul li > span {
          display: block; }
          .modal-offer-choose .modal-content .chooser .list-horizontal ul li > span.image {
            width: 47px;
            margin: 0 auto; }
          .modal-offer-choose .modal-content .chooser .list-horizontal ul li > span.name {
            padding-top: 8px; }
          .modal-offer-choose .modal-content .chooser .list-horizontal ul li > span.icon-container {
            position: absolute;
            top: 3px;
            left: 12px; }
          .modal-offer-choose .modal-content .chooser .list-horizontal ul li > span img {
            max-width: 100%;
            width: auto; }
  .modal-offer-choose .modal-content .chooser .current-offer-fullname {
    width: 100%;
    margin-top: 2px;
    background-color: #fff;
    text-align: center;
    padding: 14px 0; }
  .modal-offer-choose .modal-content .prices {
    justify-content: center;
    align-items: baseline;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 13px; }
    .modal-offer-choose .modal-content .prices .price-old {
      color: #f16e26;
      font-size: 17px;
      text-decoration: line-through;
      opacity: 0.3;
      margin-right: 8px; }
    .modal-offer-choose .modal-content .prices .price-current {
      font-size: 23px;
      color: #333333;
      margin-right: 8px; }
    .modal-offer-choose .modal-content .prices .discount {
      font-size: 13px;
      color: #767676; }
  .modal-offer-choose .modal-content .unavailable-text {
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
    color: #333333; }

.vue-scrollbar__scrollbar-vertical {
  width: 5px;
  right: 5px;
  opacity: 1;
  background: none; }
  .vue-scrollbar__scrollbar-vertical:hover {
    background: none; }
  .vue-scrollbar__scrollbar-vertical .scrollbar {
    width: 5px;
    background: #e4e4e4;
    border-radius: 25px; }
    .vue-scrollbar__scrollbar-vertical .scrollbar:hover {
      cursor: pointer; }

.vb > .vb-dragger {
  z-index: 5;
  width: 5px;
  right: 5px; }

.vb > .vb-dragger > .vb-dragger-styler {
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  background-color: #e5e5e5;
  border-radius: 25px;
  height: 100%;
  display: block; }

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: #d0d0d0; }

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: #d0d0d0;
  height: 100%; }

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: #d0d0d0;
  height: 100%; }

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: #d0d0d0; }

.tooltip {
  background-color: #fff;
  box-sizing: border-box;
  width: 120px;
  padding: 6px 10px;
  z-index: 100;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e3e3e3; }
  .tooltip .tooltip-inner {
    text-align: center;
    color: #767676;
    text-align: center;
    font-size: 12px; }
  .tooltip .tooltip-arrow {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px; }
  .tooltip[x-placement^="top"] {
    margin-bottom: 5px; }
    .tooltip[x-placement^="top"] .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-color: #fff transparent transparent transparent;
      bottom: -5px;
      left: calc(50% - 10px);
      margin-top: 0;
      margin-bottom: 0; }
  .tooltip[x-placement^="bottom"] {
    margin-top: 5px; }
    .tooltip[x-placement^="bottom"] .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #fff transparent;
      top: -5px;
      left: calc(50% - 10px);
      margin-top: 0;
      margin-bottom: 0; }
  .tooltip[x-placement^="right"] {
    margin-left: 5px; }
    .tooltip[x-placement^="right"] .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent #fff transparent transparent;
      left: -5px;
      top: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0; }
  .tooltip[x-placement^="left"] {
    margin-right: 5px; }
    .tooltip[x-placement^="left"] .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent #fff;
      right: -5px;
      top: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0; }

.menu-blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7); }

.blured main,
.blured .top-menu,
.blured .middle-menu {
  filter: blur(1px); }

nav.main-menu > ul {
  z-index: 8;
  display: flex;
  justify-content: space-between;
  position: relative; }
  nav.main-menu > ul > li {
    flex-grow: 1;
    height: 48px;
    line-height: 48px; }
    nav.main-menu > ul > li:nth-child(1) {
      background-color: #1788d2; }
    nav.main-menu > ul > li:nth-child(2) {
      background-color: #734ea5; }
    nav.main-menu > ul > li:nth-child(3) {
      background-color: #822f89; }
    nav.main-menu > ul > li:nth-child(4) {
      background-color: #ac4a6b; }
    nav.main-menu > ul > li:nth-child(5) {
      background-color: #c75756; }
    nav.main-menu > ul > li:nth-child(6) {
      background-color: #dc6948; }
    nav.main-menu > ul > li:nth-child(7) {
      background-color: #f16e26; }
    nav.main-menu > ul > li:nth-child(8) {
      background-color: #f18e58; }
    nav.main-menu > ul > li:nth-child(9) {
      background-color: #fe584c; }
    nav.main-menu > ul > li.has-children > a:after {
      display: block;
      content: "";
      position: absolute;
      top: 35px;
      left: 50%;
      background: url(/build/images/menu_arrows_sprite.png) no-repeat -4px -5px;
      width: 5px;
      height: 3px; }
    nav.main-menu > ul > li:hover > div {
      display: block; }
    nav.main-menu > ul > li > a {
      position: relative;
      display: block;
      text-align: center;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      white-space: nowrap; }
      nav.main-menu > ul > li > a > span.label-sale {
        display: block;
        position: absolute;
        top: -7px;
        left: 50%;
        margin-left: -17px;
        color: #fff;
        width: 33px;
        height: 17px;
        background: #e64f88;
        font-size: 12px;
        line-height: 16px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.16); }
      nav.main-menu > ul > li > a .icon-label-markdown-mini {
        position: absolute;
        top: -9px;
        left: calc(50% - 10px); }
    nav.main-menu > ul > li > div {
      position: absolute;
      left: 0;
      display: none;
      width: 100%;
      background: #fff;
      border: 1px solid #d0d0d1;
      border-top: none;
      z-index: 30;
      padding-top: 8px; }
    nav.main-menu > ul > li > .brands {
      height: 260px;
      padding-top: 6px; }
      nav.main-menu > ul > li > .brands > ul {
        position: relative;
        display: flex; }
        nav.main-menu > ul > li > .brands > ul > li {
          margin: 5px 3px; }
          nav.main-menu > ul > li > .brands > ul > li:not(.empty-letter):hover > ul {
            display: flex;
            padding-top: 6px; }
            nav.main-menu > ul > li > .brands > ul > li:not(.empty-letter):hover > ul:before {
              display: block;
              content: "";
              margin-top: 5px;
              border-top: 1px solid #d0d0d1;
              position: absolute;
              width: 1149px;
              left: 9px; }
          nav.main-menu > ul > li > .brands > ul > li:not(.empty-letter):hover > a {
            color: #fff;
            background: #4998cc;
            border-radius: 50%;
            cursor: pointer; }
          nav.main-menu > ul > li > .brands > ul > li > a {
            display: block;
            text-align: center;
            width: 35px;
            height: 35px;
            line-height: 35px;
            color: #474747;
            font-size: 14px;
            text-decoration: none; }
          nav.main-menu > ul > li > .brands > ul > li.empty-letter > a {
            color: #a5a5a5;
            cursor: default; }
          nav.main-menu > ul > li > .brands > ul > li > ul {
            position: absolute;
            left: 0;
            display: none;
            width: 100%; }
            nav.main-menu > ul > li > .brands > ul > li > ul > li {
              text-align: center;
              padding: 20px 14px 14px; }
              nav.main-menu > ul > li > .brands > ul > li > ul > li a {
                display: block;
                color: #0078c1;
                line-height: 22px;
                text-decoration: none;
                width: 95px; }
                nav.main-menu > ul > li > .brands > ul > li > ul > li a:hover {
                  text-decoration: underline; }
              nav.main-menu > ul > li > .brands > ul > li > ul > li .brand-logo {
                display: block;
                width: 92px;
                height: 60px;
                margin-bottom: 5px;
                background: no-repeat center;
                background-size: contain; }
      nav.main-menu > ul > li > .brands .brand-items-wrapper:before {
        display: block;
        content: "";
        margin-top: 5px;
        border-top: 1px solid #d0d0d1;
        position: absolute;
        width: 1149px;
        left: 9px; }
      nav.main-menu > ul > li > .brands .brand-items-wrapper .brand-items {
        display: flex;
        justify-content: center; }
        nav.main-menu > ul > li > .brands .brand-items-wrapper .brand-items > div {
          text-align: center;
          padding: 20px 14px 14px; }
          nav.main-menu > ul > li > .brands .brand-items-wrapper .brand-items > div a {
            display: block;
            color: #0078c1;
            line-height: 22px;
            text-decoration: none; }
            nav.main-menu > ul > li > .brands .brand-items-wrapper .brand-items > div a:hover {
              text-decoration: underline; }
          nav.main-menu > ul > li > .brands .brand-items-wrapper .brand-items > div .brand-logo {
            display: block;
            width: 92px;
            height: 60px;
            margin-bottom: 5px;
            background: no-repeat center;
            background-size: contain; }
      nav.main-menu > ul > li > .brands .brand-search {
        position: absolute;
        bottom: 10px;
        right: 10px; }
        nav.main-menu > ul > li > .brands .brand-search .btn.brand-all-btn {
          width: 129px;
          color: #fff;
          font-size: 14px;
          text-align: center;
          margin-right: 10px; }
        nav.main-menu > ul > li > .brands .brand-search .search-form {
          margin-right: 16px; }
          nav.main-menu > ul > li > .brands .brand-search .search-form .form {
            position: relative; }
            nav.main-menu > ul > li > .brands .brand-search .search-form .form input[type="text"] {
              width: 490px;
              height: 42px;
              border: 1px solid #c0c0c0;
              padding: 0 30px 0 15px;
              box-shadow: none;
              background: #fff; }
            nav.main-menu > ul > li > .brands .brand-search .search-form .form input[type="submit"] {
              position: absolute;
              top: 17px;
              right: 10px;
              background: url("/build/images/icon_search_mini.png") no-repeat 0 -1px;
              width: 17px;
              height: 17px; }
            nav.main-menu > ul > li > .brands .brand-search .search-form .form input[type="text"]:focus + input[type="submit"] {
              background-position: 0 -21px; }
          nav.main-menu > ul > li > .brands .brand-search .search-form .placeholder a {
            font-size: 14px;
            color: #474747;
            border-bottom: 1px dotted #474747;
            padding-bottom: 2px;
            margin-right: 14px;
            text-transform: none;
            text-decoration: none; }
    nav.main-menu > ul > li > .children > ul {
      position: relative; }
      nav.main-menu > ul > li > .children > ul > li {
        max-width: 250px;
        line-height: normal; }
        nav.main-menu > ul > li > .children > ul > li.has-children > a:after {
          display: block;
          content: "";
          position: absolute;
          top: 19px;
          right: 4px;
          background: url(/build/images/menu_arrows_sprite.png) no-repeat -5px -23px;
          width: 3px;
          height: 5px; }
        nav.main-menu > ul > li > .children > ul > li:hover {
          background: #0078c1; }
          nav.main-menu > ul > li > .children > ul > li:hover .grid {
            display: flex;
            justify-content: flex-start; }
          nav.main-menu > ul > li > .children > ul > li:hover > a {
            border-bottom: 1px dotted transparent;
            color: #fff; }
            nav.main-menu > ul > li > .children > ul > li:hover > a:after {
              background-position: -5px -13px; }
        nav.main-menu > ul > li > .children > ul > li > a {
          position: relative;
          display: block;
          padding: 11px 0;
          border-bottom: 1px dotted #b9b8b8;
          color: #0078c1;
          margin: 0 10px 0 14px;
          font-size: 15px;
          text-decoration: none; }
        nav.main-menu > ul > li > .children > ul > li .grid {
          position: absolute;
          display: none;
          left: 250px;
          top: 0;
          padding: 21px 24px;
          height: 100%;
          width: calc(100% - 250px); }
          nav.main-menu > ul > li > .children > ul > li .grid .row.column-container {
            columns: 2;
            column-fill: auto;
            height: 100%; }
          nav.main-menu > ul > li > .children > ul > li .grid .row .group {
            margin-bottom: 10px; }
            nav.main-menu > ul > li > .children > ul > li .grid .row .group p {
              color: #4272e0;
              font-weight: bold; }
          nav.main-menu > ul > li > .children > ul > li .grid .row ul > li {
            line-height: 28px;
            width: 265px; }
            nav.main-menu > ul > li > .children > ul > li .grid .row ul > li > a {
              color: #474747;
              font-size: 14px;
              text-decoration: none; }
              nav.main-menu > ul > li > .children > ul > li .grid .row ul > li > a:hover {
                text-decoration: underline; }
          nav.main-menu > ul > li > .children > ul > li .grid .row ul.important > li > p {
            color: #fe584c;
            margin-bottom: 0;
            font-size: 12px; }
          nav.main-menu > ul > li > .children > ul > li .grid .row ul.important > li > a {
            display: inline-block;
            font-size: 16px;
            color: #0078c1;
            padding: 2px 16px;
            border: 1px solid #0078c1;
            text-decoration: none;
            margin-bottom: 20px; }

.swipe-wrapper.is-active .swipe-content {
  transform: translateX(0px) !important; }

.swipe-wrapper .swipe-blocker {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10; }

.swipe-wrapper .swipe-content {
  position: fixed;
  top: 0;
  height: 100%;
  width: 500px;
  transition: 0.3s all ease;
  transform: translateX(-500px);
  z-index: 12; }

.pagination ul {
  display: flex;
  justify-content: center; }
  .pagination ul li {
    display: block;
    width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center; }
    .pagination ul li a {
      display: block;
      color: #626161;
      text-decoration: none;
      outline: none; }
      .pagination ul li a:hover {
        text-decoration: none; }
    .pagination ul li a,
    .pagination ul li span {
      font-size: 15px; }
    .pagination ul li.is-active {
      background-color: #d7d7d7;
      border-radius: 100%; }
      .pagination ul li.is-active a,
      .pagination ul li.is-active span {
        color: #fff; }

.cart-informer-container {
  position: relative;
  height: 100%; }
  .cart-informer-container .cart-informer {
    padding: 0 10px;
    height: 100%; }
    .cart-informer-container .cart-informer.is-active:hover {
      cursor: pointer; }
    .cart-informer-container .cart-informer .count {
      position: relative;
      width: 25px;
      height: 28px;
      margin-right: 10px; }
      .cart-informer-container .cart-informer .count i.icon {
        position: absolute; }
      .cart-informer-container .cart-informer .count span {
        position: absolute;
        color: #000;
        top: 7px;
        width: 100%;
        /* height: 100%; */
        line-height: normal;
        text-align: center; }

.modal-cart .modal-content {
  padding: 19px 0 0; }

.modal-cart .error-message {
  margin: 5px 19px;
  padding: 15px;
  background: #da4040;
  color: #fff; }

.modal-cart .empty-cart {
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .modal-cart .empty-cart .loader-wrapper {
    position: absolute; }
  .modal-cart .empty-cart p {
    margin-top: 20px;
    font-size: 15px;
    color: #767676; }

.modal-cart .cart-informer-content {
  padding: 0 19px; }
  .modal-cart .cart-informer-content .loader-wrapper.is-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4); }
  .modal-cart .cart-informer-content .cart-informer-block {
    position: relative;
    background: #fff;
    padding: 15px; }
    .modal-cart .cart-informer-content .cart-informer-block:not(:last-child) {
      border-bottom: 1px solid #f3f3f5; }
    .modal-cart .cart-informer-content .cart-informer-block .grid {
      align-items: center; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .image a {
        width: 120px;
        height: 120px;
        display: flex; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .image img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .name-container {
        padding: 10px;
        flex-grow: 1; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .name {
          margin-bottom: 12px; }
          .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .name a {
            color: #333333;
            text-decoration: none;
            outline: none; }
            .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .name a:hover {
              text-decoration: none; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .name2 {
          color: #767676; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .properties {
          border-top: 1px solid #f3f3f5;
          margin-top: 9px;
          padding-top: 5px; }
          .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .properties li span.name {
            color: #767676; }
          .modal-cart .cart-informer-content .cart-informer-block .grid .name-container .properties li span.value {
            color: #4c4c4c; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container {
        width: 150px;
        flex-shrink: 0; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div {
          display: flex;
          justify-content: center; }
          .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div a {
            color: #626161;
            text-decoration: none;
            outline: none; }
            .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div a:hover {
              text-decoration: none; }
            .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div a.is-disabled {
              cursor: not-allowed; }
          .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div a,
          .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div .quantity {
            display: block;
            width: 38px;
            height: 38px;
            line-height: 38px;
            text-align: center; }
          .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container > div .quantity {
            background: #f3f3f5; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container .help-block-error {
          color: #ff0000;
          text-align: center; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .price-container {
        width: 115px;
        font-size: 14px;
        text-align: center;
        flex-shrink: 0; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .price-container.has-discount .price {
          display: inline-block;
          border: 1px solid #e9bac4;
          color: #d98596;
          padding: 7px 11px; }
        .modal-cart .cart-informer-content .cart-informer-block .grid .price-container.has-discount .old-price {
          text-decoration: line-through;
          color: #adadad;
          padding: 7px 11px; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .need-wait-block {
        color: red; }
    .modal-cart .cart-informer-content .cart-informer-block .btn-remove {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 21px;
      height: 21px;
      display: flex; }
      .modal-cart .cart-informer-content .cart-informer-block .btn-remove > i {
        margin: auto; }
      .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm {
        right: 0;
        background: #fff;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15); }
        .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm a {
          color: #767676;
          text-decoration: none;
          outline: none;
          width: 137px;
          height: 115px;
          display: block;
          text-align: center; }
          .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm a:hover {
            text-decoration: none; }
          .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm a:not(:last-child) {
            border-right: 1px solid #f3f3f5; }
          .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm a span {
            display: block; }
            .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm a span.text {
              padding-top: 10px; }
          .modal-cart .cart-informer-content .cart-informer-block .btn-remove .popup-cart-confirm a > span {
            padding: 5px;
            margin-top: 21px;
            display: flex;
            flex-direction: column; }
  .modal-cart .cart-informer-content .total-container {
    padding: 30px 20px;
    font-size: 13px; }
    .modal-cart .cart-informer-content .total-container .grid .row {
      width: 280px; }
      .modal-cart .cart-informer-content .total-container .grid .row.stats {
        display: flex;
        flex-direction: column;
        margin-top: auto; }
    .modal-cart .cart-informer-content .total-container table {
      width: 100%;
      margin-bottom: 10px; }
      .modal-cart .cart-informer-content .total-container table tr.economy {
        color: #e89191; }
      .modal-cart .cart-informer-content .total-container table tr td {
        padding: 10px 0; }
        .modal-cart .cart-informer-content .total-container table tr td:last-child {
          text-align: right; }
    .modal-cart .cart-informer-content .total-container .coupon-container {
      height: 60px;
      text-align: center; }
      .modal-cart .cart-informer-content .total-container .coupon-container > a {
        color: #767676;
        text-decoration: none;
        outline: none;
        text-decoration: underline; }
        .modal-cart .cart-informer-content .total-container .coupon-container > a:hover {
          text-decoration: none; }
      .modal-cart .cart-informer-content .total-container .coupon-container .coupon-form .form-control {
        background: #fff;
        width: 100%; }
      .modal-cart .cart-informer-content .total-container .coupon-container .coupon-form a {
        display: block;
        color: #4998cc;
        text-decoration: none;
        outline: none; }
        .modal-cart .cart-informer-content .total-container .coupon-container .coupon-form a:hover {
          text-decoration: none; }
      .modal-cart .cart-informer-content .total-container .coupon-container .coupon-form .discount-success span {
        color: #8bc870; }
      .modal-cart .cart-informer-content .total-container .coupon-container .coupon-form .discount-error {
        color: #ff0000; }
  .modal-cart .cart-informer-content .progress-bar-container {
    margin: 12px 0;
    padding: 0 9px;
    text-align: center;
    font-size: 13px; }
    .modal-cart .cart-informer-content .progress-bar-container .hint {
      padding: 5px 0; }
      .modal-cart .cart-informer-content .progress-bar-container .hint span {
        color: #333333; }
    .modal-cart .cart-informer-content .progress-bar-container .progress-bar {
      width: 262px;
      height: 8px;
      background-color: #d3d3d3; }
      .modal-cart .cart-informer-content .progress-bar-container .progress-bar .indicator {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8bc870+0,76bf56+100 */
        background: #8bc870;
        /* Old browsers */
        background: -moz-linear-gradient(top, #8bc870 0%, #76bf56 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #8bc870 0%, #76bf56 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #8bc870 0%, #76bf56 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc870', endColorstr='#76bf56',GradientType=0 );
        /* IE6-9 */ }

.modal-cart .products-viewed {
  background: #fff;
  height: 100%;
  line-height: normal;
  padding: 0 15px; }
  .modal-cart .products-viewed h3 {
    font-size: 14px;
    color: #767676;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 23px; }
  .modal-cart .products-viewed .cart-product-viewed {
    padding: 10px 0; }
    .modal-cart .products-viewed .cart-product-viewed:not(:last-child) {
      border-bottom: 1px solid #f3f3f5; }
    .modal-cart .products-viewed .cart-product-viewed .image {
      text-align: center; }
      .modal-cart .products-viewed .cart-product-viewed .image a {
        width: 120px;
        height: 120px;
        display: flex; }
        .modal-cart .products-viewed .cart-product-viewed .image a img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto; }
    .modal-cart .products-viewed .cart-product-viewed .names {
      flex-grow: 1;
      padding-left: 10px; }
      .modal-cart .products-viewed .cart-product-viewed .names .primary-name {
        padding-bottom: 10px; }
        .modal-cart .products-viewed .cart-product-viewed .names .primary-name a {
          color: #626161;
          text-decoration: none;
          outline: none;
          font-size: 13px; }
          .modal-cart .products-viewed .cart-product-viewed .names .primary-name a:hover {
            text-decoration: none; }
      .modal-cart .products-viewed .cart-product-viewed .names .secondary-name {
        color: #767676;
        font-size: 13px; }
    .modal-cart .products-viewed .cart-product-viewed .actions {
      padding: 0 37px; }
      .modal-cart .products-viewed .cart-product-viewed .actions > div {
        width: 193px; }
      .modal-cart .products-viewed .cart-product-viewed .actions .price-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 33px;
        font-size: 14px; }
        .modal-cart .products-viewed .cart-product-viewed .actions .price-container .label {
          margin-right: 5px; }
        .modal-cart .products-viewed .cart-product-viewed .actions .price-container .price-current {
          color: #333333; }
        .modal-cart .products-viewed .cart-product-viewed .actions .price-container.with-discount .price-current {
          border: 1px solid #e9bac4;
          color: #d98596;
          padding: 5px 11px;
          border-radius: 2px; }
        .modal-cart .products-viewed .cart-product-viewed .actions .price-container.with-discount .price-old {
          color: #d0d0d0;
          text-decoration: line-through;
          margin-left: 9px; }
      .modal-cart .products-viewed .cart-product-viewed .actions .unavailable-text {
        color: #333333;
        text-align: center;
        min-height: 32px;
        line-height: 32px; }
      .modal-cart .products-viewed .cart-product-viewed .actions .btn {
        width: 100%;
        margin-top: 6px; }

.modal-cart .offers-chooser {
  margin-top: 6px; }
  .modal-cart .offers-chooser .simple__list-item {
    font-size: 13px; }
  .modal-cart .offers-chooser .extended {
    width: 100%; }

.modal-average .modal-content {
  width: 1160px;
  background: #fff;
  padding: 0; }
  .modal-average .modal-content .modal-title {
    padding: 28px 16px; }

.modal-average .average {
  padding: 0 25px; }

.modal-average .controls {
  padding: 25px;
  display: flex;
  justify-content: space-around; }
  .modal-average .controls__button {
    width: 35%; }

.breadcrumbs {
  margin-top: 10px; }
  .breadcrumbs ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .breadcrumbs ul li {
      border: 1px solid #ececec; }
      .breadcrumbs ul li:not(:last-child) {
        border-right: none; }
      .breadcrumbs ul li a,
      .breadcrumbs ul li > span {
        display: flex;
        align-items: center;
        height: 40px; }
      .breadcrumbs ul li a {
        color: #767676;
        text-decoration: none;
        outline: none;
        padding: 0 44px 0 22px;
        background: #fff url("/build/images/breadcrumb-bg.png") 90% 50% no-repeat; }
        .breadcrumbs ul li a:hover {
          text-decoration: none; }
      .breadcrumbs ul li > span {
        padding: 0 20px; }
      .breadcrumbs ul li.home, .breadcrumbs ul li.more {
        background: #fff; }
        .breadcrumbs ul li.home a, .breadcrumbs ul li.more a {
          padding: 0 20px;
          background: #fff; }

.zoomer {
  position: relative;
  border: 0;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  transition: all .5s ease-out; }
  .zoomer:hover {
    cursor: move; }
    .zoomer:hover .normal {
      opacity: 0;
      transform: scale(1.1); }
    .zoomer:hover .plus {
      opacity: 0;
      transform: scale(0.8); }
  .zoomer .img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: white no-repeat center center;
    transition: transform ease-out .3s; }
  .zoomer .normal {
    z-index: 5; }
    .zoomer .normal[lazy=loaded] {
      background-size: contain; }
  .zoomer img.normal {
    opacity: 0;
    width: 100%; }
  .zoomer .zoom {
    z-index: 4;
    transition: none; }
  .zoomer .plus {
    position: absolute;
    transition: all ease-out .4s;
    z-index: 6;
    right: 0;
    bottom: 60px; }

.product-informer {
  margin: 0 20px;
  justify-content: flex-start; }
  .product-informer .image a {
    display: flex;
    width: 110px;
    height: 110px; }
  .product-informer .image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: auto; }
  .product-informer .description {
    padding: 0 15px; }
    .product-informer .description p.title a {
      color: #626161;
      text-decoration: none;
      outline: none; }
      .product-informer .description p.title a:hover {
        text-decoration: none; }
      .product-informer .description p.title a span {
        font-size: 13px; }
        .product-informer .description p.title a span.name {
          display: block;
          font-weight: bold; }
    .product-informer .description > .grid {
      margin-top: 18px;
      align-items: center; }
      .product-informer .description > .grid .price {
        font-size: 15px; }
        .product-informer .description > .grid .price span {
          font-weight: bold; }
      .product-informer .description > .grid .offer-value {
        font-size: 13px;
        color: #767676; }

.stars-rating {
  display: flex; }
  .stars-rating:not(.read-only) li:hover {
    cursor: pointer; }
  .stars-rating li {
    display: block;
    background: url("/build/images/stars_sprite.png") no-repeat 0 0;
    width: 14px;
    height: 14px; }
    .stars-rating li.is-active {
      background-position: -15px 0; }

.rating-widget {
  display: flex; }
  .rating-widget a {
    display: inline-block;
    margin-left: 10px;
    color: #6ca2c5;
    text-decoration: none;
    outline: none; }
    .rating-widget a:hover {
      text-decoration: none; }

.progress-bar {
  display: inline-block; }
  .progress-bar .indicator {
    height: 100%;
    transition: 0.3s all ease; }

.review-item {
  margin: 20px 0; }
  .review-item .review-item {
    margin-left: 50px; }
  .review-item__header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .review-item__name {
    color: #333; }
  .review-item__rating {
    padding-left: 12px; }
  .review-item__recommendations {
    flex-grow: 1; }
    .review-item__recommendations .icon-wrapper i.icon {
      margin-right: 5px; }
  .review-item__recommendations-content {
    display: flex;
    justify-content: center;
    align-items: center; }
  .review-item__recommendations-row {
    padding: 0 15px; }
    .review-item__recommendations-row--first .icon-wrapper span.up {
      color: #8bc870; }
    .review-item__recommendations-row--first .icon-wrapper span.down {
      color: #e89191; }
    .review-item__recommendations-row--last .icon-wrapper span {
      color: #8bc870; }
  .review-item__date {
    color: #a4a4a4; }
  .review-item__content {
    color: #767676; }
    .review-item__content a {
      color: #4998cc;
      text-decoration: none;
      outline: none;
      display: inline-block;
      margin: 5px 0; }
      .review-item__content a:hover {
        text-decoration: none; }
  .review-item__parameters {
    margin-top: 12px; }
    .review-item__parameters span.label {
      color: #333;
      padding-right: 5px; }
  .review-item__advantages {
    margin-top: 12px; }
    .review-item__advantages span.label {
      color: #333;
      padding-right: 5px; }
  .review-item__limitations {
    margin-top: 12px; }
    .review-item__limitations span.label {
      color: #333;
      padding-right: 5px; }
  .review-item__files {
    margin-top: 12px; }
    .review-item__files span.label {
      color: #333;
      padding-right: 5px; }
  .review-item__parameters {
    display: flex;
    margin: 0 0 12px; }
  .review-item__parameters-row {
    padding-right: 35px; }
  .review-item__files > div {
    margin-bottom: 20px; }
  .review-item__files-list {
    display: flex;
    align-items: center; }
    .review-item__files-list > a {
      margin: 5px; }
  .review-item__footer {
    margin-top: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px; }
  .review-item__answer-button {
    color: #4998cc;
    text-decoration: none;
    outline: none; }
    .review-item__answer-button:hover {
      text-decoration: none; }
  .review-item__recommendation-label {
    flex-grow: 1;
    text-align: right;
    padding-right: 30px; }
  .review-item__stats {
    display: flex; }
  .review-item__stats-row {
    padding: 0 5px; }
  .review-item__stats-action {
    color: #626161;
    text-decoration: none;
    outline: none; }
    .review-item__stats-action:hover {
      text-decoration: none; }
    .review-item__stats-action.is-disabled {
      cursor: default; }
    .review-item__stats-action span {
      padding: 0 5px; }

.review-last-item {
  padding: 0 5px; }
  .review-last-item__image-link {
    display: flex;
    width: 100%;
    height: auto; }
    .review-last-item__image-link img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  .review-last-item__rating {
    margin-bottom: 5px; }
  .review-last-item__link {
    color: #626161;
    text-decoration: none;
    outline: none;
    font-weight: bold; }
    .review-last-item__link:hover {
      text-decoration: none; }
  .review-last-item__content {
    margin: 8px 0;
    color: #767676; }

.custom-select {
  position: relative; }
  .custom-select:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    background: url(/build/images/menu_arrows_sprite.png) no-repeat -4px -33px;
    width: 5px;
    height: 3px;
    transition: 0.3s all ease; }
  .custom-select.is-active:after {
    transform: rotate(180deg); }
  .custom-select.is-disabled .current-value {
    cursor: not-allowed; }
  .custom-select .current-value {
    cursor: pointer;
    height: 100%;
    overflow: hidden; }
  .custom-select .dropdown {
    position: absolute;
    left: 0;
    z-index: 5;
    width: 100%; }
    .custom-select .dropdown .vb-content {
      width: calc(100% + 20px) !important;
      max-height: 300px; }
    .custom-select .dropdown ul {
      list-style: none; }
      .custom-select .dropdown ul li {
        cursor: pointer; }

.custom-select-enter-active {
  animation: customFadeInUp 500ms; }

.custom-select-leave-active {
  animation: customFadeOut 300ms; }

@keyframes customFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes customFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.answer-form {
  margin-top: 30px;
  background: #ececec;
  padding: 16px 21px; }
  .answer-form .grid .row {
    flex-basis: 48%; }
    .answer-form .grid .row button {
      margin-top: 10px;
      font-size: 13px;
      line-height: 13px; }
  .answer-form .form-group label {
    margin: 5px 0; }
  .answer-form .form-group .form-control {
    background: #fff;
    padding: 0 20px; }
    .answer-form .form-group .form-control.custom-select {
      padding: initial; }
      .answer-form .form-group .form-control.custom-select .current-value {
        padding: 0 20px; }
  .answer-form .form-group textarea.form-control {
    padding: 20px; }
  .answer-form .form-group p.help-block-error a {
    color: #4998cc;
    text-decoration: none;
    outline: none; }
    .answer-form .form-group p.help-block-error a:hover {
      text-decoration: none; }
  .answer-form .custom-select .dropdown {
    background-color: #fff;
    border: 1px solid #e9e9e9; }
    .answer-form .custom-select .dropdown .form-group {
      padding: 7px; }
      .answer-form .custom-select .dropdown .form-group .form-control {
        border: 2px solid #ececec; }
    .answer-form .custom-select .dropdown ul {
      background: none; }
      .answer-form .custom-select .dropdown ul li {
        padding: 5px 20px;
        color: #767676; }
        .answer-form .custom-select .dropdown ul li:hover {
          background-color: #dfdfdf; }
        .answer-form .custom-select .dropdown ul li.is-active {
          background-color: #daeaf5; }
    .answer-form .custom-select .dropdown .empty-result {
      padding: 5px 20px; }

.answer-item-container {
  margin-top: 20px;
  padding-left: 30px; }
  .answer-item-container .answer-icon {
    padding-right: 20px; }
  .answer-item-container .answer-item {
    flex-grow: 1;
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec; }
    .answer-item-container .answer-item > header {
      justify-content: space-between; }
      .answer-item-container .answer-item > header .name {
        color: #767676; }
        .answer-item-container .answer-item > header .name span {
          color: #333; }
        .answer-item-container .answer-item > header .name.is-admin span {
          color: #1b88d3;
          font-weight: bold;
          font-size: 16px; }
      .answer-item-container .answer-item > header .date {
        color: #a4a4a4; }
    .answer-item-container .answer-item > article {
      color: #767676;
      margin: 20px 0; }
      .answer-item-container .answer-item > article .signature {
        font-style: italic;
        margin-top: 15px; }
    .answer-item-container .answer-item > footer a {
      color: #4998cc;
      text-decoration: none;
      outline: none; }
      .answer-item-container .answer-item > footer a:hover {
        text-decoration: none; }
  .answer-item-container .children {
    padding-left: 50px; }
    .answer-item-container .children .show-more-child {
      padding: 20px 0; }
      .answer-item-container .children .show-more-child a {
        justify-content: center;
        color: #626161;
        text-decoration: none;
        outline: none; }
        .answer-item-container .children .show-more-child a:hover {
          text-decoration: none; }
        .answer-item-container .children .show-more-child a i.icon {
          margin-left: 10px; }

.vue-form-wizard .wizard-navigation .wizard-nav {
  display: flex; }
  .vue-form-wizard .wizard-navigation .wizard-nav .wizard-icon-circle {
    display: none; }

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .custom-checkbox > .checkbox {
    display: flex;
    background: #e8e8e8;
    width: 18px;
    height: 18px; }
    .custom-checkbox > .checkbox > i.icon {
      display: block;
      margin: auto; }
  .custom-checkbox > span {
    padding-left: 10px; }

.fade-enter-active {
  transition: opacity .5s; }

.fade-leave-active {
  transition: opacity .3s; }

.fade-enter, .fade-leave-to {
  opacity: 0; }

.offer-item {
  background: #fff;
  padding: 12px; }
  .offer-item .image {
    padding-top: 30px;
    margin: 0 auto;
    display: flex;
    width: 250px;
    height: 250px; }
    .offer-item .image img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  .offer-item .rating-widget {
    margin: 5px auto; }
  .offer-item .code {
    font-size: 12px;
    width: 124px;
    margin: 0 auto;
    border: 2px dotted #ffaf8b;
    text-align: center;
    line-height: 17px;
    color: #767676; }
    .offer-item .code > span {
      color: #3c3c3c; }
  .offer-item .names {
    margin-top: 10px;
    text-align: center;
    height: 90px; }
    .offer-item .names .primary-name a {
      color: #333333;
      text-decoration: none;
      outline: none; }
      .offer-item .names .primary-name a:hover {
        text-decoration: none; }
    .offer-item .names .secondary-name {
      position: relative;
      margin-top: 10px;
      color: #767676;
      padding: 0 2px; }
  .offer-item .unavailable-text,
  .offer-item .price-container {
    margin-bottom: 20px; }
  .offer-item .price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 33px;
    font-size: 15px; }
    .offer-item .price-container .label {
      margin-right: 5px; }
    .offer-item .price-container .price-current {
      color: #333333; }
    .offer-item .price-container.with-discount .price-current {
      border: 1px solid #e9bac4;
      color: #d98596;
      padding: 7px 11px;
      border-radius: 2px; }
      .offer-item .price-container.with-discount .price-current > span {
        font-weight: 600; }
    .offer-item .price-container.with-discount .price-old {
      color: #d0d0d0;
      text-decoration: line-through;
      margin-left: 9px; }
  .offer-item .unavailable-text {
    color: #333333;
    font-size: 15px;
    text-align: center;
    min-height: 32px;
    line-height: 32px; }

.offers-chooser {
  flex-basis: 100%;
  margin-top: 14px;
  font-size: 13px;
  color: #767676; }
  .offers-chooser .simple__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none; }
  .offers-chooser .simple__list-item {
    padding: 9px 15px; }
    .offers-chooser .simple__list-item:hover {
      cursor: pointer; }
    .offers-chooser .simple__list-item.is-active {
      background: #eeeeee; }
    .offers-chooser .simple__list-item.is-disabled {
      color: #767676;
      opacity: 0.5; }
  .offers-chooser .extended {
    position: relative;
    margin: 0 auto;
    max-width: 193px;
    height: 32px;
    line-height: 32px;
    padding: 0 7px;
    border: 1px solid #eeeeee;
    overflow: hidden; }
    .offers-chooser .extended__overlay {
      position: absolute;
      top: 0;
      right: 38px;
      width: 70px;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
      /* IE6-9 */ }
    .offers-chooser .extended__btn-choose {
      position: absolute;
      top: 0;
      right: 0;
      width: 38px;
      height: 100%;
      line-height: 0;
      border-left: 1px solid #eeeeee;
      background: #fff; }
      .offers-chooser .extended__btn-choose:hover {
        background: #eeeeee; }
      .offers-chooser .extended__btn-choose.icon-wrapper {
        justify-content: center; }

.modal-available-request .modal-content {
  width: 500px; }

.show-more {
  width: 270px;
  min-height: 450px;
  height: 100%;
  text-align: center;
  display: flex; }
  .show-more .icon-wrapper {
    width: 64px;
    height: 64px;
    margin: 0 auto 8px auto;
    border-radius: 50%;
    justify-content: center;
    background-color: #6ca2c5; }
    .show-more .icon-wrapper.loading {
      animation: rotation 0.7s infinite ease; }
  .show-more > a {
    display: block;
    margin: auto;
    font-size: 16px;
    color: #6ca2c5;
    font-weight: 600;
    line-height: 26px;
    text-decoration: none; }

.instagram-posts {
  margin-bottom: 60px; }
  .instagram-posts__header {
    margin-bottom: 10px; }
    .instagram-posts__header .grid {
      align-items: center;
      flex-wrap: wrap; }
  .instagram-posts__slide {
    text-align: center; }
  .instagram-posts__follow-link {
    padding: 12px 30px; }
  .instagram-posts__post-link {
    display: flex;
    width: auto;
    height: 320px; }
    .instagram-posts__post-link img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  .instagram-posts .header {
    display: flex;
    align-items: center;
    background: #464646;
    padding: 8px 15px; }
    .instagram-posts .header__icon img {
      display: block;
      width: 50px;
      height: 50px; }
    .instagram-posts .header__text {
      padding: 0 15px;
      flex-grow: 1; }
    .instagram-posts .header__link {
      color: #fff;
      text-decoration: none;
      outline: none;
      font-size: 16px; }
      .instagram-posts .header__link:hover {
        text-decoration: none; }
  .instagram-posts .info {
    display: flex; }
    .instagram-posts .info__column {
      text-align: center;
      padding: 15px; }
    .instagram-posts .info__count {
      font-weight: bold; }
    .instagram-posts .info__label {
      font-size: 13px;
      color: #cecece; }

.search-list {
  display: flex; }
  .search-list__categories {
    width: 50%;
    flex-shrink: 0; }
  .search-list__products {
    flex-grow: 1; }
  .search-list__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px; }
  .search-list__category-item a {
    color: #727272;
    text-decoration: none;
    outline: none; }
    .search-list__category-item a:hover {
      text-decoration: none; }
  .search-list__brand-logo {
    max-width: 120px;
    padding: 8px 0; }
  .search-list__product-item {
    margin-bottom: 8px; }
    .search-list__product-item a {
      color: #626161;
      text-decoration: none;
      outline: none; }
      .search-list__product-item a:hover {
        text-decoration: none; }
    .search-list__product-item .grid {
      justify-content: flex-start; }
      .search-list__product-item .grid .row {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; }
  .search-list__product-image {
    display: flex;
    width: auto;
    height: auto;
    width: 50px;
    flex-shrink: 0; }
    .search-list__product-image img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  .search-list__product-name {
    color: #727272;
    width: 100%; }
  .search-list__product-name-alias {
    color: #b2b2b2;
    width: 100%; }

.popup-search-results {
  width: 800px;
  padding: 14px 26px;
  border: 1px solid #c0c0c0;
  border-top-color: #e5e5e5;
  margin-top: 2px;
  z-index: 9;
  background: #fff; }
  .popup-search-results .v-spinner {
    text-align: center; }
  .popup-search-results .vb-content {
    max-height: 420px; }
  .popup-search-results .search-result-item {
    text-decoration: none;
    min-height: 60px;
    padding-bottom: 10px; }
    .popup-search-results .search-result-item:not(:first-child) {
      margin-top: 10px; }
    .popup-search-results .search-result-item:not(:last-child) {
      border-bottom: 1px solid #cacaca; }
    .popup-search-results .search-result-item p {
      color: #727272; }
      .popup-search-results .search-result-item p.second-name {
        color: #b2b2b2;
        margin-top: 10px; }
    .popup-search-results .search-result-item.grid {
      justify-content: flex-start;
      align-items: center; }
      .popup-search-results .search-result-item.grid .row.image {
        flex-basis: 70px;
        text-align: center; }
      .popup-search-results .search-result-item.grid .row.content {
        flex-basis: calc(100% - 70px); }

.questions__button {
  padding: 12px 35px; }

.questions__sorry-message {
  text-align: center;
  margin-bottom: 18px; }

.questions__button-ask {
  text-align: center;
  margin-bottom: 20px; }

.modal-lottery .modal-content {
  overflow: hidden;
  width: 640px;
  height: 480px;
  background: #fff url("/build/images/gift-bg.png"); }
  .modal-lottery .modal-content p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px; }
  .modal-lottery .modal-content .result {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 35px; }
    .modal-lottery .modal-content .result p {
      font-size: 20px; }
    .modal-lottery .modal-content .result--success p {
      color: green; }

.roulette {
  position: absolute;
  left: -290px;
  top: 30px; }
  .roulette__pointer {
    top: 178px;
    position: absolute;
    right: 0; }

.lottery-form {
  margin-left: 280px;
  margin-top: 140px; }

.stock-single-list {
  margin: 15px 0; }

.stock-single-item {
  display: flex;
  align-items: center;
  border: 1px solid #97c9e4;
  padding: 19px 12px;
  text-decoration: none; }
  .stock-single-item--slim {
    padding: 9px 12px; }
  .stock-single-item--centered {
    justify-content: center; }
  .stock-single-item span {
    display: block; }
  .stock-single-item__icon {
    margin-right: 18px; }
  .stock-single-item__content {
    color: #4998cc; }
  .stock-single-item__name {
    margin-bottom: 10px;
    font-weight: bold; }

/* Страницы сайта */
.slider-content {
  margin-top: 12px; }
  .slider-content .row.pull-left {
    float: left; }
  .slider-content .row.pull-right {
    float: right; }
  .slider-content:after {
    content: '';
    display: block;
    clear: both; }
  .slider-content .slider-wrapper {
    position: relative;
    width: 100%; }
    .slider-content .slider-wrapper .VueCarousel-pagination {
      display: none;
      position: absolute;
      bottom: 12px; }
      .slider-content .slider-wrapper .VueCarousel-pagination .VueCarousel-dot .VueCarousel-dot-inner {
        border: 1px solid #007dc6; }
      .slider-content .slider-wrapper .VueCarousel-pagination .VueCarousel-dot--active .VueCarousel-dot-inner {
        border: 2px solid #007dc6; }
    .slider-content .slider-wrapper .thumbnails {
      position: absolute;
      bottom: 11px;
      width: 100%; }
      .slider-content .slider-wrapper .thumbnails ul {
        display: flex;
        justify-content: center; }
        .slider-content .slider-wrapper .thumbnails ul > li {
          padding: 0 10px; }
          .slider-content .slider-wrapper .thumbnails ul > li img {
            border: 4px solid transparent;
            opacity: 0.65;
            cursor: pointer; }
          .slider-content .slider-wrapper .thumbnails ul > li.active img {
            border-color: #0092d8;
            opacity: 1; }

.slider-content .VueCarousel {
  overflow: hidden; }
  .slider-content .VueCarousel:hover .VueCarousel-navigation-button {
    display: block; }
  .slider-content .VueCarousel .VueCarousel-navigation-button {
    display: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.4 35.4'%3E%3Cstyle%3E.a%7Bfill:%23010101%7D%3C/style%3E%3Cpath class='a' d='M27.1 17L9.9 0 8.4 1.4l16.3 16.3L8.4 34l1.6 1.4 17.1-17z'/%3E%3C/svg%3E") no-repeat 2px 16px;
    height: 76px;
    width: 45px;
    text-indent: -9999px;
    top: 50%;
    z-index: 5; }
    .slider-content .VueCarousel .VueCarousel-navigation-button:not(.VueCarousel-navigation--disabled):hover {
      background-color: rgba(255, 255, 255, 0.75); }
    .slider-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-prev {
      transform: rotate(180deg);
      margin-top: -40px; }
    .slider-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-next {
      right: 45px; }
    .slider-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation--disabled {
      opacity: 0.2; }

.main-tabs {
  margin-bottom: 32px; }
  .main-tabs .tabs {
    background-color: #ececec; }
    .main-tabs .tabs ul {
      padding: 7px 15px 0 15px;
      margin-top: 12px; }
      .main-tabs .tabs ul > li.is-active {
        background: #fff;
        box-shadow: 0px 2px 4px 0px white, 0px 0px 4px 0px rgba(35, 77, 156, 0.1); }
      .main-tabs .tabs ul > li a {
        padding: 12px 0 21px 0;
        font-size: 15px;
        color: #333333;
        text-decoration: none; }
  .main-tabs .tabs-content {
    padding: 16px 0; }
    .main-tabs .tabs-content .tab-name {
      background-color: #f3f3f5;
      text-align: center;
      padding: 26px 0;
      color: #555555;
      border-top: 1px solid #e6e6e8;
      margin-bottom: 18px;
      font-size: 20px; }
    .main-tabs .tabs-content .VueCarousel {
      margin: 0 15px; }
      .main-tabs .tabs-content .VueCarousel .VueCarousel-wrapper {
        margin: 0px 0 -150px 0;
        padding: 0px 0px 150px 0;
        z-index: 1; }
        .main-tabs .tabs-content .VueCarousel .VueCarousel-wrapper .VueCarousel-slide {
          padding-top: 10px; }
        .main-tabs .tabs-content .VueCarousel .VueCarousel-wrapper .VueCarousel-inner {
          max-height: 450px; }
      .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button {
        background: url("/build/images/vue-slider-arrows.png") no-repeat 0 0;
        width: 14px;
        height: 24px;
        text-indent: -9999px;
        top: 40%;
        z-index: 5; }
        .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-prev {
          background-position: -1px -1px; }
        .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-next {
          background-position: -20px -1px; }
        .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation--disabled {
          opacity: 0.2; }
      .main-tabs .tabs-content .VueCarousel .product-wrapper {
        margin: 0 auto; }
  .main-tabs .btn-info {
    height: 47px;
    line-height: 47px;
    font-size: 15px;
    color: #333333; }
  .main-tabs .tabs-content .show-more {
    width: 270px;
    margin: 0 auto;
    min-height: 450px;
    height: 100%;
    text-align: center;
    display: flex; }
    .main-tabs .tabs-content .show-more .icon-wrapper {
      width: 64px;
      height: 64px;
      margin: 0 auto 8px auto;
      border-radius: 50%;
      justify-content: center; }
    .main-tabs .tabs-content .show-more.new .icon-wrapper {
      background-color: #8dc973; }
    .main-tabs .tabs-content .show-more.top .icon-wrapper {
      background-color: #8bc3e8; }
    .main-tabs .tabs-content .show-more.stock .icon-wrapper {
      background-color: #8d4e98; }
    .main-tabs .tabs-content .show-more > a {
      display: block;
      margin: auto;
      font-size: 16px;
      color: #767676;
      font-weight: 600;
      line-height: 26px;
      text-decoration: none; }

@keyframes loading-categories {
  to {
    background-position: 150% 0, 5px 5px; } }

@keyframes loading-filters {
  to {
    background-position: -350% 0, 5px 5px, 5px 39px, 29px 43.75px, 5px 67px, 29px 71.75px, 5px 95px, 29px 99.75px; } }

.category-wrapper {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px; }
  .category-wrapper .category-description-wrapper h1 {
    text-align: center;
    font-size: 21px;
    color: #484848;
    margin-bottom: 20px; }
  .category-wrapper .category-description-wrapper .category-description {
    text-align: center; }
    .category-wrapper .category-description-wrapper .category-description .logo {
      padding-bottom: 26px; }
    .category-wrapper .category-description-wrapper .category-description .short-description:not(:empty) {
      border: 1px solid #ececec;
      padding: 15px; }
  .category-wrapper > aside {
    width: 300px; }
    .category-wrapper > aside .aside-content {
      padding: 5px;
      background: #ececec;
      margin-bottom: 15px; }
      .category-wrapper > aside .aside-content .vb-content {
        max-height: 430px; }
      .category-wrapper > aside .aside-content .vb-dragger {
        right: 0 !important; }
        .category-wrapper > aside .aside-content .vb-dragger .vb-dragger-styler {
          background-color: #d4d2d2; }
  .category-wrapper > section {
    width: calc(100% - 300px - 14px); }
    .category-wrapper > section .sort-panel {
      display: flex;
      align-items: center;
      margin: 20px 0;
      background: #ececec; }
      .category-wrapper > section .sort-panel > div {
        display: flex;
        align-items: center;
        padding: 11px 26px; }
      .category-wrapper > section .sort-panel a {
        color: #626161;
        text-decoration: none;
        outline: none; }
        .category-wrapper > section .sort-panel a:hover {
          text-decoration: none; }
        .category-wrapper > section .sort-panel a i.icon {
          margin-right: 10px; }
        .category-wrapper > section .sort-panel a.filter-btn {
          padding: 19px 50px;
          background-color: #0092d8;
          color: #fff; }
        .category-wrapper > section .sort-panel a.sort-btn {
          padding: 19px 50px; }
      .category-wrapper > section .sort-panel .panel-text {
        margin-right: 30px; }
      .category-wrapper > section .sort-panel .sort-item {
        display: flex;
        align-items: center; }
        .category-wrapper > section .sort-panel .sort-item .sort-item-name {
          padding: 8px 14px; }
        .category-wrapper > section .sort-panel .sort-item .grid {
          margin: 0 10px; }
        .category-wrapper > section .sort-panel .sort-item .sort-type {
          width: 20px;
          height: 20px;
          display: flex; }
          .category-wrapper > section .sort-panel .sort-item .sort-type:first-child {
            margin-right: 2px; }
          .category-wrapper > section .sort-panel .sort-item .sort-type:last-child {
            margin-left: 2px; }
          .category-wrapper > section .sort-panel .sort-item .sort-type a {
            display: block;
            background: url("/build/images/sort_arrows.png") no-repeat;
            width: 12px;
            height: 6px;
            margin: auto; }
            .category-wrapper > section .sort-panel .sort-item .sort-type a.asc {
              background-position: 0 -2px; }
            .category-wrapper > section .sort-panel .sort-item .sort-type a.desc {
              background-position: 0 -12px; }
          .category-wrapper > section .sort-panel .sort-item .sort-type.is-active {
            background-color: #fff; }
        .category-wrapper > section .sort-panel .sort-item.is-active:not(:last-child) {
          margin-right: 10px; }
        .category-wrapper > section .sort-panel .sort-item.is-active .sort-item-name {
          background: #fff; }
    .category-wrapper > section .filter-panel {
      display: flex;
      flex-wrap: wrap;
      padding: 0 25px;
      margin-bottom: 18px; }
      .category-wrapper > section .filter-panel .filter-item {
        color: #767676;
        text-decoration: none;
        outline: none;
        position: relative;
        padding: 7px 30px 7px 23px;
        background: #ececec;
        margin-right: 17px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .category-wrapper > section .filter-panel .filter-item:hover {
          text-decoration: none; }
        .category-wrapper > section .filter-panel .filter-item span.close {
          position: absolute;
          right: 10px;
          top: 7px; }
      .category-wrapper > section .filter-panel .show-full {
        display: block;
        text-align: center;
        width: 100%;
        color: #626161;
        text-decoration: none;
        outline: none;
        font-size: 17px;
        margin: 20px 0; }
        .category-wrapper > section .filter-panel .show-full:hover {
          text-decoration: none; }
    .category-wrapper > section .category-loader {
      text-align: center;
      margin-bottom: 30px; }
      .category-wrapper > section .category-loader::after {
        height: 0;
        width: 30%;
        content: ""; }
  .category-wrapper .products-container {
    display: flex;
    flex-wrap: wrap;
    position: relative; }
  .category-wrapper .pagination:not(:empty) {
    margin: 30px 0; }
  .category-wrapper .empty-products {
    text-align: center;
    margin: 20px 0; }
  .category-wrapper .category-banner {
    padding: 0 10px; }

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.filter-wrapper .child-categories ul li:not(:last-child) {
  border-bottom: 1px solid #ececec; }

.filter-wrapper .child-categories ul li a {
  display: block;
  position: relative;
  color: #626161;
  text-decoration: none;
  outline: none;
  padding: 14px 33px 14px 21px;
  background: #fff;
  font-size: 13px; }
  .filter-wrapper .child-categories ul li a:hover {
    text-decoration: none; }
  .filter-wrapper .child-categories ul li a:after {
    display: block;
    position: absolute;
    right: 23px;
    top: calc(50% - 3px);
    content: "";
    width: 4px;
    height: 7px;
    background: url("/build/images/filter_mini_arrow.png") no-repeat; }
  .filter-wrapper .child-categories ul li a.important {
    color: #0078c1; }

.filter-wrapper .filter-group,
.filter-wrapper .filter-price {
  padding: 15px; }
  .filter-wrapper .filter-group:not(:last-child),
  .filter-wrapper .filter-price:not(:last-child) {
    border-bottom: 2px solid #fff; }
  .filter-wrapper .filter-group .filter-title,
  .filter-wrapper .filter-price .filter-title {
    position: relative;
    padding: 10px 13px;
    color: #484848;
    cursor: pointer; }
    .filter-wrapper .filter-group .filter-title:after,
    .filter-wrapper .filter-price .filter-title:after {
      display: block;
      content: '';
      position: absolute;
      background: url("/build/images/filter_arrows.png") no-repeat -6px -14px;
      width: 8px;
      height: 15px;
      top: calc(50% - 7px);
      right: 3px; }
  .filter-wrapper .filter-group .filter-options ul li a,
  .filter-wrapper .filter-price .filter-options ul li a {
    display: flex;
    padding: 9px;
    margin-bottom: 1px;
    color: #626161;
    text-decoration: none;
    outline: none;
    cursor: pointer; }
    .filter-wrapper .filter-group .filter-options ul li a:hover,
    .filter-wrapper .filter-price .filter-options ul li a:hover {
      text-decoration: none; }
    .filter-wrapper .filter-group .filter-options ul li a span,
    .filter-wrapper .filter-price .filter-options ul li a span {
      display: block;
      line-height: normal; }
      .filter-wrapper .filter-group .filter-options ul li a span.checkbox,
      .filter-wrapper .filter-price .filter-options ul li a span.checkbox {
        width: 19px;
        height: 19px;
        background: #fff; }
      .filter-wrapper .filter-group .filter-options ul li a span.text,
      .filter-wrapper .filter-price .filter-options ul li a span.text {
        padding-left: 17px;
        flex-grow: 1;
        line-height: normal; }
    .filter-wrapper .filter-group .filter-options ul li a.is-active,
    .filter-wrapper .filter-price .filter-options ul li a.is-active {
      background: #fff; }
      .filter-wrapper .filter-group .filter-options ul li a.is-active span.checkbox,
      .filter-wrapper .filter-price .filter-options ul li a.is-active span.checkbox {
        background: #ececec url(/build/images/filter_checked.png) center center no-repeat; }
    .filter-wrapper .filter-group .filter-options ul li a.is-disabled,
    .filter-wrapper .filter-price .filter-options ul li a.is-disabled {
      cursor: default; }
      .filter-wrapper .filter-group .filter-options ul li a.is-disabled span.checkbox,
      .filter-wrapper .filter-price .filter-options ul li a.is-disabled span.checkbox {
        background: #f9f9f9; }
      .filter-wrapper .filter-group .filter-options ul li a.is-disabled span.text,
      .filter-wrapper .filter-price .filter-options ul li a.is-disabled span.text {
        color: #b4b4b4; }
  .filter-wrapper .filter-group .filter-options a.show-more-link,
  .filter-wrapper .filter-price .filter-options a.show-more-link {
    line-height: normal;
    color: #626161;
    text-decoration: none;
    outline: none; }
    .filter-wrapper .filter-group .filter-options a.show-more-link:hover,
    .filter-wrapper .filter-price .filter-options a.show-more-link:hover {
      text-decoration: none; }
  .filter-wrapper .filter-group.is-expand .filter-title:after,
  .filter-wrapper .filter-price.is-expand .filter-title:after {
    right: 0;
    top: calc(50% - 4px);
    background-position: -2px -4px;
    width: 15px;
    height: 8px; }
  .filter-wrapper .filter-group.is-expand .filter-options.is-scroll .vb-content,
  .filter-wrapper .filter-price.is-expand .filter-options.is-scroll .vb-content {
    max-height: 370px; }
  .filter-wrapper .filter-group.is-expand .filter-options.is-scroll .vb-dragger,
  .filter-wrapper .filter-price.is-expand .filter-options.is-scroll .vb-dragger {
    right: 0; }

.filter-wrapper .filter-price .filter-options .vue-slider-dot {
  box-shadow: none !important;
  border: 1px solid #e4e4e4; }

.filter-wrapper .filter-price .filter-options .grid {
  margin: 20px 0; }
  .filter-wrapper .filter-price .filter-options .grid .row input[type="number"],
  .filter-wrapper .filter-price .filter-options .grid .row input[type="text"] {
    border: none;
    width: 65px;
    padding: 4px 0;
    text-align: center;
    color: #767676;
    margin-left: 5px; }

.mobile-filter .swipe-content,
.mobile-sort .swipe-content {
  background: #ececec;
  width: 300px;
  transform: translateX(-300px); }
  .mobile-filter .swipe-content > header,
  .mobile-sort .swipe-content > header {
    margin: 30px 0;
    padding: 0 15px; }
    .mobile-filter .swipe-content > header .logo,
    .mobile-sort .swipe-content > header .logo {
      margin-left: 25px; }
    .mobile-filter .swipe-content > header .swipe-close,
    .mobile-sort .swipe-content > header .swipe-close {
      margin-top: 20px; }

.mobile-filter .scrolled {
  overflow: scroll; }

.mobile-sort ul:not(:last-child) {
  margin-bottom: 22px; }

.mobile-sort ul li a {
  color: #626161;
  text-decoration: none;
  outline: none;
  padding: 20px 33px 20px 21px;
  font-size: 16px;
  display: block;
  background: #fff; }
  .mobile-sort ul li a:hover {
    text-decoration: none; }

.mobile-sort ul li.is-active a {
  background-color: #4998cc;
  color: #fff; }

.reviews-last {
  margin-bottom: 20px; }
  .reviews-last__title {
    font-weight: bold;
    text-align: center; }
  .reviews-last__total-rating {
    text-align: center; }
    .reviews-last__total-rating .stars-rating {
      margin: 5px 0;
      justify-content: center; }

.product-page {
  padding-top: 15px; }
  .product-page .stock-single-item {
    margin-bottom: 45px; }
  .product-page > header {
    text-align: center;
    margin-bottom: 36px;
    font-size: inherit; }
    .product-page > header span {
      display: block; }
      .product-page > header span.product-name {
        font-size: 16px;
        color: #767676;
        margin-bottom: 8px; }
      .product-page > header span.original-name {
        font-size: 14px;
        color: #a4a4a4; }
  .product-page header.title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px; }
  .product-page .unavailable {
    margin: 24px 0; }
    .product-page .unavailable__text {
      color: #333333;
      font-size: 18px;
      min-height: 32px;
      line-height: 32px;
      font-weight: bold; }
  .product-page > .grid .row.properties {
    width: 390px; }
    .product-page > .grid .row.properties .brand-logo {
      margin-bottom: 22px; }
    .product-page > .grid .row.properties .properties-list > div {
      margin-bottom: 14px; }
      .product-page > .grid .row.properties .properties-list > div span {
        font-weight: 600; }
      .product-page > .grid .row.properties .properties-list > div ul li:before {
        display: inline;
        content: '- '; }
  .product-page > .grid .row.image {
    width: 369px;
    margin-right: 37px;
    position: relative; }
    .product-page > .grid .row.image .label-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
      .product-page > .grid .row.image .label-container .tooltip {
        width: 150px; }
        .product-page > .grid .row.image .label-container .tooltip .tooltip-inner {
          font-size: 14px; }
    .product-page > .grid .row.image .VueCarousel,
    .product-page > .grid .row.image .VueCarousel-wrapper,
    .product-page > .grid .row.image .VueCarousel-inner {
      height: inherit; }
    .product-page > .grid .row.image .VueCarousel {
      height: 369px; }
      .product-page > .grid .row.image .VueCarousel .VueCarousel-slide a {
        display: flex;
        width: 100%;
        height: 100%; }
        .product-page > .grid .row.image .VueCarousel .VueCarousel-slide a img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto; }
    .product-page > .grid .row.image .stock-block {
      margin-top: 40px;
      border: 1px solid #ececec;
      padding: 19px 12px; }
      .product-page > .grid .row.image .stock-block.grid {
        align-items: center;
        justify-content: flex-start; }
        .product-page > .grid .row.image .stock-block.grid .icon-row {
          margin-right: 18px; }
      .product-page > .grid .row.image .stock-block a {
        color: #626161;
        text-decoration: none;
        outline: none; }
        .product-page > .grid .row.image .stock-block a:hover {
          text-decoration: none; }
        .product-page > .grid .row.image .stock-block a span {
          display: block;
          color: #767676; }
          .product-page > .grid .row.image .stock-block a span.title {
            color: #333;
            margin-bottom: 10px; }
  .product-page > .grid .row.actions {
    width: 370px; }
    .product-page > .grid .row.actions .info {
      flex-wrap: wrap;
      align-items: center; }
      .product-page > .grid .row.actions .info .row {
        width: 50%;
        padding: 8px 0; }
        .product-page > .grid .row.actions .info .row:nth-of-type(2n) a.icon-wrapper {
          justify-content: flex-end; }
        .product-page > .grid .row.actions .info .row.code .code-inner {
          display: inline;
          padding: 8px 16px;
          border: 1px dashed #ffaf8b; }
          .product-page > .grid .row.actions .info .row.code .code-inner span {
            font-weight: bold; }
        .product-page > .grid .row.actions .info .row a.icon-wrapper {
          color: #767676;
          text-decoration: none;
          outline: none; }
          .product-page > .grid .row.actions .info .row a.icon-wrapper:hover {
            text-decoration: none; }
          .product-page > .grid .row.actions .info .row a.icon-wrapper span {
            margin-left: 10px; }
    .product-page > .grid .row.actions .discontinued-text {
      margin-top: 70px; }
      .product-page > .grid .row.actions .discontinued-text span {
        display: inline-block;
        border: 1px solid #e89191;
        color: #e89191;
        padding: 12px 18px;
        font-size: 18px;
        font-weight: bold; }
    .product-page > .grid .row.actions .store-info {
      font-size: 14px;
      padding: 7px; }
    .product-page > .grid .row.actions .discount-reason-block {
      margin-top: 20px; }
      .product-page > .grid .row.actions .discount-reason-block span {
        margin-left: 17px; }
    .product-page > .grid .row.actions .product-without-discount {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #ececec;
      margin-top: 20px;
      padding: 10px 0; }
      .product-page > .grid .row.actions .product-without-discount > div {
        margin-left: 10px; }
        .product-page > .grid .row.actions .product-without-discount > div a {
          color: #4998cc;
          text-decoration: none;
          outline: none; }
          .product-page > .grid .row.actions .product-without-discount > div a:hover {
            text-decoration: none; }
        .product-page > .grid .row.actions .product-without-discount > div p {
          text-align: center;
          padding-top: 2px; }
          .product-page > .grid .row.actions .product-without-discount > div p span {
            font-weight: 600;
            font-size: 17px; }
    .product-page > .grid .row.actions .buy-container {
      margin-top: 14px; }
      .product-page > .grid .row.actions .buy-container button {
        width: 48%; }
    .product-page > .grid .row.actions .info-block {
      margin-top: 17px;
      align-items: center;
      justify-content: flex-start; }
      .product-page > .grid .row.actions .info-block > .row:first-child {
        width: 70px; }
      .product-page > .grid .row.actions .info-block p {
        color: #767676; }
        .product-page > .grid .row.actions .info-block p.title {
          color: #333;
          margin-bottom: 12px; }
        .product-page > .grid .row.actions .info-block p span {
          color: #78a664; }
      .product-page > .grid .row.actions .info-block i.icon-product-payment {
        margin-left: 10px; }
  .product-page .alternative-products {
    margin-top: 50px; }
    .product-page .alternative-products > .grid {
      flex-wrap: wrap;
      justify-content: center; }
  .product-page .description-title {
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px; }
    .product-page .description-title h2 {
      font-weight: normal;
      color: #c2c2c2;
      font-size: 15px; }
  .product-page .seo-categories {
    margin-bottom: 30px; }
    .product-page .seo-categories__title {
      font-weight: bold;
      margin-bottom: 8px; }
    .product-page .seo-categories__link {
      color: #4998cc;
      text-decoration: none;
      outline: none;
      padding-right: 15px; }
      .product-page .seo-categories__link:hover {
        text-decoration: none; }
  .product-page .scroll-spy-container {
    margin-top: 40px;
    position: relative;
    padding-right: 410px; }
    .product-page .scroll-spy-container header.title {
      text-align: left;
      margin-bottom: 20px; }
    .product-page .scroll-spy-container .spy-block {
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s all ease;
      background: #fff;
      z-index: 2; }
      .product-page .scroll-spy-container .spy-block .offers-chooser {
        margin: 14px 0 0; }
      .product-page .scroll-spy-container .spy-block .product-price {
        margin: 14px 0; }
    .product-page .scroll-spy-container .product-spy-content {
      width: 390px;
      border: 7px solid #ececec;
      padding: 10px 14px; }
      .product-page .scroll-spy-container .product-spy-content a.icon-wrapper {
        color: #767676;
        text-decoration: none;
        outline: none; }
        .product-page .scroll-spy-container .product-spy-content a.icon-wrapper:hover {
          text-decoration: none; }
        .product-page .scroll-spy-container .product-spy-content a.icon-wrapper span {
          margin-left: 10px; }
      .product-page .scroll-spy-container .product-spy-content .header {
        margin-top: 6px;
        margin-bottom: 18px; }
      .product-page .scroll-spy-container .product-spy-content .grid .image {
        width: 120px;
        height: 120px;
        text-align: center;
        margin-right: 15px;
        flex-shrink: 0; }
        .product-page .scroll-spy-container .product-spy-content .grid .image img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto; }
      .product-page .scroll-spy-container .product-spy-content .grid .names {
        flex-grow: 1; }
        .product-page .scroll-spy-container .product-spy-content .grid .names p {
          margin-bottom: 8px; }
        .product-page .scroll-spy-container .product-spy-content .grid .names .product-name {
          color: #767676; }
        .product-page .scroll-spy-container .product-spy-content .grid .names .original-name {
          font-size: 13px;
          color: #a4a4a4; }
        .product-page .scroll-spy-container .product-spy-content .grid .names .code span {
          font-weight: bold; }
      .product-page .scroll-spy-container .product-spy-content .buy-container button {
        width: 48%; }
      .product-page .scroll-spy-container .product-spy-content .unavailable {
        margin: 14px 0; }
      .product-page .scroll-spy-container .product-spy-content .discount-reason-block {
        margin-top: 20px; }
        .product-page .scroll-spy-container .product-spy-content .discount-reason-block span {
          margin-left: 17px; }
      .product-page .scroll-spy-container .product-spy-content .discontinued-text {
        margin-top: 20px; }
        .product-page .scroll-spy-container .product-spy-content .discontinued-text span {
          display: inline-block;
          border: 1px solid #e89191;
          color: #e89191;
          padding: 12px 18px;
          font-size: 18px;
          font-weight: bold; }
  .product-page .description a {
    color: #4998cc;
    text-decoration: none;
    outline: none; }
    .product-page .description a:hover {
      text-decoration: none; }
  .product-page .description p {
    margin-bottom: 20px; }
  .product-page .seo-h1 {
    font-size: 18px; }
  .product-page .product-warning {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px dashed #ff002b; }
    .product-page .product-warning__header {
      color: #ee8759;
      font-weight: bold;
      margin-bottom: 8px; }
  .product-page .reviews > .grid {
    width: 100%; }
    .product-page .reviews > .grid .row.comments {
      padding-left: 90px;
      width: 100%; }
      .product-page .reviews > .grid .row.comments p.not-found {
        margin-bottom: 20px; }
  .product-page .reviews .stats .rating-container > div {
    margin: 8px 0; }
  .product-page .reviews .stats .rating-container .recommendation {
    margin: 0 0 20px; }
  .product-page .reviews .stats .rating-container .centered {
    display: flex;
    justify-content: center; }
  .product-page .reviews .stats .rating-container .rating .grid {
    margin: 8px 0; }
    .product-page .reviews .stats .rating-container .rating .grid > .row:nth-of-type(2) {
      margin: 0 16px; }
  .product-page .reviews .stats .rating-container .rating .progress-bar {
    width: 193px;
    height: 6px;
    background: #ececec; }
    .product-page .reviews .stats .rating-container .rating .progress-bar .indicator {
      background-color: #4998cc; }
  .product-page .reviews .stats .rating-container button {
    display: block;
    margin: 21px auto 0;
    padding: 12px 35px;
    background-color: #ececec; }
    .product-page .reviews .stats .rating-container button:hover {
      background-color: #e6e6e6; }
  .product-page .reviews .list {
    margin-top: 40px; }
    .product-page .reviews .list.is-loading {
      opacity: 0.3; }
    .product-page .reviews .list .loader {
      padding: 30px 0; }
    .product-page .reviews .list .review-item .answers .show-more {
      padding: 20px 0 0 30px; }
      .product-page .reviews .list .review-item .answers .show-more .icon-wrapper i.icon {
        margin-left: 12px;
        transition: 0.3s all ease;
        display: block; }
      .product-page .reviews .list .review-item .answers .show-more a {
        color: #4998cc;
        text-decoration: none;
        outline: none; }
        .product-page .reviews .list .review-item .answers .show-more a:hover {
          text-decoration: none; }
      .product-page .reviews .list .review-item .answers .show-more.is-active i.icon {
        transform: rotate(180deg); }
  .product-page .reviews .pagination {
    margin: 30px 0; }
  .product-page.is-discontinued > .grid .row.actions .info .row {
    width: 100%; }
  .product-page .offers-chooser {
    margin: 24px 0; }
    .product-page .offers-chooser .simple__list {
      justify-content: flex-start; }
    .product-page .offers-chooser .simple__list-item {
      margin-right: 5px; }
    .product-page .offers-chooser .extended {
      margin: 0; }
  .product-page .product-bonus {
    margin-left: 16px; }
    .product-page .product-bonus__value {
      font-size: 13px; }
  .product-page .product-price--large {
    min-height: 33px; }
  .product-page .product-review-tabs .tabs {
    margin-bottom: 20px; }
    .product-page .product-review-tabs .tabs ul li.is-active a {
      background: #eeeeee;
      border-color: transparent; }
    .product-page .product-review-tabs .tabs ul li a {
      color: #626161;
      text-decoration: none;
      outline: none;
      border: 1px solid #eeeeee;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      padding: 15px 35px; }
      .product-page .product-review-tabs .tabs ul li a:hover {
        text-decoration: none; }
      .product-page .product-review-tabs .tabs ul li a span {
        background: #ec8b38;
        padding: 4px;
        border-radius: 100%;
        color: #fff;
        height: 25px;
        width: 25px;
        display: inline-block; }

.product-list {
  margin-bottom: 60px; }
  .product-list--similar {
    margin-bottom: 20px; }
    .product-list--similar .product-list__title {
      text-align: left;
      margin-bottom: 20px; }
  .product-list__title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px; }
  .product-list__content {
    display: flex;
    flex-wrap: wrap; }

.modal-zoom .modal-content {
  width: 880px;
  background: #fff;
  padding: 46px 46px 0; }
  .modal-zoom .modal-content .zoomer {
    width: 480px;
    height: 480px;
    margin: 0 auto; }
  .modal-zoom .modal-content > .header {
    margin-bottom: 20px; }
    .modal-zoom .modal-content > .header .names {
      width: 470px; }
      .modal-zoom .modal-content > .header .names h2 {
        font-size: 19px;
        color: #626161;
        margin-bottom: 12px; }
      .modal-zoom .modal-content > .header .names h3 {
        font-size: 14px;
        color: #a4a4a4; }
    .modal-zoom .modal-content > .header .actions {
      align-items: center; }
      .modal-zoom .modal-content > .header .actions .price {
        margin-right: 22px;
        font-size: 19px;
        color: #333;
        padding: 5px 15px;
        text-align: center; }
        .modal-zoom .modal-content > .header .actions .price span {
          font-weight: bold; }
        .modal-zoom .modal-content > .header .actions .price.has-discount {
          border: 1px solid #e19dab;
          color: #e19dab;
          font-size: 17px; }
      .modal-zoom .modal-content > .header .actions .btn-buy {
        width: 160px; }
  .modal-zoom .modal-content .zoomer-carousel {
    width: 420px;
    margin: 0 auto;
    padding: 20px 0; }
    .modal-zoom .modal-content .zoomer-carousel .VueCarousel-slide a {
      border: 1px solid transparent;
      display: flex;
      width: 80px;
      height: 80px; }
      .modal-zoom .modal-content .zoomer-carousel .VueCarousel-slide a.active {
        border-color: #f4f4f4; }
      .modal-zoom .modal-content .zoomer-carousel .VueCarousel-slide a img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto; }

.modal-add-review .modal-content {
  width: 860px; }
  .modal-add-review .modal-content > header {
    margin: 5px 0 15px; }
    .modal-add-review .modal-content > header p {
      margin: 0;
      text-align: center; }
  .modal-add-review .modal-content form .stars-rating {
    justify-content: center; }
    .modal-add-review .modal-content form .stars-rating li {
      background: url("/build/images/stars_sprite_big.png") no-repeat -22px 0;
      width: 20px;
      height: 20px; }
      .modal-add-review .modal-content form .stars-rating li.is-active {
        background-position: -1px 0; }
  .modal-add-review .modal-content form .rating-container * {
    text-align: center !important; }
  .modal-add-review .modal-content form .grid {
    flex-wrap: wrap; }
    .modal-add-review .modal-content form .grid .row {
      flex-basis: 48%; }
  .modal-add-review .modal-content form .form-group * {
    text-align: left; }
  .modal-add-review .modal-content form .form-group label {
    padding: 10px 5px; }
  .modal-add-review .modal-content form .form-group .form-control {
    background: #fff; }
    .modal-add-review .modal-content form .form-group .form-control:not(.custom-select) {
      padding: 0 20px; }
    .modal-add-review .modal-content form .form-group .form-control.custom-select .current-value {
      padding: 0 20px; }
  .modal-add-review .modal-content form .form-group textarea {
    height: 120px; }
    .modal-add-review .modal-content form .form-group textarea.form-control {
      padding: 20px !important; }
    .modal-add-review .modal-content form .form-group textarea[name="content"] {
      height: 122px; }
  .modal-add-review .modal-content form .custom-select .dropdown {
    background-color: #fff;
    border: 1px solid #e9e9e9; }
    .modal-add-review .modal-content form .custom-select .dropdown .form-group {
      padding: 7px; }
      .modal-add-review .modal-content form .custom-select .dropdown .form-group .form-control {
        border: 2px solid #ececec; }
    .modal-add-review .modal-content form .custom-select .dropdown ul {
      background: none; }
      .modal-add-review .modal-content form .custom-select .dropdown ul li {
        padding: 5px 20px;
        color: #767676; }
        .modal-add-review .modal-content form .custom-select .dropdown ul li:hover {
          background-color: #dfdfdf; }
        .modal-add-review .modal-content form .custom-select .dropdown ul li.is-active {
          background-color: #daeaf5; }
    .modal-add-review .modal-content form .custom-select .dropdown .empty-result {
      padding: 5px 20px; }
  .modal-add-review .modal-content form a.recaptcha {
    color: #4998cc;
    text-decoration: none;
    outline: none; }
    .modal-add-review .modal-content form a.recaptcha:hover {
      text-decoration: none; }
  .modal-add-review .modal-content form .link {
    color: #4998cc;
    text-decoration: none;
    outline: none;
    margin-left: 22px;
    text-decoration: underline;
    padding: 5px 0; }
    .modal-add-review .modal-content form .link:hover {
      text-decoration: none; }
    .modal-add-review .modal-content form .link i.icon {
      margin-left: 10px; }
  .modal-add-review .modal-content form .files input[type="file"] {
    display: none; }
  .modal-add-review .modal-content form .files .progress-bar {
    width: 100%;
    height: 5px;
    background: #ececec; }
    .modal-add-review .modal-content form .files .progress-bar .indicator {
      background-color: #4998cc; }
  .modal-add-review .modal-content form .files .images {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .modal-add-review .modal-content form .files .images a {
      display: flex;
      width: 70px;
      height: 70px;
      position: relative;
      margin: 5px; }
      .modal-add-review .modal-content form .files .images a img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto; }
      .modal-add-review .modal-content form .files .images a .remove-container {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7); }
        .modal-add-review .modal-content form .files .images a .remove-container .icon {
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px); }
  .modal-add-review .modal-content form .extra-data {
    justify-content: flex-start;
    margin-top: 10px; }
    .modal-add-review .modal-content form .extra-data .row {
      flex-basis: 180px; }
  .modal-add-review .modal-content form .actions {
    align-items: flex-end; }
    .modal-add-review .modal-content form .actions .form-group {
      margin-bottom: 0; }
      .modal-add-review .modal-content form .actions .form-group button {
        text-align: center; }
        .modal-add-review .modal-content form .actions .form-group button.is-active {
          background: #e3e3e3; }
        .modal-add-review .modal-content form .actions .form-group button i.icon {
          margin-left: 5px; }

.signup-page {
  width: 566px;
  margin: 0 auto;
  padding-bottom: 192px; }
  .signup-page .man-in-the-black {
    position: relative;
    margin: 30px 73px 15px 73px;
    height: 28px;
    background: url("/build/images/dot_small_bg.jpg") center center repeat-x; }
    .signup-page .man-in-the-black .grid {
      height: 100%;
      align-items: center; }
    .signup-page .man-in-the-black .dot {
      width: 13px;
      height: 13px;
      background: #969696;
      border-radius: 100%; }
    .signup-page .man-in-the-black .man {
      position: absolute;
      top: 0;
      background: #fff;
      transition: .7s all ease-in-out;
      transform: translateX(0); }
  .signup-page h4 {
    font-size: 15px; }
  .signup-page .vue-form-wizard .wizard-navigation .wizard-nav {
    justify-content: space-between; }
    .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li {
      display: flex; }
      .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li:not(:last-child) {
        flex-grow: 1; }
      .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li a {
        display: block;
        width: 161px;
        height: 45px;
        line-height: 45px;
        background: #ececec;
        color: #767676;
        text-align: center; }
      .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li.active a {
        background: #8bc870;
        color: #fff; }
      .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li:not(:last-child):after {
        display: block;
        content: '';
        background: url("/build/images/steps_right.png");
        width: 8px;
        height: 14px;
        margin: auto; }
  .signup-page form {
    padding-top: 52px; }
    .signup-page form .form-group {
      margin-bottom: 12px; }
      .signup-page form .form-group label:not(.checkbox) {
        font-size: 16px;
        font-weight: 300;
        margin: 20px 0;
        text-align: center; }
      .signup-page form .form-group .form-control {
        text-align: center; }
      .signup-page form .form-group .help-block-error {
        text-align: center; }
    .signup-page form a {
      color: #626161;
      text-decoration: none;
      outline: none; }
      .signup-page form a:hover {
        text-decoration: none; }
      .signup-page form a.link {
        color: #767676;
        border-bottom: 1px solid #c2c2c2; }
        .signup-page form a.link.support-link {
          display: inline-block;
          margin: 15px 25px; }
    .signup-page form .custom-checkbox {
      margin-top: 35px; }
      .signup-page form .custom-checkbox .checkbox {
        width: 24px;
        height: 24px;
        background: rgba(206, 206, 206, 0.4);
        border-radius: 2px; }
        .signup-page form .custom-checkbox .checkbox.is-checked {
          background: #8fc875; }
      .signup-page form .custom-checkbox > span .icon-wrapper span {
        padding: 0 15px;
        font-size: 12px;
        color: #767676; }
    .signup-page form .links {
      margin-top: 20px; }
    .signup-page form .send-code .row:nth-of-type(1) {
      padding: 0 25px; }
    .signup-page form .send-code .row:nth-of-type(2) {
      flex-grow: 1; }
      .signup-page form .send-code .row:nth-of-type(2) .grid .row {
        padding: 0 15px; }
    .signup-page form .support-text {
      text-align: center;
      margin: 23px 0; }
      .signup-page form .support-text a {
        margin-left: 10px; }

.modal-support-call .modal-content {
  width: 400px; }

#root.order-mode .top-menu {
  top: 0;
  background: #ececec;
  color: #767676; }
  #root.order-mode .top-menu > .grid > .row:first-child {
    flex-basis: 44%; }
  #root.order-mode .top-menu > .grid > .row .city-chooser-container .city-chooser span {
    border-bottom: 1px dotted #767676; }
    #root.order-mode .top-menu > .grid > .row .city-chooser-container .city-chooser span:after {
      color: #767676; }
  #root.order-mode .top-menu > .grid > .row .auth-form-container .profile-link {
    text-align: left; }
    #root.order-mode .top-menu > .grid > .row .auth-form-container .profile-link a {
      color: #767676; }

#root.order-mode .layout__header .middle-menu > .grid > .row.phones {
  padding-left: 20px; }

#root.order-mode .layout__header .middle-menu > .grid > .row.logo {
  flex-grow: 1;
  text-align: center; }

#root.order-mode .layout__header .middle-menu > .grid > .row.request-call {
  padding-right: 144px; }

#root.order-mode .layout__footer {
  padding-top: 0; }

#root.order-mode .order-page h4 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px; }

#root.order-mode .order-page h5 {
  width: 260px;
  padding: 18px 0;
  border-bottom: 1px solid #ededed;
  margin-bottom: 14px;
  text-align: center; }

#root.order-mode .order-page .alert-success {
  font-size: 15px; }

#root.order-mode .order-page form {
  text-align: center; }
  #root.order-mode .order-page form .form-group .input-group {
    position: relative; }
    #root.order-mode .order-page form .form-group .input-group i.icon {
      position: absolute;
      left: 15px;
      top: 11px; }
      #root.order-mode .order-page form .form-group .input-group i.icon.icon-mail {
        left: 17px;
        top: 15px; }
    #root.order-mode .order-page form .form-group .input-group .form-control {
      padding: 0 40px; }
  #root.order-mode .order-page form .form-group label {
    padding: 5px 0; }
    #root.order-mode .order-page form .form-group label span.required {
      color: #ff0000; }
  #root.order-mode .order-page form .form-group .form-control {
    background: #f4f3f5;
    text-align: center;
    border: 1px solid #b3b3b3; }
    #root.order-mode .order-page form .form-group .form-control.completed, #root.order-mode .order-page form .form-group .form-control:disabled {
      background: #fff; }
  #root.order-mode .order-page form .form-group .hint {
    padding: 8px 0; }
    #root.order-mode .order-page form .form-group .hint span.username {
      font-weight: bold; }
    #root.order-mode .order-page form .form-group .hint.small {
      font-size: 13px; }
  #root.order-mode .order-page form .form-group textarea.form-control {
    text-align: left; }
  #root.order-mode .order-page form .m-t-20 {
    margin-top: 20px; }
  #root.order-mode .order-page form .icon-wrapper__center {
    justify-content: center;
    width: 100%; }
    #root.order-mode .order-page form .icon-wrapper__center span {
      padding: 0 10px; }
  #root.order-mode .order-page form .actions {
    margin: 30px 0; }
    #root.order-mode .order-page form .actions .btn .icon-wrapper i.icon {
      margin-right: 10px; }
  #root.order-mode .order-page form .custom-checkbox {
    padding: 8px 0; }
    #root.order-mode .order-page form .custom-checkbox > span {
      color: #4998cc; }
  #root.order-mode .order-page form .checkbox-container > .grid {
    justify-content: flex-start;
    align-items: center; }
    #root.order-mode .order-page form .checkbox-container > .grid .row:last-child {
      padding-left: 10px; }
  #root.order-mode .order-page form .custom-select.is-disabled {
    background: #fff; }
  #root.order-mode .order-page form .custom-select .dropdown {
    background-color: #fff;
    border: 1px solid #b3b3b3; }
    #root.order-mode .order-page form .custom-select .dropdown .form-group {
      padding: 7px; }
      #root.order-mode .order-page form .custom-select .dropdown .form-group .form-control {
        border: 1px solid #b3b3b3; }
    #root.order-mode .order-page form .custom-select .dropdown ul {
      background: none; }
      #root.order-mode .order-page form .custom-select .dropdown ul li {
        overflow: hidden;
        white-space: nowrap;
        color: #767676; }
        #root.order-mode .order-page form .custom-select .dropdown ul li:hover {
          background-color: #dfdfdf; }
        #root.order-mode .order-page form .custom-select .dropdown ul li.is-active {
          background-color: #daeaf5; }
    #root.order-mode .order-page form .custom-select .dropdown .empty-result {
      padding: 5px 20px; }

#root.order-mode .order-page .delivery-step-content .form-group {
  margin-bottom: 15px; }

#root.order-mode .order-page .delivery-step-content .paysystem-description {
  margin-top: 10px;
  color: #e89191; }

#root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav {
  display: flex;
  justify-content: center; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav .wizard-icon-container,
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav .wizard-icon-circle {
    display: flex;
    height: 70px;
    margin: auto; }
    #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav .wizard-icon-container i.icon,
    #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav .wizard-icon-circle i.icon {
      margin: auto; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li {
    display: flex; }
    #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li:not(:last-child):after {
      display: block;
      content: '';
      background: url("/build/images/steps_right.png");
      width: 8px;
      height: 14px;
      margin: auto 30px;
      flex-shrink: 0; }
    #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li.active a {
      background: #ececec; }
    #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li span.stepTitle {
      display: block;
      padding: 15px 31px;
      color: #767676;
      min-width: 158px;
      text-align: center; }

#root.order-mode .order-page .vue-form-wizard .wizard-tab-content {
  margin-top: 20px;
  padding: 5px;
  background: #ececec;
  display: flex; }
  #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container {
    flex-grow: 1;
    background: #fff; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container > header {
      display: flex;
      justify-content: center; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container form {
      position: relative; }
      #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container form .loader-wrapper.is-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.4); }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center {
      justify-content: center;
      align-items: flex-start; }
      #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row {
        padding: 0 30px;
        width: 340px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between; }
        #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row .form-group {
          width: 100%; }
        #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row .field-house-container,
        #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row .field-flat-container,
        #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row .field-date-container,
        #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row .field-time-container {
          flex-basis: 48%; }
  #root.order-mode .order-page .vue-form-wizard .wizard-tab-content a.link {
    color: #4998cc;
    text-decoration: none;
    outline: none;
    text-decoration: underline;
    text-align: center; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content a.link:hover {
      text-decoration: none; }
  #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container {
    display: flex;
    width: 311px;
    margin-left: 5px; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .v-spinner {
      margin: auto; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container > div.container {
      width: 100%; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container header {
      text-align: center;
      padding: 18px 0; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .cart-carousel {
      display: none; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .cart-items {
      background: #fff; }
      #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .cart-items .vb-content {
        max-height: 300px; }
      #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .cart-items .cart-item:not(:last-child) {
        border-bottom: 1px solid #ececec; }

#root.order-mode .order-page .paysystem-list.select-mode .paysystem:not(.is-selected) {
  opacity: .3; }

#root.order-mode .order-page .paysystem-list form > .grid {
  flex-wrap: wrap;
  justify-content: space-around; }
  #root.order-mode .order-page .paysystem-list form > .grid > .row {
    width: 30%; }

#root.order-mode .order-page .cart-item {
  align-items: center;
  padding: 14px 0; }
  #root.order-mode .order-page .cart-item .image {
    width: 70px;
    height: 70px;
    display: flex;
    flex-shrink: 0; }
    #root.order-mode .order-page .cart-item .image img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto; }
  #root.order-mode .order-page .cart-item .names {
    flex-grow: 1;
    padding-left: 5px; }
    #root.order-mode .order-page .cart-item .names p {
      font-size: 13px;
      color: #6d6d6d;
      margin-bottom: 12px;
      text-transform: uppercase; }
      #root.order-mode .order-page .cart-item .names p a {
        color: #626161;
        text-decoration: none;
        outline: none;
        color: inherit; }
        #root.order-mode .order-page .cart-item .names p a:hover {
          text-decoration: none; }
      #root.order-mode .order-page .cart-item .names p.alias {
        color: #949494; }
    #root.order-mode .order-page .cart-item .names .grid {
      justify-content: flex-start;
      align-items: center; }
      #root.order-mode .order-page .cart-item .names .grid .quantity {
        background: #e9e9e7;
        color: #767676;
        padding: 4px 6px;
        font-size: 13px; }
      #root.order-mode .order-page .cart-item .names .grid .separator {
        margin: 0 15px; }

#root.order-mode .order-page .totals {
  padding: 21px 25px; }
  #root.order-mode .order-page .totals .bonus-container {
    justify-content: flex-end; }
    #root.order-mode .order-page .totals .bonus-container span {
      padding-right: 5px; }
  #root.order-mode .order-page .totals table {
    width: 100%; }
    #root.order-mode .order-page .totals table:nth-of-type(1) {
      margin-bottom: 5px; }
    #root.order-mode .order-page .totals table:nth-of-type(2) {
      margin-top: 5px;
      border-top: 1px solid #fff; }
    #root.order-mode .order-page .totals table tr td {
      padding: 10px 0; }
      #root.order-mode .order-page .totals table tr td:last-child {
        text-align: right; }
      #root.order-mode .order-page .totals table tr td span {
        color: #f16e26; }

#root.order-mode .order-page .paysystem {
  position: relative;
  cursor: pointer;
  padding: 35px 0;
  text-align: center; }
  #root.order-mode .order-page .paysystem i.icon-order-checked {
    position: absolute;
    top: 20px; }
  #root.order-mode .order-page .paysystem .paysystem-name {
    margin-top: 20px; }
  #root.order-mode .order-page .paysystem .paysystem-description {
    margin-top: 10px;
    color: #e89191; }

#root.order-mode .order-page .summary {
  padding: 0 36px; }
  #root.order-mode .order-page .summary header {
    padding: 25px 0;
    text-align: center; }
  #root.order-mode .order-page .summary > .grid > .row {
    width: 50%; }
    #root.order-mode .order-page .summary > .grid > .row .icon-wrapper {
      margin-bottom: 22px;
      justify-content: space-between; }
      #root.order-mode .order-page .summary > .grid > .row .icon-wrapper i.icon {
        margin-right: 15px; }
      #root.order-mode .order-page .summary > .grid > .row .icon-wrapper.bonus-container {
        justify-content: flex-end; }
        #root.order-mode .order-page .summary > .grid > .row .icon-wrapper.bonus-container i.icon {
          margin-right: 0; }
      #root.order-mode .order-page .summary > .grid > .row .icon-wrapper span {
        flex-basis: 85%; }
    #root.order-mode .order-page .summary > .grid > .row:first-child > .grid {
      border-right: 1px solid #ededed; }
      #root.order-mode .order-page .summary > .grid > .row:first-child > .grid > .row {
        width: 50%; }
    #root.order-mode .order-page .summary > .grid > .row:last-child > .grid {
      justify-content: center; }
      #root.order-mode .order-page .summary > .grid > .row:last-child > .grid > .row {
        width: 48%; }
  #root.order-mode .order-page .summary .VueCarousel {
    margin-bottom: 30px; }
  #root.order-mode .order-page .summary .cart-item .names p {
    font-size: 14px; }
  #root.order-mode .order-page .summary .paysystem {
    cursor: default; }
  #root.order-mode .order-page .summary .totals table:nth-of-type(2) {
    border-color: #ededed; }

#root.order-mode .order-page .offer-text {
  margin: 18px 0;
  font-size: 11px;
  text-align: center; }
  #root.order-mode .order-page .offer-text a {
    color: #626161;
    text-decoration: none;
    outline: none; }
    #root.order-mode .order-page .offer-text a:hover {
      text-decoration: none; }

#root.order-mode .order-page .pickup-address-description {
  padding: 9px;
  margin-top: 10px;
  background: #87e49b;
  color: #3b3c44; }

#pickup-map {
  margin: 20px 0;
  width: 100%;
  height: 280px; }

.bonus-hint__mark {
  font-weight: bold;
  color: #e74c3c; }

.order-make {
  width: 578px;
  margin: 0 auto;
  text-align: center; }
  .order-make > header {
    display: flex;
    justify-content: center; }
  .order-make h4 {
    margin: 24px 0; }
  .order-make .greeting {
    margin-bottom: 70px;
    font-size: 15px; }
    .order-make .greeting span {
      color: #8bc870;
      font-weight: bold; }
      .order-make .greeting span.order-id {
        font-size: 18px; }
  .order-make > .grid > .row .grid {
    justify-content: flex-start;
    align-items: center; }
    .order-make > .grid > .row .grid .row.label, .order-make > .grid > .row .grid .row.value {
      font-weight: bold;
      font-size: 16px; }
    .order-make > .grid > .row .grid .row.label {
      color: #767676; }
    .order-make > .grid > .row .grid .row.value {
      padding-left: 10px;
      color: #8bc870; }
      .order-make > .grid > .row .grid .row.value .icon-wrapper span {
        padding-right: 10px; }
  .order-make p.hint {
    margin-top: 10px;
    text-align: left;
    width: 230px;
    font-size: 13px; }
  .order-make.guest-mode > .grid.pay-info {
    justify-content: center; }
  .order-make .separator {
    width: 260px;
    margin: 40px auto 40px auto;
    border-bottom: 1px solid #ededed; }
  .order-make .user-budget {
    text-align: left;
    margin-bottom: 40px; }
    .order-make .user-budget span.amount {
      color: #8bc870;
      font-weight: bold; }
    .order-make .user-budget span.container {
      position: relative; }
      .order-make .user-budget span.container a {
        color: #4998cc;
        text-decoration: none;
        outline: none;
        text-decoration: underline; }
        .order-make .user-budget span.container a:hover {
          text-decoration: none; }
      .order-make .user-budget span.container .popup {
        width: 326px;
        padding: 15px 25px; }
        .order-make .user-budget span.container .popup:after {
          margin-left: 42px; }
        .order-make .user-budget span.container .popup .grid {
          justify-content: space-around; }
        .order-make .user-budget span.container .popup .form-group .form-control {
          width: 94px;
          height: 38px;
          line-height: 38px;
          background: #ededed !important;
          font-weight: bold; }
        .order-make .user-budget span.container .popup .form-group .help-block-error {
          font-size: inherit;
          color: #e89191;
          text-align: left;
          margin-top: 15px; }
  .order-make .popup {
    margin-top: 10px;
    border: 2px solid #ededed;
    background-color: #fff;
    z-index: 1; }
    .order-make .popup:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: 8px solid #ededed;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
  .order-make .popup-container {
    position: relative; }
  .order-make .payments-container > .grid .row:first-child {
    padding-right: 10px; }
  .order-make .payments-container .popup {
    padding: 10px; }
  .order-make .payments-container .payments {
    display: flex; }
    .order-make .payments-container .payments .payment:not(:last-child) {
      border-right: 1px solid #ededed; }
    .order-make .payments-container .payments .payment a {
      display: flex;
      padding: 0 20px;
      height: 60px; }
      .order-make .payments-container .payments .payment a i.icon {
        display: block;
        margin: auto; }
    .order-make .payments-container .payments .payment .form {
      display: none; }
  .order-make .payments-details-container > .grid .row:first-child {
    padding-right: 10px; }
  .order-make .payments-details-container .popup {
    width: 310px;
    padding: 25px; }
    .order-make .payments-details-container .popup .grid {
      justify-content: space-around; }
    .order-make .payments-details-container .popup a {
      color: #4998cc;
      text-decoration: none;
      outline: none;
      text-decoration: underline;
      font-weight: bold; }
      .order-make .payments-details-container .popup a:hover {
        text-decoration: none; }
    .order-make .payments-details-container .popup .icon-wrapper span {
      padding-left: 10px; }
  .order-make .btn-small {
    width: 94px; }
  .order-make .actions {
    margin-top: 40px;
    margin-bottom: 80px; }
    .order-make .actions .btn {
      width: 220px; }

.news-list {
  padding-bottom: 40px; }
  .news-list h1 {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    color: #767676; }
  .news-list .news-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .news-list .news-container::after {
      height: 0;
      width: 30%;
      content: ""; }
  .news-list .news-item {
    width: 335px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 55px; }
    .news-list .news-item > header {
      font-size: 15px;
      color: #767676;
      font-weight: bold;
      margin-bottom: 12px; }
      .news-list .news-item > header a {
        color: inherit;
        text-decoration: none;
        outline: none; }
        .news-list .news-item > header a:hover {
          text-decoration: none; }
    .news-list .news-item .info {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 13px; }
      .news-list .news-item .info .date {
        color: #4998cc; }
      .news-list .news-item .info .viewed i.icon {
        margin-right: 5px; }
    .news-list .news-item .detail {
      margin: 20px 0;
      text-align: right;
      color: #4998cc;
      text-decoration: none;
      outline: none;
      font-weight: bold;
      justify-content: flex-end; }
      .news-list .news-item .detail:hover {
        text-decoration: none; }
      .news-list .news-item .detail span {
        padding-right: 18px; }

.news-page {
  padding-bottom: 40px; }
  .news-page__header h1 {
    font-size: 27px;
    text-align: center;
    color: #767676;
    font-weight: bold; }
  .news-page__info {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    display: flex;
    justify-content: space-between; }
  .news-page__date {
    color: #4998cc;
    font-weight: bold; }
  .news-page__viewed i.icon {
    margin-right: 5px; }
  .news-page__footer-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px; }

.articles-list {
  padding-bottom: 40px; }
  .articles-list h1 {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    color: #767676; }
  .articles-list .articles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .articles-list .articles-container::after {
      height: 0;
      width: 30%;
      content: ""; }
  .articles-list .article-item {
    width: 335px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 55px; }
    .articles-list .article-item > header {
      font-size: 15px;
      color: #767676;
      font-weight: bold;
      margin-bottom: 12px; }
      .articles-list .article-item > header a {
        color: inherit;
        text-decoration: none;
        outline: none; }
        .articles-list .article-item > header a:hover {
          text-decoration: none; }
    .articles-list .article-item .articles-image img {
      max-width: 100%; }
    .articles-list .article-item .info {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 13px; }
      .articles-list .article-item .info .date {
        color: #4998cc; }
      .articles-list .article-item .info .viewed i.icon {
        margin-right: 5px; }
    .articles-list .article-item .detail {
      margin: 20px 0;
      text-align: right;
      color: #4998cc;
      text-decoration: none;
      outline: none;
      font-weight: bold;
      justify-content: flex-end; }
      .articles-list .article-item .detail:hover {
        text-decoration: none; }
      .articles-list .article-item .detail span {
        padding-right: 18px; }

.article-page {
  padding-bottom: 40px; }
  .article-page__container {
    padding-bottom: 40px;
    max-width: 650px;
    margin: 0 auto; }
  .article-page__header h1 {
    font-size: 27px;
    text-align: center;
    color: #767676;
    font-weight: bold; }
  .article-page__info {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    display: flex;
    justify-content: space-between; }
  .article-page__date {
    color: #4998cc;
    font-weight: bold; }
  .article-page__viewed i.icon {
    margin-right: 5px; }
  .article-page__footer-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px; }

.profile {
  padding-bottom: 40px; }
  .profile > header {
    margin: 30px 0; }
    .profile > header h1 {
      font-size: 15px;
      color: #333333;
      text-align: center; }
  .profile .panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ececec;
    height: 61px;
    padding: 0 40px 0 30px; }
    .profile .panel .icon-wrapper span {
      padding: 0 10px; }
    .profile .panel .bonus-container .icon-wrapper span.value {
      color: #e57521; }
    .profile .panel .discount-container .icon-wrapper span.value {
      color: #ac4a6b; }
    .profile .panel .logout-container a {
      color: #626161;
      text-decoration: none;
      outline: none; }
      .profile .panel .logout-container a:hover {
        text-decoration: none; }
  .profile ul.nav {
    padding: 4px;
    background: #ececec; }
    .profile ul.nav li {
      background: #fff; }
      .profile ul.nav li:not(:last-child) {
        margin-bottom: 3px; }
      .profile ul.nav li.active a {
        font-weight: bold; }
      .profile ul.nav li a {
        color: #626161;
        text-decoration: none;
        outline: none;
        padding: 0 15px;
        height: 53px; }
        .profile ul.nav li a:hover {
          text-decoration: none; }
        .profile ul.nav li a span {
          padding-left: 10px; }
  .profile > .grid {
    margin-top: 15px; }
    .profile > .grid aside {
      flex-basis: 293px;
      flex-shrink: 0;
      margin-right: 10px; }
    .profile > .grid .profile-content {
      flex-grow: 1; }
      .profile > .grid .profile-content .profile-container {
        background: #ececec;
        padding-bottom: 20px;
        margin-bottom: 20px; }
        .profile > .grid .profile-content .profile-container > header {
          padding: 25px 0;
          border-bottom: 1px solid #fff;
          text-align: center; }
      .profile > .grid .profile-content .orders-container .filters {
        display: flex;
        padding: 0 20px; }
        .profile > .grid .profile-content .orders-container .filters a {
          color: #626161;
          text-decoration: none;
          outline: none;
          padding: 27px 15px; }
          .profile > .grid .profile-content .orders-container .filters a:hover {
            text-decoration: none; }
          .profile > .grid .profile-content .orders-container .filters a.is-active {
            font-weight: bold; }
      .profile > .grid .profile-content .orders-container .orders-list {
        padding: 0 20px; }
        .profile > .grid .profile-content .orders-container .orders-list .order-item {
          margin-bottom: 10px; }
          .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            height: 51px;
            background: #fff;
            align-items: center;
            padding: 0 17px; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .label {
              padding-right: 5px; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .date .label,
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .paid .label {
              display: none; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .id {
              flex-basis: 17%; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .date {
              flex-basis: 18%; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .date .value {
                color: #ac4a6b; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .total {
              flex-basis: 20%; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .paid {
              flex-basis: 19%; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .paid .value {
                color: #ff9239; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .paid .value.is-paid {
                  color: #8bc870; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .completed {
              flex-basis: 19%; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .completed .value {
                color: #e89191; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .completed .value.is-complete {
                  color: #8bc870; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .details {
              color: #767676; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .details .icon-wrapper span {
                opacity: 0.5;
                padding-right: 5px; }
          .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info {
            background: #fff; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery {
              padding: 21px 26px;
              border-top: 1px solid #e0e0e0; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery > header {
                font-weight: bold;
                margin-bottom: 23px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .icon-wrapper {
                margin: 10px 0; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .delivery-type i.icon {
                margin-right: 14px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .delivery-value i.icon {
                margin-left: 5px;
                margin-right: 12px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .manager-info i.icon {
                margin-right: 10px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .manager-info span > span {
                padding-left: 10px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .manager-message i.icon {
                margin-right: 11px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .manager-message a {
                color: #7db103;
                text-decoration: none;
                outline: none;
                font-weight: bold; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .manager-message a:hover {
                  text-decoration: none; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses {
              border-top: 1px solid #e0e0e0;
              display: flex;
              padding: 30px 0;
              justify-content: center; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status {
                display: flex; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status:not(:last-child):after {
                  margin: 25px 10px 0 10px;
                  display: block;
                  content: "\2022\2022\2022\2022";
                  color: #9f9f9f; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner {
                  width: 90px; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .icon-container {
                    position: relative;
                    display: flex;
                    height: 60px; }
                    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .icon-container .icon-order-checked {
                      position: absolute;
                      right: 10%; }
                    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .icon-container .status-icon {
                      margin: auto; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .date {
                    font-size: 12px;
                    color: #a5a5a5;
                    margin-bottom: 8px; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .name {
                    text-align: center;
                    font-size: 13px; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items {
              border-top: 1px solid #e0e0e0; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view {
                display: none; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items .cart-item .image {
                min-width: 40px;
                text-align: center;
                margin-right: 10px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table {
                width: 100%; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table thead tr {
                  border-bottom: 1px solid #e0e0e0; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table thead tr th {
                    font-weight: bold;
                    padding: 15px;
                    text-align: left; }
                    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table thead tr th:first-child {
                      padding-left: 24px;
                      width: 55%; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table tbody tr td {
                  padding: 5px 15px;
                  vertical-align: middle; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table tbody tr td:first-child {
                    padding-left: 24px; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table tbody tr td .grid {
                    justify-content: flex-start;
                    align-items: center; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table tbody tr td a {
                    color: #4998cc;
                    text-decoration: none;
                    outline: none; }
                    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items table tbody tr td a:hover {
                      text-decoration: none; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating {
              border-top: 1px solid #e0e0e0;
              padding: 10px 0; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating.is-active .grid .row.rating-item:not(.is-active) {
                opacity: 0.3; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .grid {
                justify-content: space-around;
                align-items: center;
                margin: 10px 0; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .grid .row {
                  cursor: pointer; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .grid .row span {
                    display: block;
                    padding: 10px 0; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .unacceptable {
                color: #e10019; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .bad {
                color: #9f1c19; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .normal {
                color: #68341a; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .good {
                color: #20531b; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .excellent {
                color: #01601b; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .success-message {
                text-align: center;
                color: #8cc870; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment {
                display: flex;
                padding: 0 20px; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment .form-control {
                  flex-grow: 1;
                  padding: 11px 20px;
                  border-color: #e0e0e0; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment button {
                  height: 100%; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment-content {
                  padding: 0 20px; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment-content p:not(:last-child) {
                    margin-bottom: 8px; }
            .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container {
              border-top: 1px solid #e0e0e0;
              padding: 20px; }
              .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid {
                justify-content: flex-end;
                align-items: center; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .gifts {
                  flex-grow: 1; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .gifts table {
                    width: 100%; }
                    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .gifts table tbody tr td {
                      padding: 10px 0; }
                      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .gifts table tbody tr td.quantity {
                        width: 15%; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .gifts .bonus-container .icon-wrapper span > span {
                    color: #f16e26;
                    padding: 0 5px; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions a.icon-wrapper {
                  margin: 10px 0;
                  color: #626161;
                  text-decoration: none;
                  outline: none; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions a.icon-wrapper:hover {
                    text-decoration: none; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions a.icon-wrapper span {
                    padding-left: 10px; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container > .grid .row:first-child {
                  padding-right: 10px; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container .popup {
                  padding: 10px; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container .payments {
                  display: flex; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container .payments .payment:not(:last-child) {
                    border-right: 1px solid #ededed; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container .payments .payment a {
                    display: flex;
                    padding: 0 20px;
                    height: 60px; }
                    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container .payments .payment a i.icon {
                      display: block;
                      margin: auto; }
                  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .pay-container .payments .payment .form {
                    display: none; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .cancel-container a.icon-wrapper i.icon {
                  margin-left: 3px; }
                .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .actions .cancel-container a.icon-wrapper span {
                  padding-left: 14px; }
      .profile > .grid .profile-content .orders-container .pagination {
        padding: 20px 0; }
  .profile .popup {
    margin-top: 10px;
    border: 2px solid #ededed;
    background-color: #fff;
    z-index: 1; }
    .profile .popup:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: 8px solid #ededed;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
  .profile .popup-container {
    position: relative; }
  .profile .profile-account-index .link {
    color: #4998cc;
    text-decoration: none;
    outline: none;
    text-decoration: underline;
    display: block;
    padding: 15px 0; }
    .profile .profile-account-index .link:hover {
      text-decoration: none; }
  .profile .account-container .label,
  .profile .password-container .label {
    padding: 15px 0;
    text-align: center; }
  .profile .account-container form,
  .profile .password-container form {
    padding: 20px; }
    .profile .account-container form .grid,
    .profile .password-container form .grid {
      flex-wrap: wrap; }
      .profile .account-container form .grid .row,
      .profile .password-container form .grid .row {
        flex-basis: 48%; }
    .profile .account-container form .btn-submit,
    .profile .password-container form .btn-submit {
      width: 50%;
      margin: 10px auto; }
    .profile .account-container form .form-group,
    .profile .password-container form .form-group {
      margin-bottom: 15px; }
      .profile .account-container form .form-group label,
      .profile .password-container form .form-group label {
        margin-bottom: 5px; }
      .profile .account-container form .form-group .form-control,
      .profile .password-container form .form-group .form-control {
        padding: 0 15px;
        background: #fff;
        height: 51px;
        line-height: 51px; }
      .profile .account-container form .form-group .custom-select .dropdown,
      .profile .password-container form .form-group .custom-select .dropdown {
        background-color: #fff;
        border: 1px solid #e9e9e9; }
        .profile .account-container form .form-group .custom-select .dropdown .form-group,
        .profile .password-container form .form-group .custom-select .dropdown .form-group {
          padding: 7px; }
          .profile .account-container form .form-group .custom-select .dropdown .form-group .form-control,
          .profile .password-container form .form-group .custom-select .dropdown .form-group .form-control {
            border: 2px solid #ececec; }
        .profile .account-container form .form-group .custom-select .dropdown ul,
        .profile .password-container form .form-group .custom-select .dropdown ul {
          background: none; }
          .profile .account-container form .form-group .custom-select .dropdown ul li,
          .profile .password-container form .form-group .custom-select .dropdown ul li {
            padding: 0 20px;
            color: #767676; }
            .profile .account-container form .form-group .custom-select .dropdown ul li:hover,
            .profile .password-container form .form-group .custom-select .dropdown ul li:hover {
              background-color: #dfdfdf; }
            .profile .account-container form .form-group .custom-select .dropdown ul li.is-active,
            .profile .password-container form .form-group .custom-select .dropdown ul li.is-active {
              background-color: #daeaf5; }
        .profile .account-container form .form-group .custom-select .dropdown .empty-result,
        .profile .password-container form .form-group .custom-select .dropdown .empty-result {
          padding: 5px 20px; }
  .profile .account-container {
    margin-bottom: 20px; }
  .profile .password-container .grid .row:first-child {
    flex-basis: 100%; }
  .profile .wishlist-container .actions {
    padding: 27px 57px; }
    .profile .wishlist-container .actions .custom-checkbox .checkbox {
      background: #fff; }
    .profile .wishlist-container .actions a {
      color: #626161;
      text-decoration: none;
      outline: none; }
      .profile .wishlist-container .actions a:hover {
        text-decoration: none; }
    .profile .wishlist-container .actions .askbuy .icon-wrapper span {
      padding: 0 15px; }
    .profile .wishlist-container .actions .askbuy .tooltip {
      width: 300px; }
    .profile .wishlist-container .actions .remove .icon-wrapper span {
      color: #e89191;
      padding-left: 15px; }
    .profile .wishlist-container .actions .is-disabled {
      opacity: 0.5;
      cursor: not-allowed; }
  .profile .wishlist-container .offers-list-container {
    padding: 0 20px; }
    .profile .wishlist-container .offers-list-container .offers-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .profile .wishlist-container .offers-list-container .offers-list::after {
        height: 0;
        width: 32%;
        content: ""; }
      .profile .wishlist-container .offers-list-container .offers-list .offer-item {
        width: 32%;
        margin-bottom: 15px; }
  .profile .addressbook-container .label {
    padding: 15px 0;
    text-align: center; }
  .profile .addressbook-container .profiles-list-container {
    padding: 20px; }
    .profile .addressbook-container .profiles-list-container .profiles-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .profile .addressbook-container .profiles-list-container .profiles-list::after {
        height: 0;
        width: 32%;
        content: ""; }
      .profile .addressbook-container .profiles-list-container .profiles-list .profile-item {
        width: 32%;
        margin-bottom: 35px;
        padding: 16px; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item.is-active {
          background: #e5ebee; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .primary-container {
          margin-bottom: 40px; }
          .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .primary-container .custom-checkbox {
            justify-content: center; }
            .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .primary-container .custom-checkbox .checkbox {
              background: #fff; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .icon-wrapper {
          margin: 15px 0; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .delivery-type i.icon {
          margin-right: 17px; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .delivery-value i.icon {
          margin-left: 6px;
          margin-right: 13px; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .link {
          margin-top: 15px;
          color: #4998cc;
          text-decoration: none;
          outline: none;
          text-align: center;
          text-decoration: underline; }
          .profile .addressbook-container .profiles-list-container .profiles-list .profile-item .link:hover {
            text-decoration: none; }
      .profile .addressbook-container .profiles-list-container .profiles-list .profile-item-add {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item-add > span {
          display: block;
          flex-basis: 100%; }
        .profile .addressbook-container .profiles-list-container .profiles-list .profile-item-add a {
          color: #626161;
          text-decoration: none;
          outline: none;
          display: block;
          font-weight: bold;
          margin: auto;
          text-align: center; }
          .profile .addressbook-container .profiles-list-container .profiles-list .profile-item-add a:hover {
            text-decoration: none; }
  .profile .addressbook-container .edit-form {
    padding: 20px; }
    .profile .addressbook-container .edit-form .grid .row {
      width: 48%; }
    .profile .addressbook-container .edit-form form .form-group {
      margin-bottom: 15px; }
      .profile .addressbook-container .edit-form form .form-group label {
        margin-bottom: 5px; }
      .profile .addressbook-container .edit-form form .form-group .form-control {
        padding: 0 15px;
        background: #fff;
        height: 51px;
        line-height: 51px; }
      .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown {
        background-color: #fff;
        border: 1px solid #e9e9e9; }
        .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown .form-group {
          padding: 7px; }
          .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown .form-group .form-control {
            border: 2px solid #ececec; }
        .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown ul {
          background: none; }
          .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown ul li {
            padding: 0 20px;
            color: #767676; }
            .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown ul li:hover {
              background-color: #dfdfdf; }
            .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown ul li.is-active {
              background-color: #daeaf5; }
        .profile .addressbook-container .edit-form form .form-group .custom-select .dropdown .empty-result {
          padding: 5px 20px; }
      .profile .addressbook-container .edit-form form .form-group .btn-block {
        width: 50%;
        margin: 0 auto; }
      .profile .addressbook-container .edit-form form .form-group .btn-link {
        color: #e89191;
        text-decoration: underline; }

.modal-ask-buy .modal-content {
  width: 400px; }

.bonus-profile-page {
  padding: 0 25px 25px 25px; }

.bonus-history {
  margin-bottom: 15px;
  padding: 15px;
  background: #fff; }
  .bonus-history__content {
    padding: 0 25px;
    display: flex;
    justify-content: space-between; }
  .bonus-history__group {
    width: 25%; }
  .bonus-history__key {
    font-weight: bold;
    margin-bottom: 8px; }
  .bonus-history__value .grid {
    justify-content: flex-start; }
  .bonus-history__value img {
    width: 17px;
    margin-right: 5px; }
  .bonus-history__value--empty {
    color: #767676;
    opacity: 0.5; }
  .bonus-history__value--date {
    color: #c27a92; }
  .bonus-history__value--add {
    color: #8bc870; }
  .bonus-history__value--remove {
    color: #e89191; }

.stock-list > header h1 {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0; }

.stock-list .filters {
  display: flex; }
  .stock-list .filters .form-group:not(:last-child) {
    margin-right: 20px; }
  .stock-list .filters .custom-select {
    background-color: #fff;
    border: 2px solid #ececec;
    line-height: 38px; }
    .stock-list .filters .custom-select .current-value {
      padding: 0 40px 0 18px; }
    .stock-list .filters .custom-select .dropdown {
      border: 2px solid #ececec;
      width: auto;
      min-width: 100%; }
    .stock-list .filters .custom-select ul {
      background-color: #fff;
      border-width: 2px; }
      .stock-list .filters .custom-select ul li {
        padding: 0 18px;
        white-space: nowrap; }

.stock-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .stock-list-container::after {
    height: 0;
    width: 30%;
    content: ""; }
  .stock-list-container .stock-item {
    flex-basis: 30%; }
    .stock-list-container .stock-item.is-finished a > img {
      opacity: 0.5; }
    .stock-list-container .stock-item .condition {
      padding: 20px 0;
      height: 40px;
      box-sizing: content-box;
      display: flex;
      flex-direction: column-reverse; }
    .stock-list-container .stock-item a {
      color: #626161;
      text-decoration: none;
      outline: none;
      position: relative;
      display: block; }
      .stock-list-container .stock-item a:hover {
        text-decoration: none; }
      .stock-list-container .stock-item a span.finished-label {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        display: flex;
        width: 130px;
        height: 130px;
        background-color: #8bc870;
        color: #fff;
        font-weight: bold;
        border-radius: 100%;
        z-index: 1; }
        .stock-list-container .stock-item a span.finished-label > span {
          margin: auto;
          display: block; }
      .stock-list-container .stock-item a > img {
        max-width: 100%; }
    .stock-list-container .stock-item .title {
      margin: 26px 0 16px 0;
      font-size: 15px;
      font-weight: bold; }
    .stock-list-container .stock-item .preview-text {
      margin-bottom: 24px; }
    .stock-list-container .stock-item .detail {
      margin: 20px 0;
      text-align: right;
      color: #4998cc;
      text-decoration: none;
      outline: none;
      font-weight: bold;
      justify-content: flex-end; }
      .stock-list-container .stock-item .detail:hover {
        text-decoration: none; }
      .stock-list-container .stock-item .detail span {
        padding-right: 18px; }

.stock-list .pagination {
  padding: 25px 0; }

.stock-full-page > header h1 {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0; }

.stock-full-page .stock-full-container.is-finished > .header {
  position: relative; }

.stock-full-page .stock-full-container.is-finished .finished-label {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  display: flex;
  width: 200px;
  height: 200px;
  background-color: #8bc870;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  font-size: 18px;
  z-index: 1; }
  .stock-full-page .stock-full-container.is-finished .finished-label > span {
    margin: auto;
    display: block; }

.stock-full-page .stock-full-container.is-finished picture > img {
  opacity: 0.5; }

.stock-full-page .stock-full-container > .header {
  text-align: center; }
  .stock-full-page .stock-full-container > .header.has-gift {
    display: flex;
    align-items: center; }
    .stock-full-page .stock-full-container > .header.has-gift .row:first-child img {
      max-width: 920px; }
    .stock-full-page .stock-full-container > .header.has-gift .row:last-child {
      flex-grow: 1; }

.stock-full-page .stock-full-container > .body {
  margin-top: 40px;
  padding: 20px; }
  .stock-full-page .stock-full-container > .body.has-gift {
    display: flex;
    justify-content: space-around; }
    .stock-full-page .stock-full-container > .body.has-gift .gift-container {
      width: 270px;
      flex-shrink: 0; }
      .stock-full-page .stock-full-container > .body.has-gift .gift-container > header {
        text-align: center; }
        .stock-full-page .stock-full-container > .body.has-gift .gift-container > header p {
          font-weight: bold; }
          .stock-full-page .stock-full-container > .body.has-gift .gift-container > header p:nth-of-type(1) {
            font-size: 15px; }
          .stock-full-page .stock-full-container > .body.has-gift .gift-container > header p:nth-of-type(2) {
            font-size: 18px;
            color: #4998cc; }
      .stock-full-page .stock-full-container > .body.has-gift .gift-container .gift-item-container {
        margin-top: 30px; }
        .stock-full-page .stock-full-container > .body.has-gift .gift-container .gift-item-container::before {
          display: block;
          content: "";
          width: 58px;
          height: 23px;
          margin: 0 auto;
          background-image: url("/build/images/stock-gift-before.png"); }
        .stock-full-page .stock-full-container > .body.has-gift .gift-container .gift-item-container .gift-item {
          border: 2px solid #4998cc;
          padding: 25px;
          text-align: center; }
          .stock-full-page .stock-full-container > .body.has-gift .gift-container .gift-item-container .gift-item .image-container {
            width: 192px;
            height: 192px;
            margin: 0 auto;
            padding-bottom: 10px; }
            .stock-full-page .stock-full-container > .body.has-gift .gift-container .gift-item-container .gift-item .image-container img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              margin: auto; }
    .stock-full-page .stock-full-container > .body.has-gift .description-container {
      padding-left: 40px; }

.stock-full-page .stock-full-container .products > header {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0; }

.stock-full-page .stock-full-container .products .products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative; }

.stock-full-page .stock-full-container .pagination {
  padding: 25px 0; }

.stock .countdown-container {
  font-weight: bold;
  text-align: center;
  font-size: 15px; }
  .stock .countdown-container .countdown-title {
    margin-bottom: 5px; }
  .stock .countdown-container span {
    color: #e89191; }

.contacts-page > header h1 {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0; }

.contacts-page .grid {
  flex-wrap: wrap;
  justify-content: space-between; }
  .contacts-page .grid::after {
    height: 0;
    width: 30%;
    content: ""; }
  .contacts-page .grid .row {
    flex-basis: 30%;
    display: flex;
    margin-bottom: 61px; }
    .contacts-page .grid .row .icon-container {
      flex-basis: 70px;
      flex-shrink: 0; }
    .contacts-page .grid .row .text-container > header {
      font-weight: bold;
      margin-bottom: 21px; }
    .contacts-page .grid .row .text-container p {
      margin-bottom: 15px; }
    .contacts-page .grid .row .text-container a.link {
      color: #4998cc;
      text-decoration: none;
      outline: none;
      font-weight: bold;
      text-decoration: underline; }
      .contacts-page .grid .row .text-container a.link:hover {
        text-decoration: none; }

.modal-contact p {
  text-align: center; }
  .modal-contact p.label {
    margin-bottom: 18px; }

.delivery-page {
  padding-bottom: 40px; }
  .delivery-page > header h1 {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin: 20px 0; }
  .delivery-page-container > header {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin: 20px 0; }
  .delivery-page-container .search-form {
    margin: 20px 0;
    justify-content: center;
    align-items: center; }
    .delivery-page-container .search-form .form-group {
      padding: 0 35px;
      margin-bottom: 0; }
    .delivery-page-container .search-form .custom-select {
      width: 315px; }
      .delivery-page-container .search-form .custom-select .current-value {
        padding: 0 10px; }
      .delivery-page-container .search-form .custom-select .dropdown {
        background: #ececec; }
        .delivery-page-container .search-form .custom-select .dropdown .form-control {
          background: #fff;
          text-align: center; }
      .delivery-page-container .search-form .custom-select ul li {
        padding: 0 10px; }
  .delivery-page-container .deliveries,
  .delivery-page-container .paysystems {
    justify-content: center;
    flex-wrap: wrap; }
    .delivery-page-container .deliveries .row,
    .delivery-page-container .paysystems .row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 232px;
      height: 232px;
      border: 1px solid #d9d9d9; }
      .delivery-page-container .deliveries .row p,
      .delivery-page-container .paysystems .row p {
        text-align: center;
        margin-bottom: 18px; }
  .delivery-page-container .deliveries .row:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.26); }

.modal-delivery .modal-content {
  width: 850px;
  text-align: center; }
  .modal-delivery .modal-content .icon-container {
    margin: 60px 0 33px 0; }
  .modal-delivery .modal-content .label {
    margin-bottom: 55px; }

.brand-page .fixed {
  position: fixed; }

.brand-page > header h1 {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0; }

.brand-page .brand-panel {
  background: #ececec; }
  .brand-page .brand-panel ul {
    display: flex;
    justify-content: center;
    padding: 13px 0; }
    .brand-page .brand-panel ul li a {
      color: #949494;
      text-decoration: none;
      outline: none;
      display: block;
      margin: 12px;
      font-weight: bold;
      font-size: 15px; }
      .brand-page .brand-panel ul li a:hover {
        text-decoration: none; }
      .brand-page .brand-panel ul li a.disabled {
        color: #d7d7d7; }

.brand-page .brand-list .row {
  display: flex;
  border-bottom: 1px solid #ececec;
  padding-top: 100px;
  margin-top: -100px; }
  .brand-page .brand-list .row .letter-key {
    flex-basis: 170px;
    flex-shrink: 0;
    padding: 60px 0 0 35px;
    font-size: 20px;
    color: #949494;
    font-weight: bold; }
  .brand-page .brand-list .row .list {
    display: flex;
    flex-wrap: wrap; }
    .brand-page .brand-list .row .list .brand-item {
      color: #767676;
      text-decoration: none;
      outline: none;
      text-align: center;
      margin: 45px 15px; }
      .brand-page .brand-list .row .list .brand-item:hover {
        text-decoration: none; }
      .brand-page .brand-list .row .list .brand-item .image {
        display: flex;
        width: 100px;
        height: 35px; }
        .brand-page .brand-list .row .list .brand-item .image img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto; }
      .brand-page .brand-list .row .list .brand-item .title {
        display: block;
        margin-top: 10px; }

.search-page-wrapper {
  margin: 20px 0; }
  .search-page-wrapper .search-page-title span {
    font-weight: bold; }
  .search-page-wrapper .search-page-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .search-page-wrapper .search-page-container::after {
      height: 0;
      width: 30%;
      content: ""; }

.site-error {
  padding: 50px 0; }
  .site-error__container {
    display: flex;
    border: 1px solid #e0dede;
    background: #f5f4f4; }
  .site-error__image {
    display: block;
    width: auto;
    max-width: 300px; }
  .site-error__content {
    align-self: center;
    padding: 0 35px; }
  .site-error__message {
    color: #af3734;
    font-size: 28px;
    font-weight: 700; }

* {
  box-sizing: border-box; }

html,
body {
  background-color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  color: #626161;
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%; }

html.is-blocked {
  overflow: hidden;
  touch-action: none; }

.schema {
  display: none; }

@keyframes pagepreloader {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0.5); } }

.site-informer {
  background: #f44336;
  color: #fff;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  height: 22px;
  line-height: 22px;
  padding: 0 15px; }
  .site-informer .icon-wrapper {
    justify-content: center; }
    .site-informer .icon-wrapper span {
      padding: 0 5px; }

.top-menu-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11; }
  .top-menu-container .notification {
    text-align: center;
    padding: 5px; }
    .top-menu-container .notification.warning {
      color: #2731da;
      background: #e8e525; }
    .top-menu-container .notification.danger {
      color: #ffff;
      background: #b62c25;
      border-bottom: 3px dotted #1e1e1e; }

.top-menu {
  background: #1e1e1e;
  height: 38px;
  line-height: 38px;
  color: #fff; }
  .top-menu > .grid > .row:nth-child(2) {
    flex-grow: 1; }
  .top-menu > .grid > .row .city-chooser-container {
    position: relative; }
    .top-menu > .grid > .row .city-chooser-container .city-chooser span {
      font-size: 14px;
      padding-bottom: 2px;
      border-bottom: 1px dotted #ffffff;
      margin-right: 15px; }
      .top-menu > .grid > .row .city-chooser-container .city-chooser span:after {
        position: absolute;
        margin-left: 6px;
        content: "\25BC";
        color: #ffffff;
        float: right;
        font-size: 8px;
        margin-right: 10px; }
      .top-menu > .grid > .row .city-chooser-container .city-chooser span:hover {
        cursor: pointer; }
    .top-menu > .grid > .row .city-chooser-container .popup-city-chooser,
    .top-menu > .grid > .row .city-chooser-container .popup-city-confirm {
      position: absolute;
      margin-top: -4px;
      z-index: 5;
      background: #fff;
      border: 1px solid #b6b6b6;
      font-size: 14px;
      padding: 14px 12px; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-chooser .popup-close,
      .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .popup-close {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer; }
    .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid {
      justify-content: flex-start; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid > .row:last-child {
        padding-left: 15px; }
    .top-menu > .grid > .row .city-chooser-container .popup-city-confirm p {
      color: #626161;
      line-height: normal;
      margin-bottom: 8px; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-confirm p span {
        color: #000000; }
    .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid {
      width: 305px;
      align-items: baseline; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid button {
        width: 44px;
        height: 44px;
        font-size: 14px; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid a {
        text-decoration: none;
        color: #0078c1;
        padding-bottom: 2px;
        border-bottom: 1px dotted #0078c1; }
    .top-menu > .grid > .row .city-chooser-container .popup-city-chooser {
      width: 350px;
      color: #626161; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-chooser > div {
        position: relative; }
        .top-menu > .grid > .row .city-chooser-container .popup-city-chooser > div .v-spinner {
          position: absolute;
          right: 7px;
          top: 1px; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-chooser input[type="text"] {
        border: 1px solid #c0c0c0;
        background: #fff;
        padding: 8px;
        box-shadow: none;
        width: 100%;
        font-size: 13px;
        line-height: normal;
        height: auto;
        box-sizing: border-box;
        margin-bottom: 10px; }
      .top-menu > .grid > .row .city-chooser-container .popup-city-chooser ul {
        display: flex;
        flex-wrap: wrap; }
        .top-menu > .grid > .row .city-chooser-container .popup-city-chooser ul li {
          width: 50%;
          line-height: 25px; }
          .top-menu > .grid > .row .city-chooser-container .popup-city-chooser ul li a {
            text-decoration: none;
            color: #626161;
            display: block;
            white-space: nowrap;
            /* Запрещаем перенос строк */
            overflow: hidden;
            /* Обрезаем все, что не помещается в область */
            text-overflow: ellipsis;
            /* Добавляем многоточие */ }
            .top-menu > .grid > .row .city-chooser-container .popup-city-chooser ul li a:before {
              display: inline-block;
              content: '-';
              padding-right: 5px; }
            .top-menu > .grid > .row .city-chooser-container .popup-city-chooser ul li a:hover {
              cursor: pointer;
              text-decoration: underline; }
  .top-menu > .grid > .row .auth-form-container {
    position: relative; }
    .top-menu > .grid > .row .auth-form-container .profile-link {
      text-align: center; }
      .top-menu > .grid > .row .auth-form-container .profile-link a {
        display: inline-block;
        color: #fff;
        text-decoration: none;
        text-align: center; }
        .top-menu > .grid > .row .auth-form-container .profile-link a span {
          padding-left: 5px; }
    .top-menu > .grid > .row .auth-form-container .modal-sticked {
      height: auto;
      line-height: normal;
      margin-left: -250px;
      left: 50%; }
      .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content {
        width: 500px; }
        .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content .grid {
          margin-top: 28px;
          line-height: normal; }
        .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content a {
          color: #4a4a4a;
          text-decoration: none;
          margin: 0 20px; }
        .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content .help-block-error {
          text-align: center; }
        .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content .loader {
          text-align: center; }
    .top-menu > .grid > .row .auth-form-container .panel-info {
      padding: 15px;
      margin-top: 28px;
      background: #da4040;
      color: #fff; }
      .top-menu > .grid > .row .auth-form-container .panel-info p.title {
        font-weight: bold;
        margin-bottom: 8px; }

.landing-link {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #fff no-repeat fixed 50% 0; }

.layout {
  display: flex;
  flex-direction: column; }
  .layout .page-preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #e8e8e8;
    font-size: 24px;
    font-weight: 300; }
    .layout .page-preloader > img {
      animation: pagepreloader 2s infinite; }
  .layout__header {
    padding-top: 38px;
    flex-shrink: 0; }
    .layout__header .middle-menu > .grid {
      align-items: center;
      height: 140px; }
      .layout__header .middle-menu > .grid .search-wrapper {
        position: relative;
        width: 490px; }
        .layout__header .middle-menu > .grid .search-wrapper .search-input {
          padding: 15px 45px 15px 15px;
          border: 1px solid #c0c0c0;
          box-shadow: none;
          line-height: normal;
          background: #fff;
          height: auto;
          width: 100%;
          box-sizing: border-box; }
        .layout__header .middle-menu > .grid .search-wrapper .search-submit {
          position: absolute;
          top: 14px;
          right: 14px;
          width: 26px;
          height: 26px;
          background: url(/build/images/search_title_icon.png) 0 0 no-repeat; }
          .layout__header .middle-menu > .grid .search-wrapper .search-submit:hover {
            cursor: pointer; }
        .layout__header .middle-menu > .grid .search-wrapper .search-input:focus + .search-submit {
          background-position: 0 -26px; }
      .layout__header .middle-menu > .grid .phones p {
        line-height: 23px; }
        .layout__header .middle-menu > .grid .phones p a {
          text-decoration: none;
          color: inherit; }
      .layout__header .middle-menu > .grid .request-call-container .request-call a {
        color: #626161;
        font-size: 14px;
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1px dotted #626161; }
      .layout__header .middle-menu > .grid .request-call-container .request-call-mobile {
        padding-right: 35px; }
    .layout__header .middle-menu.mobile > .grid {
      justify-content: space-between;
      align-items: center; }
      .layout__header .middle-menu.mobile > .grid .row.actions {
        padding: 0 25px; }
      .layout__header .middle-menu.mobile > .grid .row.mobile-logo {
        flex-grow: 1;
        text-align: center; }
        .layout__header .middle-menu.mobile > .grid .row.mobile-logo .mobile-only {
          display: none; }
        .layout__header .middle-menu.mobile > .grid .row.mobile-logo .desktop-only {
          display: block; }
      .layout__header .middle-menu.mobile > .grid .row p:not(:last-child) {
        margin-bottom: 8px; }
      .layout__header .middle-menu.mobile > .grid .row .request-call-container .request-call-mobile {
        padding-right: 15px; }
  .layout .mobile-menu .swipe-content {
    background: rgba(24, 24, 24, 0.95);
    width: 460px;
    transform: translateX(-460px); }
    .layout .mobile-menu .swipe-content > header {
      margin: 30px 0;
      padding: 0 15px; }
      .layout .mobile-menu .swipe-content > header .logo {
        margin-left: 25px; }
      .layout .mobile-menu .swipe-content > header .swipe-close {
        margin-top: 20px; }
  .layout .mobile-menu .mobile-menu-tabs .tabs {
    padding: 0 15px; }
    .layout .mobile-menu .mobile-menu-tabs .tabs ul li {
      border: 1px solid rgba(101, 101, 101, 0.5);
      text-align: center; }
      .layout .mobile-menu .mobile-menu-tabs .tabs ul li a {
        padding: 11px 0;
        font-size: 16px;
        color: #585858;
        text-decoration: none;
        outline: none; }
        .layout .mobile-menu .mobile-menu-tabs .tabs ul li a:hover {
          text-decoration: none; }
      .layout .mobile-menu .mobile-menu-tabs .tabs ul li.is-active {
        background-color: rgba(101, 101, 101, 0.5); }
        .layout .mobile-menu .mobile-menu-tabs .tabs ul li.is-active a {
          color: #fff; }
  .layout .mobile-menu .mobile-menu-tabs .tabs-content {
    margin-top: 13px; }
    .layout .mobile-menu .mobile-menu-tabs .tabs-content .list-scrollbar {
      background: none;
      overflow-y: scroll; }
    .layout .mobile-menu .mobile-menu-tabs .tabs-content ul li a {
      display: block;
      font-size: 16px;
      padding: 0 15px;
      color: #fff;
      text-decoration: none;
      outline: none; }
      .layout .mobile-menu .mobile-menu-tabs .tabs-content ul li a > span {
        display: block;
        padding: 24px 15px;
        border-bottom: 1px solid #333; }
      .layout .mobile-menu .mobile-menu-tabs .tabs-content ul li a:hover {
        text-decoration: none; }
    .layout .mobile-menu .mobile-menu-tabs .tabs-content .child-list li a {
      padding: 0 25px; }
    .layout .mobile-menu .mobile-menu-tabs .tabs-content .back-link {
      padding: 16px 34px;
      font-size: 16px;
      color: #fff;
      text-decoration: none;
      outline: none; }
      .layout .mobile-menu .mobile-menu-tabs .tabs-content .back-link > span {
        margin-left: 20px; }
      .layout .mobile-menu .mobile-menu-tabs .tabs-content .back-link:hover {
        text-decoration: none; }
  .layout .modal-mobile-search .modal-content {
    width: 460px;
    border: 1px solid #c0c0c0;
    border-top-color: #e5e5e5;
    margin-top: 2px;
    z-index: 9; }
    .layout .modal-mobile-search .modal-content .form-group {
      position: relative; }
      .layout .modal-mobile-search .modal-content .form-group .form-control {
        text-align: left;
        padding: 0 20px; }
      .layout .modal-mobile-search .modal-content .form-group .icon {
        position: absolute;
        top: 13px;
        right: 10px; }
    .layout .modal-mobile-search .modal-content .v-spinner {
      text-align: center; }
    .layout .modal-mobile-search .modal-content .search-result-item {
      text-decoration: none;
      min-height: 60px;
      margin-bottom: 10px;
      padding: 15px 5px;
      background: #fff; }
      .layout .modal-mobile-search .modal-content .search-result-item p {
        color: #727272; }
        .layout .modal-mobile-search .modal-content .search-result-item p.second-name {
          color: #b2b2b2;
          margin-top: 10px; }
      .layout .modal-mobile-search .modal-content .search-result-item.grid {
        justify-content: flex-start;
        align-items: center; }
        .layout .modal-mobile-search .modal-content .search-result-item.grid .row.image {
          flex-basis: 70px;
          text-align: center; }
        .layout .modal-mobile-search .modal-content .search-result-item.grid .row.content {
          flex-basis: calc(100% - 70px); }
  .layout__body {
    flex: 1 0 auto; }
  .layout__footer {
    flex: 0 0 auto;
    background: #ececec;
    padding-top: 30px;
    color: #767676; }
    .layout__footer .footer-blocks {
      position: relative;
      margin-bottom: 12px; }
      .layout__footer .footer-blocks header {
        font-size: 15px;
        margin-bottom: 16px;
        font-weight: 300; }
      .layout__footer .footer-blocks .contacts {
        flex-basis: 200px;
        padding-left: 10px; }
        .layout__footer .footer-blocks .contacts .block {
          line-height: 26px; }
          .layout__footer .footer-blocks .contacts .block:not(:last-child) {
            margin-bottom: 24px; }
          .layout__footer .footer-blocks .contacts .block .title {
            font-weight: 600; }
      .layout__footer .footer-blocks .subscribe > header {
        text-align: center; }
      .layout__footer .footer-blocks .subscribe .form-container {
        width: 500px;
        background-color: #fff;
        margin-bottom: 8px; }
        .layout__footer .footer-blocks .subscribe .form-container form input[type="text"] {
          margin: 11px 0 11px 11px;
          padding-right: 11px;
          flex-grow: 1;
          height: 38px;
          line-height: 38px;
          text-align: center;
          border: none;
          outline: none;
          border-right: 2px solid #ececec; }
        .layout__footer .footer-blocks .subscribe .form-container form button {
          width: 160px;
          color: #767676;
          cursor: pointer; }
        .layout__footer .footer-blocks .subscribe .form-container p {
          background: #ececec;
          font-size: 12px;
          padding-top: 4px;
          text-align: center; }
          .layout__footer .footer-blocks .subscribe .form-container p a {
            color: #626161;
            font-size: inherit; }
      .layout__footer .footer-blocks .subscribe .phones-container > p {
        text-align: center;
        padding: 16px 0; }
      .layout__footer .footer-blocks .subscribe .phones-container .phones {
        text-align: center;
        width: 250px;
        margin: 0 auto;
        flex-wrap: wrap; }
        .layout__footer .footer-blocks .subscribe .phones-container .phones .row {
          flex-basis: 50%;
          color: #626161;
          line-height: 24px; }
          .layout__footer .footer-blocks .subscribe .phones-container .phones .row a {
            color: inherit;
            text-decoration: none; }
      .layout__footer .footer-blocks .subscribe .language-container {
        margin-top: 50px;
        text-align: center; }
        .layout__footer .footer-blocks .subscribe .language-container a {
          color: #767676;
          line-height: 26px;
          padding: 0 17px;
          text-decoration: none; }
          .layout__footer .footer-blocks .subscribe .language-container a:not(:last-child) {
            border-right: 1px solid #b4b4b4; }
          .layout__footer .footer-blocks .subscribe .language-container a.active {
            opacity: 0.4; }
      .layout__footer .footer-blocks .abuse {
        flex-basis: 220px; }
        .layout__footer .footer-blocks .abuse > p {
          margin-bottom: 25px;
          text-align: center; }
        .layout__footer .footer-blocks .abuse .social {
          margin-bottom: 41px;
          justify-content: center;
          display: flex; }
          .layout__footer .footer-blocks .abuse .social__link {
            display: flex;
            width: 35px;
            height: 35px;
            background-size: contain;
            margin-right: 11px; }
            .layout__footer .footer-blocks .abuse .social__link--facebook {
              background-image: url("/build/images/facebook.svg"); }
            .layout__footer .footer-blocks .abuse .social__link--google-plus {
              background-image: url("/build/images/google-plus.svg"); }
            .layout__footer .footer-blocks .abuse .social__link--instagram {
              background-image: url("/build/images/instagram.svg?v=1"); }
            .layout__footer .footer-blocks .abuse .social__link--twitter {
              background-image: url("/build/images/twitter.svg"); }
            .layout__footer .footer-blocks .abuse .social__link--telegram {
              background-image: url("/build/images/telegram.svg"); }
            .layout__footer .footer-blocks .abuse .social__link--pinterest {
              background-image: url("/build/images/pinterest.svg"); }
            .layout__footer .footer-blocks .abuse .social__link.is-last {
              margin-right: 0; }
        .layout__footer .footer-blocks .abuse .btn-inverse {
          color: #767676;
          padding: 25px; }
      .layout__footer .footer-blocks .copyright {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 11px; }
    .layout__footer .footer-menu {
      padding: 16px 0;
      font-size: 13px;
      background-color: #fff; }
      .layout__footer .footer-menu ul {
        display: flex;
        justify-content: flex-start; }
        .layout__footer .footer-menu ul li {
          padding: 0 10px;
          line-height: normal; }
          .layout__footer .footer-menu ul li:not(:last-child) {
            border-right: 1px solid #ececec; }
          .layout__footer .footer-menu ul li a {
            color: #767676;
            text-decoration: none; }
            .layout__footer .footer-menu ul li a:hover {
              text-decoration: underline; }
  .layout .mobile-action {
    position: fixed;
    bottom: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: rgba(141, 141, 141, 0.5);
    display: flex;
    transition: 0.3s all ease; }
    .layout .mobile-action > i.icon {
      margin: auto; }
    .layout .mobile-action.is-active {
      background-color: #ed6c2c; }
    .layout .mobile-action.move-top {
      left: 20px; }
    .layout .mobile-action.hot-actions {
      right: 20px; }
  .layout .mobile-bottom-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 90px; }
    .layout .mobile-bottom-menu > .grid {
      height: inherit;
      background-color: #fff;
      align-items: baseline; }
      .layout .mobile-bottom-menu > .grid > .row {
        text-align: center; }
        .layout .mobile-bottom-menu > .grid > .row a {
          display: flex;
          height: 100%;
          flex-direction: column;
          padding: 15px;
          color: #626161;
          text-decoration: none;
          outline: none; }
          .layout .mobile-bottom-menu > .grid > .row a:hover {
            text-decoration: none; }
          .layout .mobile-bottom-menu > .grid > .row a > span {
            position: relative; }
            .layout .mobile-bottom-menu > .grid > .row a > span > span {
              position: absolute;
              width: 21px;
              height: 21px;
              background: #ed6c2c;
              color: #fff;
              line-height: 21px;
              border-radius: 100%;
              margin-left: 6px;
              margin-top: -2px; }
          .layout .mobile-bottom-menu > .grid > .row a p {
            padding: 12px 0 19px 0; }
  .layout .seo-text-container {
    margin-bottom: 88px; }
    .layout .seo-text-container .seo-text {
      color: #959595;
      column-count: 2;
      column-gap: 35px;
      overflow: hidden;
      overflow-y: hidden; }
      .layout .seo-text-container .seo-text:after {
        display: block;
        content: '';
        clear: both; }
      .layout .seo-text-container .seo-text a {
        color: #4998cc;
        text-decoration: none;
        outline: none; }
        .layout .seo-text-container .seo-text a:hover {
          text-decoration: none; }
      .layout .seo-text-container .seo-text ol, .layout .seo-text-container .seo-text ul, .layout .seo-text-container .seo-text dl {
        /* IE7: reset rtl list margin. (#7334) */
        *margin-right: 0px;
        /* preserved spaces for list items with text direction other than the list. (#6249,#8049)*/
        padding: 0 40px; }
      .layout .seo-text-container .seo-text ul {
        list-style-type: disc; }
      .layout .seo-text-container .seo-text ol {
        list-style-type: decimal; }
      .layout .seo-text-container .seo-text h1, .layout .seo-text-container .seo-text h2, .layout .seo-text-container .seo-text h3, .layout .seo-text-container .seo-text h4, .layout .seo-text-container .seo-text h5, .layout .seo-text-container .seo-text h6 {
        font-weight: normal;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 20px;
        font-size: inherit; }
        .layout .seo-text-container .seo-text h1:not(:first-child), .layout .seo-text-container .seo-text h2:not(:first-child), .layout .seo-text-container .seo-text h3:not(:first-child), .layout .seo-text-container .seo-text h4:not(:first-child), .layout .seo-text-container .seo-text h5:not(:first-child), .layout .seo-text-container .seo-text h6:not(:first-child) {
          margin-top: 20px; }
      .layout .seo-text-container .seo-text h2 {
        font-size: 20px; }
      .layout .seo-text-container .seo-text h3 {
        font-size: 18px; }
      .layout .seo-text-container .seo-text i, .layout .seo-text-container .seo-text em {
        font-style: italic; }
      .layout .seo-text-container .seo-text .no-margin {
        margin: 0 !important; }
      .layout .seo-text-container .seo-text.is-active {
        height: auto; }
    .layout .seo-text-container .overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
      /* IE6-9 */ }
    .layout .seo-text-container .show-more-container {
      position: relative;
      z-index: 0;
      padding: 31px 0 0;
      display: flex;
      justify-content: center; }
      .layout .seo-text-container .show-more-container a {
        display: block;
        position: absolute;
        color: #959595; }
    .layout .seo-text-container .seo-price {
      margin-top: 20px; }

.modal-request-call .modal-content {
  width: 430px; }
  .modal-request-call .modal-content p.result {
    text-align: center; }
  .modal-request-call .modal-content .phones {
    margin-bottom: 22px; }
    .modal-request-call .modal-content .phones > a {
      background: #fff;
      justify-content: center;
      margin-bottom: 8px;
      font-size: 16px;
      padding: 10px 0;
      color: #626161;
      text-decoration: none;
      outline: none; }
      .modal-request-call .modal-content .phones > a:hover {
        text-decoration: none; }
      .modal-request-call .modal-content .phones > a i.icon {
        margin-right: 26px; }

.modal-abuse .modal-content {
  width: 460px; }
  .modal-abuse .modal-content .abuse-type-container label {
    line-height: 45px;
    margin-top: 8px; }
  .modal-abuse .modal-content textarea {
    background: #fff;
    color: #6d6d6d;
    text-align: left; }

.alert {
  padding: 25px;
  margin-bottom: 20px; }
  .alert-danger {
    background: #ff3633;
    color: #fff; }
  .alert-success {
    background: #00ba2f;
    color: #fff; }
  .alert-info {
    background: #0b93d5;
    color: #fff; }
  .alert > header {
    font-size: 18px; }
  .alert pre {
    margin: 10px 0; }

.grecaptcha-badge {
  display: none; }

[v-cloak] {
  display: none; }

.editor-content:after {
  display: block;
  content: '';
  clear: both; }

.editor-content a {
  color: #4998cc;
  text-decoration: none;
  outline: none; }
  .editor-content a:hover {
    text-decoration: none; }

.editor-content ol, .editor-content ul, .editor-content dl {
  /* IE7: reset rtl list margin. (#7334) */
  *margin-right: 0px;
  /* preserved spaces for list items with text direction other than the list. (#6249,#8049)*/
  padding: 0 40px; }

.editor-content ul {
  list-style-type: disc; }

.editor-content ol {
  list-style-type: decimal; }

.editor-content h1, .editor-content h2, .editor-content h3, .editor-content h4, .editor-content h5, .editor-content h6 {
  font-weight: normal;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: inherit; }
  .editor-content h1:not(:first-child), .editor-content h2:not(:first-child), .editor-content h3:not(:first-child), .editor-content h4:not(:first-child), .editor-content h5:not(:first-child), .editor-content h6:not(:first-child) {
    margin-top: 20px; }

.editor-content h2 {
  font-size: 20px; }

.editor-content h3 {
  font-size: 18px; }

.editor-content i, .editor-content em {
  font-style: italic; }

.VueCarousel-navigation-button {
  background: url("/build/images/vue-slider-arrows.png") no-repeat 0 0;
  width: 14px;
  height: 24px;
  text-indent: -9999px;
  top: 40%; }
  .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    background-position: -1px -1px; }
  .VueCarousel-navigation-button.VueCarousel-navigation-next {
    background-position: -20px -1px; }
  .VueCarousel-navigation-button.VueCarousel-navigation--disabled {
    opacity: 0.2; }

.VueCarousel-pagination {
  float: none !important; }

.help-block--error {
  color: red; }

.help-block--success {
  color: green; }

.responsive {
  width: auto;
  max-width: 100%; }

.products__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .products__content::after {
    height: 0;
    width: 30%;
    content: ""; }

.error {
  background: #cece; }
  .error:not(:empty) {
    padding: 25px; }
  .error__title {
    font-weight: bold; }

.VueCarousel-slide .product-wrapper {
  width: calc(100% - 15px); }

/* Адаптивность */
.hidden-xx-up {
  display: none !important; }

@media (max-width: 319px) {
  .hidden-xx-down {
    display: none !important; } }

@media (min-width: 320px) {
  .hidden-xs-up {
    display: none !important; } }

@media (max-width: 459px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 460px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1169px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1170px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

@media (max-width: 1169px) {
  .slider-content {
    margin: 12px auto; }
    .slider-content .slider-wrapper .VueCarousel-slide img {
      max-width: 100%;
      width: auto; } }

@media (max-width: 991px) {
  .slider-content {
    margin: 0; }
    .slider-content .slider-wrapper {
      max-width: inherit; }
      .slider-content .slider-wrapper .thumbnails ul > li {
        padding: 0 5px; } }

@media (max-width: 767px) {
  .slider-content .slider-wrapper .VueCarousel-pagination {
    display: inherit; }
  .slider-content .slider-wrapper .thumbnails {
    display: none; } }

@media (min-width: 768px) {
  .modal-sticked {
    position: absolute;
    z-index: auto;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto; }
    .modal-sticked .modal-background {
      position: fixed;
      z-index: 10; }
    .modal-sticked .modal-content {
      z-index: 20;
      position: relative; } }

@media (max-width: 991px) {
  .modal .modal-content .modal-close {
    top: 7px;
    right: 15px; }
    .modal .modal-content .modal-close .close {
      width: 35px;
      height: 35px; } }

@media (max-width: 767px) {
  .modal .modal-content {
    width: 100% !important;
    height: 100% !important;
    overflow: auto; }
    .modal .modal-content .modal-title {
      padding-top: 0;
      padding-bottom: 10px; } }

@media (min-width: 460px) {
  .product-item__hidden .btn-small {
    font-size: 13px !important; }
  .product-wrapper .product-item .label-offer-container {
    position: absolute;
    top: 5px;
    left: 5px;
    justify-content: center;
    width: 100%; }
    .product-wrapper .product-item .label-offer-container .label {
      color: #fff;
      padding: 6px 19px;
      font-weight: 300; }
      .product-wrapper .product-item .label-offer-container .label.label-discount.black-friday {
        padding-left: 5px;
        background: url("/build/images/bf_discount_bg.png") no-repeat;
        height: 80px;
        width: 80px;
        line-height: 80px;
        font-weight: bold;
        font-size: 19px; } }

@media (max-width: 991px) {
  .product-item__names {
    height: auto; }
  .product-item__hidden .buy-container .btn-buy {
    padding: 12px 10px; } }

@media (max-width: 767px) {
  .product-wrapper {
    width: 45%;
    min-height: auto; }
  .product-item__background {
    border-width: 4px; }
  .product-item__image {
    height: 220px; }
  .product-item__alias-name {
    font-size: 11px; }
  .product-item__hidden .buy-container-wrapper {
    width: 80%; }
  .product-item__hidden .buy-container {
    width: 100%;
    margin: 8px 0; }
    .product-item__hidden .buy-container .icon-wrapper {
      display: none; }
  .product-item__hidden .available-reminder-container .btn {
    width: 100%; }
  .product-item--average .product-item__image {
    height: 140px; }
  .product-bonus {
    display: none; }
  .product-price {
    font-size: 14px; }
  .offers-chooser {
    margin-top: 7px; }
  .product-wrapper .product-item .label-offer-container .label {
    font-size: 14px;
    padding: 9px 19px; }
  .product-wrapper .product-item .rating-widget {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around; }
    .product-wrapper .product-item .rating-widget a {
      margin-left: 0; }
  .modal-offer-choose .modal-content {
    padding: 12px 16px; }
    .modal-offer-choose .modal-content .modal-close {
      top: 13px; }
    .modal-offer-choose .modal-content .modal-title {
      flex-basis: 100%;
      flex-wrap: wrap;
      padding: 0; }
      .modal-offer-choose .modal-content .modal-title .row {
        flex-basis: inherit; }
        .modal-offer-choose .modal-content .modal-title .row.names {
          flex-basis: 94%; }
          .modal-offer-choose .modal-content .modal-title .row.names h3 {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0; }
        .modal-offer-choose .modal-content .modal-title .row.info {
          padding-top: 5px;
          margin-right: 0;
          display: flex;
          justify-content: inherit; }
          .modal-offer-choose .modal-content .modal-title .row.info .stars {
            order: -1; }
    .modal-offer-choose .modal-content .chooser {
      flex-wrap: wrap; }
      .modal-offer-choose .modal-content .chooser .preview,
      .modal-offer-choose .modal-content .chooser .list-horizontal {
        flex-basis: 100%; }
      .modal-offer-choose .modal-content .chooser .list-horizontal ul {
        padding: 10px 7px; }
      .modal-offer-choose .modal-content .chooser .preview {
        height: 269px;
        text-align: center; } }

@media (max-width: 459px) {
  .product-wrapper .product-item.is-active .hidden {
    height: auto; }
  .product-wrapper .product-item .hidden {
    height: 35px; }
    .product-wrapper .product-item .hidden .offers-chooser {
      margin-top: 6px; }
  .modal-offer-choose .modal-content .modal-title .row.names h3 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0; }
  .modal-offer-choose .modal-content .modal-title .row.names h4 {
    font-size: 11px; }
  .modal-offer-choose .modal-content .chooser .preview {
    height: 180px; }
    .modal-offer-choose .modal-content .chooser .preview img {
      max-width: 118px; } }

@media (min-width: 1170px) {
  .modal-cart .modal-content > .vb .vb-content {
    width: calc(100% + 17px) !important; } }

@media (min-width: 768px) {
  .modal-cart .modal-content {
    width: 731px; } }

@media (max-width: 991px) {
  .modal-cart .cart-informer-content .scroll-wrapper .vb-dragger {
    right: 0 !important; }
  .modal-cart .cart-informer-content .total-container {
    font-size: 15px; }
  .modal-cart .cart-informer-content .progress-bar-container {
    font-size: 14px; }
  .modal-cart .products-viewed .cart-product-viewed .names {
    width: calc(100% - 170px); }
    .modal-cart .products-viewed .cart-product-viewed .names .primary-name a,
    .modal-cart .products-viewed .cart-product-viewed .names .secondary-name {
      font-size: 14px; }
  .modal-cart .products-viewed .cart-product-viewed .actions .price-container {
    font-size: 15px; }
  .modal-cart .products-viewed .cart-product-viewed .actions .btn-small {
    font-size: 13px; } }

@media (max-width: 767px) {
  .modal-cart {
    width: 100%; }
    .modal-cart .cart-informer-content .cart-informer-block .grid:not(.no-wrap) {
      flex-wrap: wrap; }
    .modal-cart .cart-informer-content .cart-informer-block .grid .image a {
      width: 90px;
      height: 90px; }
    .modal-cart .cart-informer-content .cart-informer-block .grid .name-container {
      width: calc(100% - 120px); }
    .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container {
      margin-left: 120px; }
      .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container a {
        font-weight: 600; }
    .modal-cart .cart-informer-content .total-container {
      display: flex;
      flex-direction: column-reverse; }
      .modal-cart .cart-informer-content .total-container .grid {
        flex-wrap: wrap; }
        .modal-cart .cart-informer-content .total-container .grid.info {
          flex-direction: column-reverse; }
          .modal-cart .cart-informer-content .total-container .grid.info .discounts-wrapper {
            display: flex;
            flex-direction: column-reverse; }
        .modal-cart .cart-informer-content .total-container .grid .row {
          width: 100%; }
          .modal-cart .cart-informer-content .total-container .grid .row .btn {
            margin: 5px 0; }
    .modal-cart .cart-informer-content .progress-bar-container {
      font-size: 15px; }
      .modal-cart .cart-informer-content .progress-bar-container .progress-bar {
        width: 100%; }
    .modal-cart .products-viewed .cart-product-viewed {
      display: block; }
      .modal-cart .products-viewed .cart-product-viewed:after {
        display: block;
        content: '';
        clear: both; }
      .modal-cart .products-viewed .cart-product-viewed .image {
        float: left; }
        .modal-cart .products-viewed .cart-product-viewed .image a {
          width: 120px; }
      .modal-cart .products-viewed .cart-product-viewed .names {
        float: left;
        width: calc(100% - 120px); }
      .modal-cart .products-viewed .cart-product-viewed .actions {
        float: left;
        padding: 0; }
        .modal-cart .products-viewed .cart-product-viewed .actions > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center; }
        .modal-cart .products-viewed .cart-product-viewed .actions .price-container {
          width: 100%;
          padding: 14px 0; }
        .modal-cart .products-viewed .cart-product-viewed .actions .offers-chooser {
          flex-basis: 50%; }
          .modal-cart .products-viewed .cart-product-viewed .actions .offers-chooser .extended {
            width: 193px; }
        .modal-cart .products-viewed .cart-product-viewed .actions .btn-buy {
          width: 38px;
          height: 32px;
          margin-left: 20px;
          padding: 7px; }
  .modal-average .modal-content .modal-title {
    padding: 28px 16px; }
  .modal-average .controls {
    flex-wrap: wrap;
    padding-top: 0; }
    .modal-average .controls__button {
      width: 100%; }
      .modal-average .controls__button--last {
        order: -1; } }

@media (max-width: 459px) {
  .modal-cart .cart-informer-content .cart-informer-block .grid .quantity-container {
    margin-left: 0; }
  .modal-cart .products-viewed .cart-product-viewed .actions {
    width: 100%; } }

@media (max-width: 767px) {
  .review-item__name {
    flex-basis: 140px; }
  .review-item__header {
    flex-wrap: wrap; }
  .review-item__recommendations {
    order: 3;
    margin-top: 10px;
    width: 100%; } }

@media (max-width: 459px) {
  .review-item__name {
    flex-basis: 140px; }
  .review-item__recommendations-content {
    justify-content: space-between; }
  .review-item__recommendations-row {
    padding: 0; }
  .review-item__parameters {
    flex-wrap: wrap; }
  .review-item__parameters-row {
    width: 100%; }
  .review-item__footer .grid {
    flex-wrap: wrap;
    justify-content: space-between; }
  .review-item__recommendation-label {
    order: 3;
    text-align: center;
    margin-top: 10px;
    padding-right: 0; } }

@media (max-width: 767px) {
  .answer-form .grid {
    flex-wrap: wrap; }
    .answer-form .grid .row {
      flex-basis: 100%; }
    .answer-form .grid.actions .row:first-child {
      order: 1; } }

@media (max-width: 991px) {
  .product-informer {
    margin: 0; } }

@media (max-width: 991px) {
  .breadcrumbs {
    padding: 0 15px; } }

@media (max-width: 459px) {
  .breadcrumbs ul li:not(:last-child) {
    display: none; } }

@media (max-width: 459px) {
  .answer-item > header {
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  .stock-single-list .VueCarousel-slide {
    padding: 0 15px; } }

@media (max-width: 1169px) {
  .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    left: 15px; }
  .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-next {
    right: 15px; }
  .slider-content .VueCarousel .VueCarousel-navigation-button {
    display: none; } }

@media (max-width: 767px) {
  .main-tabs .tabs-content .VueCarousel .VueCarousel-wrapper {
    margin: 0px 0 -150px 0;
    padding: 0px 0px 150px 0; }
    .main-tabs .tabs-content .VueCarousel .VueCarousel-wrapper .VueCarousel-inner {
      max-height: 450px; }
  .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button {
    background: url("/build/images/vue-slider-arrows.png") no-repeat 0 0;
    width: 10px;
    height: 18px;
    text-indent: -9999px;
    top: 40%; }
    .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-prev {
      background-position: 0px -31px; }
    .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation-next {
      background-position: -20px -31px; }
    .main-tabs .tabs-content .VueCarousel .VueCarousel-navigation-button.VueCarousel-navigation--disabled {
      opacity: 0.2; } }

@media (min-width: 992px) {
  .category-wrapper[v-cloak] {
    display: flex !important; }
    .category-wrapper[v-cloak] aside .child-categories {
      height: 117px;
      margin: 14px 21px;
      background-image: linear-gradient(100deg, rgba(236, 236, 236, 0), rgba(236, 236, 236, 0.5) 50%, rgba(236, 236, 236, 0) 100%), linear-gradient(white 14px, transparent 0);
      background-repeat: repeat-y;
      background-size: 117px 14px, 269px 30px;
      background-position: -150% 0, 5px 5px;
      animation: loading-categories 1.5s infinite; }
    .category-wrapper[v-cloak] .category-description-wrapper {
      width: calc(100% - 300px - 14px); }
  .category-wrapper aside .filter-group-wrapper.is-loading {
    height: 300px;
    margin: 24px;
    background-image: linear-gradient(100deg, rgba(236, 236, 236, 0), rgba(236, 236, 236, 0.5) 50%, rgba(236, 236, 236, 0) 80%), linear-gradient(white 24px, transparent 0), linear-gradient(white 19px, transparent 0), linear-gradient(white 10px, transparent 0), linear-gradient(white 19px, transparent 0), linear-gradient(white 10px, transparent 0), linear-gradient(white 19px, transparent 0), linear-gradient(white 10px, transparent 0);
    background-repeat: repeat-y;
    background-size: 300px 150px, 150px 150px, 19px 150px, 170px 150px, 19px 150px, 170px 150px, 19px 150px, 170px 150px;
    background-position: 350% 0, 5px 5px, 5px 39px, 29px 43.75px, 5px 67px, 29px 71.75px, 5px 95px, 29px 99.75px;
    animation: loading-filters 1.5s infinite; } }

@media (max-width: 1169px) {
  .category-wrapper {
    padding: 0 15px; }
    .category-wrapper > section {
      width: 100%; }
      .category-wrapper > section .products-container {
        justify-content: space-around; } }

@media (max-width: 991px) {
  .category-wrapper[v-cloak] {
    display: block !important; }
  .category-wrapper aside {
    display: none; }
  .mobile-filter .swipe-content,
  .mobile-sort .swipe-content {
    width: 460px;
    transform: translateX(-460px); }
  .mobile-filter .swipe-content .child-categories ul li a {
    padding: 20px 33px 20px 21px;
    font-size: 16px; }
    .mobile-filter .swipe-content .child-categories ul li a.important {
      color: #0078c1; }
  .mobile-filter .swipe-content .filter-group .filter-title,
  .mobile-filter .swipe-content .filter-price .filter-title {
    font-size: 16px; }
  .mobile-filter .swipe-content .filter-group .filter-options ul li a,
  .mobile-filter .swipe-content .filter-price .filter-options ul li a {
    font-size: 16px;
    padding: 16px; }
  .reviews-last {
    padding: 0 15px; } }

@media (max-width: 767px) {
  .category-wrapper > section .sort-panel a.filter-btn, .category-wrapper > section .sort-panel a.sort-btn {
    text-align: center;
    width: 50%;
    justify-content: center;
    padding: 14px 0;
    font-size: 16px; }
  .category-wrapper > section .filter-panel {
    padding: 0 35px;
    justify-content: space-between; }
    .category-wrapper > section .filter-panel .filter-item {
      padding: 12px 30px 12px 23px;
      width: 45%;
      text-align: center;
      font-size: 15px;
      margin-right: 0; }
      .category-wrapper > section .filter-panel .filter-item span.close {
        right: 15px;
        top: 12px; }
  .mobile-filter .swipe-content,
  .mobile-sort .swipe-content {
    width: 100%;
    transform: translateX(-100%); } }

@media (max-width: 767px) {
  .category-wrapper .category-banner {
    width: 50%;
    padding: 0; }
    .category-wrapper .category-banner img {
      max-width: 100%; }
  .category-wrapper > section .sort-panel a.filter-btn, .category-wrapper > section .sort-panel a.sort-btn {
    padding: 14px 0;
    font-size: 16px; }
  .category-wrapper > section .filter-panel {
    padding: 0 10px; }
    .category-wrapper > section .filter-panel .filter-item {
      width: 48%; }
  .category-wrapper .category-description-wrapper .category-description .short-description {
    display: none; } }

@media (max-width: 1169px) {
  .product-page {
    padding: 30px 15px 0 15px; }
    .product-page > .grid .row.actions .info .row {
      width: 100%; }
      .product-page > .grid .row.actions .info .row:nth-of-type(2n) a.icon-wrapper {
        justify-content: flex-start; }
    .product-page > .grid .row.actions .info .wish,
    .product-page > .grid .row.actions .info .ask {
      order: 2; }
    .product-page .reviews > .grid .row.comments {
      padding-left: 30px; } }

@media (max-width: 991px) {
  .product-page > .grid {
    flex-wrap: wrap;
    justify-content: space-around; }
    .product-page > .grid .row.properties {
      width: 100%;
      order: 2; }
      .product-page > .grid .row.properties .properties-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        .product-page > .grid .row.properties .properties-list > div {
          width: 50%; }
      .product-page > .grid .row.properties .brand-logo {
        margin: 30px 0; }
    .product-page > .grid .row.actions {
      width: 33%; }
      .product-page > .grid .row.actions .buy-container button {
        width: 100%; }
    .product-page > .grid .row.image {
      width: 50%; }
  .product-page .scroll-spy-container {
    padding-right: 0; } }

@media (min-width: 992px) and (max-width: 1169px) {
  .product-page .reviews .stats .rating-container .rating .progress-bar {
    width: 150px; } }

@media (max-width: 767px) {
  .product-page > .grid .row.actions, .product-page > .grid .row.image {
    width: 100%; }
  .product-page > .grid .row.image {
    margin-right: 0; }
  .product-page > .grid .row.actions .info {
    margin: 10px 0; }
    .product-page > .grid .row.actions .info .row {
      width: 50%; }
      .product-page > .grid .row.actions .info .row.code {
        text-align: right; }
      .product-page > .grid .row.actions .info .row.ask a.icon-wrapper {
        justify-content: flex-end; }
  .product-page > .grid .row.properties .brand-logo {
    text-align: center; }
  .product-page .reviews > .grid {
    flex-wrap: wrap;
    justify-content: center; }
    .product-page .reviews > .grid .row.comments {
      padding-left: 0; }
    .product-page .reviews > .grid .row .rating-container {
      margin-bottom: 20px; }
  .product-page h5 {
    text-align: center; }
  .product-page .description-title,
  .product-page .scroll-spy-container h4 {
    text-align: center; }
  .modal-add-review .modal-content form .grid {
    flex-wrap: wrap; }
    .modal-add-review .modal-content form .grid .row.form-group {
      flex-basis: 100%; }
    .modal-add-review .modal-content form .grid.actions > .row:last-child {
      margin: 20px 0;
      flex-basis: 100%; }
  .modal-add-review .modal-content form .extra-data .row {
    flex-basis: auto; }
  .modal-zoom .modal-content > .header {
    flex-wrap: wrap; }
    .modal-zoom .modal-content > .header .actions {
      margin-top: 10px; }
  .modal-zoom .modal-content .zoomer {
    width: 460px;
    height: 460px; }
  .modal-zoom .modal-content .zoomer-carousel {
    width: auto; } }

@media (max-width: 459px) {
  .product-page > .grid .row.image .VueCarousel {
    height: 269px; }
  .product-page > .grid .row.actions .info .row.code {
    width: 100%;
    text-align: left;
    margin: 10px 0; }
  .product-page > .grid .row.properties .properties-list > div {
    width: 100%; }
  .modal-zoom .modal-content .zoomer {
    width: 320px;
    height: 320px; } }

@media (min-width: 768px) {
  #root.order-mode .layout__header .top-menu > .grid > .row .auth-form-container .modal-sticked {
    left: auto;
    margin-left: -200px; } }

@media (max-width: 991px) {
  #root.order-mode .layout__header .middle-menu > .grid {
    justify-content: center; }
    #root.order-mode .layout__header .middle-menu > .grid > .row.logo {
      padding: 0 100px;
      flex-grow: 0; }
    #root.order-mode .layout__header .middle-menu > .grid > .row.request-call {
      padding-right: 0; }
  #root.order-mode .order-page .vue-form-wizard .wizard-tab-content {
    flex-wrap: wrap; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container {
      display: block;
      width: 100%; }
      #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .cart-items {
        display: none; }
      #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .total-container .cart-carousel {
        display: block;
        padding: 0 30px;
        background-color: #fff; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container {
      width: 100%; } }

@media (max-width: 767px) {
  #root.order-mode .layout__header .middle-menu > .grid {
    justify-content: space-around; }
    #root.order-mode .layout__header .middle-menu > .grid > .row.logo {
      padding: 0; }
  #root.order-mode .top-menu > .grid > .row .city-chooser-container .city-chooser {
    margin-left: 15px; }
    #root.order-mode .top-menu > .grid > .row .city-chooser-container .city-chooser span {
      border-bottom: none; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav .wizard-icon-circle {
    height: auto; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li a {
    padding: 15px 25px;
    display: flex; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li span.stepTitle {
    display: none; }
  #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center {
    flex-wrap: wrap; }
    #root.order-mode .order-page .vue-form-wizard .wizard-tab-content .wizard-tab-container .grid.center > .row {
      width: 100%; }
  #root.order-mode .order-page form .actions .row:first-child {
    order: 1; }
    #root.order-mode .order-page form .actions .row:first-child .icon-wrapper {
      justify-content: center; } }

@media (max-width: 459px) {
  #root.order-mode .top-menu > .grid > .row:first-child {
    flex-basis: 100%; }
  #root.order-mode .layout__header .middle-menu .logo img {
    max-width: 180px; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li a {
    padding: 5px 15px;
    display: flex; }
  #root.order-mode .order-page .vue-form-wizard .wizard-navigation .wizard-nav li:not(:last-child):after {
    margin: auto 15px; } }

@media (min-width: 992px) and (max-width: 1169px) {
  .profile .menu-toggle-container {
    margin-bottom: 16px; }
    .profile .menu-toggle-container .toggle-btn {
      display: none; }
    .profile .menu-toggle-container ul.nav {
      display: flex;
      justify-content: space-around;
      padding: 0;
      background: #fff;
      border-bottom: 1px solid #ebebeb; }
      .profile .menu-toggle-container ul.nav li:not(:last-child) {
        margin-bottom: 0; }
      .profile .menu-toggle-container ul.nav li.active {
        position: relative;
        margin-bottom: -1px;
        border-bottom: 1px solid #afafaf; }
        .profile .menu-toggle-container ul.nav li.active::after {
          position: absolute;
          display: block;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 6.5px 0 6.5px;
          border-color: #afafaf transparent transparent transparent;
          left: 50%;
          margin-left: -4px; }
      .profile .menu-toggle-container ul.nav li span {
        text-align: center; } }

@media (max-width: 991px) {
  .profile {
    padding: 0 15px; }
    .profile .menu-toggle-container {
      margin-bottom: 16px;
      position: relative; }
      .profile .menu-toggle-container .toggle-btn {
        display: flex;
        width: 100%;
        height: 61px; }
        .profile .menu-toggle-container .toggle-btn i.icon {
          display: block;
          margin: auto; }
      .profile .menu-toggle-container .toggle-content {
        position: absolute;
        width: 100%;
        display: none;
        z-index: 1; }
        .profile .menu-toggle-container .toggle-content.is-show {
          display: block; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .id {
      flex-basis: 15%; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .date {
      flex-basis: 12%; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel .completed {
      flex-basis: 22%; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status:not(:last-child):after {
      margin: auto 0; }
  .wishlist-container .offers-list-container .offers-list {
    justify-content: space-around; }
    .wishlist-container .offers-list-container .offers-list .offer-item {
      width: 40%; } }

@media (max-width: 767px) {
  .profile .panel .icon-wrapper span.label {
    display: none; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel {
    flex-wrap: wrap;
    height: auto;
    padding: 0 25px; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel > div {
      padding: 10px 0;
      flex-basis: 33% !important; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel > div span {
        line-height: 22px; }
        .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel > div span.label {
          display: block !important;
          font-weight: bold; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .grid {
    flex-wrap: wrap; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .delivery .grid .row {
      width: 100%; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses {
    flex-wrap: wrap;
    padding: 10px; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses:empty {
      display: none; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status {
      flex-wrap: wrap;
      width: 100%; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status:not(.is-completed) {
        display: none; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner {
        width: 100%;
        display: flex;
        align-items: center; }
        .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .date {
          margin-bottom: 0;
          padding-right: 15px; }
        .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .icon-container {
          width: 90px;
          height: auto;
          flex-shrink: 0; }
          .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .icon-container .icon-order-checked {
            display: none; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status:not(:last-child):after {
        margin: 0 0 0 42px;
        content: "\2022"; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.table-view {
    display: none; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view {
    display: block;
    padding: 17px; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view > header {
      font-weight: bold;
      margin-bottom: 20px; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item:last-child .separator {
      display: none; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .info {
      display: flex;
      align-items: center; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .info .image {
        flex-shrink: 0; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .info .name {
        padding-left: 10px;
        flex-grow: 1; }
        .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .info .name a {
          color: #4998cc;
          text-decoration: none;
          outline: none; }
          .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .info .name a:hover {
            text-decoration: none; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .price {
      display: flex;
      justify-content: space-between;
      margin: 12px 0; }
      .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .price span {
        font-weight: bold; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .cart-items.grid-view .cart-item .separator {
      width: 80%;
      margin: 20px auto;
      height: 0;
      border-bottom: 1px solid #e0e0e0; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .grid .row:first-child {
    display: none; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment {
    flex-wrap: wrap; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment .form-control {
      height: 100px; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .rating-comment button {
      width: 100%; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid {
    flex-wrap: wrap;
    justify-content: center; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .actions-container .grid .gifts {
      width: 100%; }
  .profile .account-container form .grid .row,
  .profile .password-container form .grid .row {
    flex-basis: 100%; }
  .profile .wishlist-container .actions {
    flex-wrap: wrap; }
    .profile .wishlist-container .actions .askbuy {
      width: 100%;
      order: 2;
      margin: 15px 0; }
      .profile .wishlist-container .actions .askbuy .icon-wrapper {
        justify-content: center; }
  .profile .wishlist-container .offers-list-container .offers-list {
    padding: 0 30px;
    justify-content: center; }
    .profile .wishlist-container .offers-list-container .offers-list .offer-item {
      width: 100%; }
  .profile .addressbook-container .profiles-list-container .profiles-list {
    flex-wrap: wrap; }
    .profile .addressbook-container .profiles-list-container .profiles-list .profile-item {
      width: 100%; }
  .profile .addressbook-container .profiles-list-select {
    padding: 0 20px;
    margin-top: 10px; }
    .profile .addressbook-container .profiles-list-select .form-group label {
      margin-bottom: 5px; }
    .profile .addressbook-container .profiles-list-select .custom-select {
      background: #fff; }
      .profile .addressbook-container .profiles-list-select .custom-select .current-value {
        padding: 0 10px; }
      .profile .addressbook-container .profiles-list-select .custom-select .dropdown {
        background-color: #fff;
        border: 1px solid #e9e9e9; }
        .profile .addressbook-container .profiles-list-select .custom-select .dropdown .form-group {
          padding: 7px; }
          .profile .addressbook-container .profiles-list-select .custom-select .dropdown .form-group .form-control {
            border: 2px solid #ececec; }
        .profile .addressbook-container .profiles-list-select .custom-select .dropdown ul {
          background: none; }
          .profile .addressbook-container .profiles-list-select .custom-select .dropdown ul li {
            padding: 0 10px;
            color: #767676; }
            .profile .addressbook-container .profiles-list-select .custom-select .dropdown ul li:hover {
              background-color: #dfdfdf; }
            .profile .addressbook-container .profiles-list-select .custom-select .dropdown ul li.is-active {
              background-color: #daeaf5; }
        .profile .addressbook-container .profiles-list-select .custom-select .dropdown .empty-result {
          padding: 5px 20px; }
    .profile .addressbook-container .profiles-list-select .btn .icon-wrapper {
      justify-content: center; }
      .profile .addressbook-container .profiles-list-select .btn .icon-wrapper span {
        padding-right: 10px; }
    .profile .addressbook-container .profiles-list-select .btn.btn-link .icon-wrapper span {
      font-weight: bold; }
  .profile .addressbook-container .edit-form .grid {
    flex-wrap: wrap; }
    .profile .addressbook-container .edit-form .grid .row {
      width: 100%; }
  .profile .addressbook-container .edit-form form .form-group .btn-block {
    width: 100%; }
  .profile .popup {
    margin-top: 0; }
  .bonus-history__content {
    flex-wrap: wrap;
    padding: 0 15px; }
  .bonus-history__group {
    width: 50%;
    margin-bottom: 10px; } }

@media (max-width: 459px) {
  .profile .panel {
    padding: 0 20px; }
    .profile .panel .total-container span.label {
      display: block;
      font-weight: bold; }
    .profile .panel .logout-container span {
      display: none; }
  .profile .filters-select {
    padding: 0 20px; }
    .profile .filters-select .custom-select {
      margin-top: 10px; }
      .profile .filters-select .custom-select .current-value {
        background: #fff;
        padding: 0 10px; }
      .profile .filters-select .custom-select .dropdown {
        background-color: #fff;
        border: 1px solid #e9e9e9; }
        .profile .filters-select .custom-select .dropdown .form-group {
          padding: 7px; }
          .profile .filters-select .custom-select .dropdown .form-group .form-control {
            border: 2px solid #ececec; }
        .profile .filters-select .custom-select .dropdown ul {
          background: none; }
          .profile .filters-select .custom-select .dropdown ul li {
            padding: 0 10px;
            color: #767676; }
            .profile .filters-select .custom-select .dropdown ul li:hover {
              background-color: #dfdfdf; }
            .profile .filters-select .custom-select .dropdown ul li.is-active {
              background-color: #daeaf5; }
        .profile .filters-select .custom-select .dropdown .empty-result {
          padding: 5px 20px; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel {
    padding: 0 12px; }
    .profile > .grid .profile-content .orders-container .orders-list .order-item .order-panel > div {
      flex-basis: 32% !important; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-statuses .order-status .inner .date {
    padding-right: 0; }
  .profile > .grid .profile-content .orders-container .orders-list .order-item .order-info .order-rating .grid .row span {
    display: none; }
  .profile .wishlist-container {
    padding: 10px; }
    .profile .wishlist-container .actions {
      padding: 10px; }
      .profile .wishlist-container .actions .row {
        width: 100%;
        margin: 10px 0; }
        .profile .wishlist-container .actions .row.askbuy .icon-wrapper {
          justify-content: flex-start; }
    .profile .wishlist-container .offers-list-container {
      padding: 0; }
      .profile .wishlist-container .offers-list-container .offers-list {
        padding: 0; } }

@media (max-width: 767px) {
  .delivery-page-container .search-form {
    flex-wrap: wrap; }
    .delivery-page-container .search-form .row {
      width: 100%;
      text-align: center; }
    .delivery-page-container .search-form .custom-select {
      width: auto;
      margin: 10px 0; }
  .delivery-page-container .deliveries,
  .delivery-page-container .paysystems {
    padding: 0 15px; }
    .delivery-page-container .deliveries .row,
    .delivery-page-container .paysystems .row {
      width: 50%; } }

@media (max-width: 991px) {
  .contacts-page {
    padding: 15px; } }

@media (max-width: 767px) {
  .contacts-page .grid .row {
    flex-basis: 50%; } }

@media (max-width: 459px) {
  .contacts-page .grid .row {
    flex-basis: 100%; } }

@media (max-width: 1169px) {
  .news-list {
    padding: 0 15px; }
    .news-list .news-container {
      justify-content: space-around; }
      .news-list .news-container > div {
        flex-basis: 30%; }
      .news-list .news-container .news-item {
        width: auto; }
  .news-page {
    padding: 15px; }
    .news-page .editor-content img {
      width: auto !important;
      height: auto !important;
      max-width: 100%; } }

@media (max-width: 991px) {
  .news-list .news-container > div {
    flex-basis: 48%; } }

@media (max-width: 767px) {
  .news-list .news-container > div {
    flex-basis: 100%; }
  .news-list .news-container .news-item {
    text-align: center; } }

@media (max-width: 1169px) {
  .articles-list {
    padding: 0 15px; }
    .articles-list .articles-container {
      justify-content: space-around; }
      .articles-list .articles-container > div {
        flex-basis: 30%; }
      .articles-list .articles-container .article-item {
        width: auto; }
  .article-page {
    padding: 15px; }
    .article-page .editor-content img {
      width: auto !important;
      height: auto !important;
      max-width: 100%; } }

@media (max-width: 991px) {
  .articles-list .articles-container > div {
    flex-basis: 48%; } }

@media (max-width: 767px) {
  .articles-list .articles-container > div {
    flex-basis: 100%; }
  .articles-list .articles-container .article-item {
    text-align: center; } }

@media (max-width: 767px) {
  .order-make {
    padding: 15px;
    width: auto;
    margin: auto; }
    .order-make .actions .btn {
      width: auto; }
    .order-make .actions .row {
      flex-basis: 48%; }
    .order-make > .grid {
      flex-wrap: wrap; }
      .order-make > .grid > .row {
        width: 100%;
        margin: 10px 0;
        text-align: center; }
        .order-make > .grid > .row .grid {
          justify-content: center; }
    .order-make .user-budget {
      text-align: center; }
    .order-make .payments-details-container .grid,
    .order-make .payments-container .grid {
      flex-wrap: wrap; }
      .order-make .payments-details-container .grid .row,
      .order-make .payments-container .grid .row {
        width: 100%; }
    .order-make .payments-details-container .popup-container,
    .order-make .payments-container .popup-container {
      display: inline-block;
      margin-top: 10px; }
    .order-make p.hint {
      text-align: center;
      margin: 10px auto 0 auto; } }

@media (max-width: 459px) {
  .order-make .actions {
    flex-wrap: wrap; }
    .order-make .actions .row {
      flex-basis: 100%; } }

@media (max-width: 991px) {
  .signup-page {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .signup-page {
    width: auto;
    padding: 15px; }
    .signup-page .vue-form-wizard .wizard-navigation .wizard-nav {
      justify-content: center; }
      .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li:not(:last-child) {
        flex-grow: 0; }
        .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li:not(:last-child):after {
          margin: auto 15px; }
      .signup-page .vue-form-wizard .wizard-navigation .wizard-nav li a {
        width: auto;
        height: auto;
        line-height: normal;
        padding: 15px; } }

@media (max-width: 459px) {
  .signup-page .man-in-the-black {
    display: none; }
  .signup-page .wizard-navigation {
    margin-top: 15px; }
  .signup-page form .links {
    flex-wrap: wrap; }
    .signup-page form .links .row {
      width: 100%; }
      .signup-page form .links .row:not(:empty) {
        margin: 5px 0; }
      .signup-page form .links .row:nth-of-type(1) {
        padding: 0; }
      .signup-page form .links .row:nth-of-type(2) .grid .row {
        padding: 0; }
      .signup-page form .links .row .grid {
        flex-wrap: wrap; }
        .signup-page form .links .row .grid .row {
          width: 100%; } }

@media (min-width: 992px) {
  .fixed {
    top: 38px;
    width: 1170px; } }

@media (max-width: 1169px) {
  .fixed {
    top: 0;
    left: 0;
    width: 100%; } }

@media (max-width: 991px) {
  .brand-page {
    padding: 0 15px; }
    .brand-page .brand-panel ul {
      flex-wrap: wrap; }
    .brand-page .brand-list .row .letter-key {
      flex-basis: 65px;
      padding: 60px 0 0 15px; } }

@media (max-width: 767px) {
  .brand-page .brand-panel {
    display: none; }
  .brand-page .brand-list .row {
    flex-wrap: wrap; }
    .brand-page .brand-list .row .letter-key {
      flex-basis: 100%;
      text-align: center;
      padding: 30px 0; } }

@media (max-width: 1169px) {
  .stock-list {
    padding: 0 15px; }
  .stock-full-page {
    padding: 0 15px; }
    .stock-full-page .stock-full-container .header {
      flex-wrap: wrap; }
      .stock-full-page .stock-full-container .header .row {
        width: 100%; } }

@media (max-width: 991px) {
  .stock-list-container .stock-item {
    flex-basis: 48%; }
  .stock-full-page .stock-full-container .header img {
    max-width: 100% !important; } }

@media (max-width: 767px) {
  .stock-list .filters {
    padding: 0 35px;
    flex-wrap: wrap; }
    .stock-list .filters .form-group {
      width: 100%; }
      .stock-list .filters .form-group:not(:last-child) {
        margin-right: 0; }
  .stock-list-container .stock-item {
    flex-basis: 100%; }
  .stock-full-page .stock-full-container > .body {
    padding: 0; }
    .stock-full-page .stock-full-container > .body.has-gift {
      flex-wrap: wrap; }
      .stock-full-page .stock-full-container > .body.has-gift > div {
        width: 100%; }
      .stock-full-page .stock-full-container > .body.has-gift .gift-container {
        margin-top: 20px;
        order: 1; }
      .stock-full-page .stock-full-container > .body.has-gift .description-container {
        padding-left: 0; } }

@media (max-width: 767px) {
  .site-error {
    padding: 0 15px;
    margin-bottom: 20px; }
    .site-error__container {
      flex-wrap: wrap;
      justify-content: center;
      padding: 15px; }
    .site-error__image {
      padding: 0 35px; }
    .site-error__content {
      text-align: center;
      padding: 0; } }

@media (min-width: 992px) {
  .layout {
    height: 100%; }
    .layout--landing .layout__container {
      background: #fff;
      z-index: 2;
      width: 1230px;
      margin: 0 auto; }
  .landing-link {
    display: block; } }

@media (max-width: 1169px) {
  .layout__header .top-menu > .grid > .row .city-chooser-container {
    padding-left: 15px; }
  .layout__header .middle-menu > .grid {
    height: 120px; }
    .layout__header .middle-menu > .grid .logo {
      padding-left: 15px; }
    .layout__header .middle-menu > .grid .search-wrapper {
      width: 380px; }
  .layout__footer .container {
    padding: 0 15px; }
  .layout__footer .footer-blocks .copyright {
    right: 15px; }
  .layout__footer .footer-blocks .subscribe .form-container {
    width: 400px; } }

@media (max-width: 991px) {
  .top-menu-container {
    position: inherit; }
  .top-menu {
    height: 62px;
    line-height: 62px; }
  .layout__header {
    padding-top: 0; }
    .layout__header .middle-menu > .grid {
      height: 100px; }
    .layout__header .middle-menu.mobile > .grid .row.work-time {
      padding-right: 15px; }
  .layout__footer .footer-blocks {
    flex-wrap: wrap; }
    .layout__footer .footer-blocks .row {
      text-align: center; }
    .layout__footer .footer-blocks .contacts,
    .layout__footer .footer-blocks .abuse {
      flex-basis: 50%; }
    .layout__footer .footer-blocks .subscribe {
      padding-top: 41px;
      flex-basis: 100%;
      order: 2; }
      .layout__footer .footer-blocks .subscribe .form-container {
        margin: auto; }
        .layout__footer .footer-blocks .subscribe .form-container form button {
          width: auto;
          padding: 0 36px;
          text-indent: -9999px;
          background: url(/build/images/subscribe-submit-bg.png) center center no-repeat; }
  .layout .seo-text-container .seo-text {
    padding: 0 15px;
    column-count: 1; }
    .layout .seo-text-container .seo-text ol, .layout .seo-text-container .seo-text ul, .layout .seo-text-container .seo-text dl {
      padding: 20px; } }

@media (max-width: 767px) {
  .layout__header .top-menu {
    height: 46px;
    line-height: 46px; }
    .layout__header .top-menu > .grid > .row:not(:last-child) {
      flex-basis: auto; }
    .layout__header .top-menu > .grid > .row .auth-form-container .modal-sticked,
    .layout__header .top-menu > .grid > .row .cart-informer-container .modal-sticked {
      left: 0;
      margin-left: 0; }
      .layout__header .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content,
      .layout__header .top-menu > .grid > .row .cart-informer-container .modal-sticked .modal-content {
        padding: 28px 16px; }
        .layout__header .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content .modal-close,
        .layout__header .top-menu > .grid > .row .auth-form-container .modal-sticked .modal-content .modal-title,
        .layout__header .top-menu > .grid > .row .cart-informer-container .modal-sticked .modal-content .modal-close,
        .layout__header .top-menu > .grid > .row .cart-informer-container .modal-sticked .modal-content .modal-title {
          height: auto;
          line-height: normal; }
    .layout__header .top-menu > .grid > .row .city-chooser-container .city-chooser {
      font-size: 14px; }
      .layout__header .top-menu > .grid > .row .city-chooser-container .city-chooser span {
        font-size: 16px; }
  .layout__header .middle-menu > .grid .request-call-container .request-call-mobile {
    padding: 0;
    border-left: none; }
  .layout__header .middle-menu.mobile > .grid {
    height: 67px; }
    .layout__header .middle-menu.mobile > .grid .row.actions, .layout__header .middle-menu.mobile > .grid .row.auth, .layout__header .middle-menu.mobile > .grid .row.request-call {
      padding: 0 20px; }
    .layout__header .middle-menu.mobile > .grid .row.mobile-logo img {
      max-width: 170px; }
    .layout__header .middle-menu.mobile > .grid .row .request-call-container .request-call-mobile {
      padding-right: 0; }
  .layout .mobile-menu .swipe-content {
    width: 360px;
    transform: translateX(-360px); }
  .layout__footer .footer-blocks {
    flex-wrap: wrap; }
    .layout__footer .footer-blocks > .row {
      flex-basis: 100%; }
    .layout__footer .footer-blocks .subscribe .form-container {
      width: 100%; }
      .layout__footer .footer-blocks .subscribe .form-container form input[type="text"] {
        width: 80%; }
  .layout__footer .footer-menu ul {
    display: block; }
    .layout__footer .footer-menu ul li {
      display: block;
      text-align: center;
      border: none;
      padding: 5px 10px; }
  .layout__footer .copyright {
    background: #fff;
    text-align: center;
    padding-bottom: 10px; }
  .layout .modal-mobile-search .modal-content {
    margin: 0;
    border: none; }
  #yii-debug-toolbar {
    display: none !important; } }

@media (max-width: 360px) {
  .layout .middle-menu.mobile > .grid .row.mobile-logo .mobile-only {
    display: block; }
  .layout .middle-menu.mobile > .grid .row.mobile-logo .desktop-only {
    display: none; }
  .layout .middle-menu.mobile > .grid .row.mobile-logo img {
    max-width: 132px; }
  .layout .middle-menu.mobile > .grid .row.actions {
    padding: 0 10px; } }

@media (max-width: 459px) {
  .layout .top-menu > .grid > .row .city-chooser-container .popup-city-confirm {
    margin-left: 15px; }
    .layout .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid {
      width: 270px;
      flex-wrap: wrap; }
      .layout .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid .row {
        flex-basis: 100%;
        text-align: center; }
        .layout .top-menu > .grid > .row .city-chooser-container .popup-city-confirm .grid .row button {
          display: block;
          width: 100%; }
  .layout .top-menu > .grid > .row .city-chooser-container .popup-city-chooser {
    width: 290px; }
  .layout .top-menu > .grid > .row .city-chooser-container .city-chooser {
    margin-left: 15px; }
    .layout .top-menu > .grid > .row .city-chooser-container .city-chooser span {
      border-bottom: none; }
  .layout .middle-menu.mobile > .grid .row.actions, .layout .middle-menu.mobile > .grid .row.auth, .layout .middle-menu.mobile > .grid .row.request-call {
    padding: 0 10px; }
  .layout .middle-menu.mobile > .grid .row.mobile-logo img {
    max-width: 140px; }
  __footer .footer-blocks .subscribe .phones-container .phones .row {
    flex-basis: 100%; }
  .mobile-menu .swipe-content {
    width: 100%;
    transform: translateX(-100%); } }
