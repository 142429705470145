@include media-breakpoint-down(sm) {
  .delivery-page {
    &-container {
      .search-form {
        flex-wrap: wrap;
        .row {
          width: 100%;
          text-align: center;
        }
        .custom-select {
          width: auto;
          margin: 10px 0;
        }
      }

      .deliveries,
      .paysystems {
        padding: 0 15px;
        .row {
          width: 50%;
        }
      }
    }
  }
}