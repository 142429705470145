@include media-breakpoint-down(lg) {
  .news-list {
    padding: 0 15px;
    .news-container {
      justify-content: space-around;
      > div {
        flex-basis: 30%;
      }
      .news-item {
        width: auto;
      }
    }
  }

  .news-page {
    padding: 15px;
    .editor-content {
      img {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .news-list {
    .news-container {
      > div {
        flex-basis: 48%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .news-list {
    .news-container {
      > div {
        flex-basis: 100%;
      }
      .news-item {
        text-align: center;
      }
    }
  }
}