.modal-lottery {
  .modal-content {
    overflow: hidden;
    //padding: 0;
    width: 640px;
    height: 480px;
    background: #fff url("/build/images/gift-bg.png");
    p {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .result {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 35px;
      p {
        font-size: 20px;
      }
      &--success {
        p {
          color: green;
        }
      }
    }
  }
}

@include block('roulette') {
  position: absolute;
  left: -290px;
  top: 30px;
  @include element('spinner') {
    //transform: rotate(1050deg);
    //transition: 1.2s all cubic-bezier(0.4, 0.3, 0, 0.99);
  }
  @include element('pointer') {
    //transform: rotate(-90deg);
    top: 178px;
    position: absolute;
    right: 0;
  }
}

@include block('lottery-form') {
  margin-left: 280px;
  margin-top: 140px;
}