/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-answer-float {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -354px;
  width: 13px;
  height: 10px;
}
.icon-answers-show-more {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -405px;
  width: 10px;
  height: 5px;
}
.icon-arrow-down {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -451px -383px;
  width: 7px;
  height: 4px;
}
.icon-arrow-left {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -455px -110px;
  width: 4px;
  height: 7px;
}
.icon-arrow-right {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -455px -117px;
  width: 4px;
  height: 7px;
}
.icon-arrow-up {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -451px -387px;
  width: 7px;
  height: 4px;
}
.icon-back-white {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -21px;
  width: 15px;
  height: 22px;
}
.icon-bonus-mini {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -251px;
  width: 11px;
  height: 17px;
}
.icon-bonus {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -19px -404px;
  width: 16px;
  height: 23px;
}
.icon-buy-approved {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -219px -272px;
  width: 21px;
  height: 21px;
}
.icon-buy-mini {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -60px;
  width: 15px;
  height: 18px;
}
.icon-cart-confirm-remove {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -232px -343px;
  width: 28px;
  height: 28px;
}
.icon-cart-confirm-wishlist {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -277px;
  width: 35px;
  height: 31px;
}
.icon-cart-remove-hover {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -240px -272px;
  width: 21px;
  height: 21px;
}
.icon-cart-remove {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -372px;
  width: 11px;
  height: 11px;
}
.icon-cityphone {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -241px -377px;
  width: 20px;
  height: 22px;
}
.icon-contact-callcenter {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px 0px;
  width: 45px;
  height: 46px;
}
.icon-contact-courier {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -140px -135px;
  width: 51px;
  height: 39px;
}
.icon-contact-phones {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px -137px;
  width: 41px;
  height: 44px;
}
.icon-contact-pickup {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -273px -130px;
  width: 48px;
  height: 51px;
}
.icon-contact-questions {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -299px;
  width: 46px;
  height: 44px;
}
.icon-custom-checkbox-checked {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -396px;
  width: 10px;
  height: 9px;
}
.icon-delivery-page-delivery-courier {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -197px 0px;
  width: 76px;
  height: 67px;
}
.icon-delivery-page-delivery-novaposhta {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -197px -67px;
  width: 70px;
  height: 69px;
}
.icon-delivery-page-delivery-pickup {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -123px 0px;
  width: 74px;
  height: 80px;
}
.icon-delivery-page-delivery-privatbank {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -66px;
  width: 87px;
  height: 69px;
}
.icon-delivery-page-delivery-ukrposhta {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -135px;
  width: 140px;
  height: 39px;
}
.icon-delivery-page-paysystem-bonus {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -273px -65px;
  width: 46px;
  height: 65px;
}
.icon-delivery-page-paysystem-cash {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -63px -174px;
  width: 65px;
  height: 63px;
}
.icon-delivery-page-paysystem-creditcard {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -123px -80px;
  width: 69px;
  height: 55px;
}
.icon-delivery-page-paysystem-imposed {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px 0px;
  width: 123px;
  height: 66px;
}
.icon-empty-cart {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -174px;
  width: 63px;
  height: 72px;
}
.icon-filter-button {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -162px;
  width: 15px;
  height: 16px;
}
.icon-filter-gift {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -94px;
  width: 16px;
  height: 16px;
}
.icon-footer-facebook-active {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -106px;
  width: 35px;
  height: 35px;
}
.icon-footer-facebook {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -110px;
  width: 11px;
  height: 22px;
}
.icon-footer-google-plus-active {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -176px;
  width: 35px;
  height: 35px;
}
.icon-footer-google-plus {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -293px;
  width: 23px;
  height: 22px;
}
.icon-footer-vk-active {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -71px;
  width: 35px;
  height: 35px;
}
.icon-footer-vk {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -240px -221px;
  width: 23px;
  height: 13px;
}
.icon-footer-youtube-active {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -141px;
  width: 35px;
  height: 35px;
}
.icon-footer-youtube {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -247px;
  width: 23px;
  height: 23px;
}
.icon-home {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -126px -404px;
  width: 19px;
  height: 17px;
}
.icon-info {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -87px -113px;
  width: 22px;
  height: 22px;
}
.icon-key-grey {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -330px;
  width: 13px;
  height: 12px;
}
.icon-key {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -342px;
  width: 13px;
  height: 12px;
}
.icon-kyivstar {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -162px -377px;
  width: 27px;
  height: 26px;
}
.icon-label-discount-mini {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -338px;
  width: 23px;
  height: 22px;
}
.icon-label-markdown-mini {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -261px -272px;
  width: 20px;
  height: 20px;
}
.icon-label-markdown {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -372px -343px;
  width: 28px;
  height: 28px;
}
.icon-label-new-big {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px -223px;
  width: 42px;
  height: 42px;
}
.icon-label-new {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -344px -343px;
  width: 28px;
  height: 28px;
}
.icon-label-stock-big {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px -181px;
  width: 42px;
  height: 42px;
}
.icon-label-stock {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -316px -343px;
  width: 28px;
  height: 28px;
}
.icon-label-top-big {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -88px -299px;
  width: 42px;
  height: 42px;
}
.icon-label-top {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -288px -343px;
  width: 28px;
  height: 28px;
}
.icon-label-without-discount {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -108px -377px;
  width: 27px;
  height: 27px;
}
.icon-lifecell {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -189px -377px;
  width: 26px;
  height: 26px;
}
.icon-liqpay {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -152px -246px;
  width: 98px;
  height: 20px;
}
.icon-mail {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -268px;
  width: 15px;
  height: 12px;
}
.icon-man {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -35px -404px;
  width: 19px;
  height: 19px;
}
.icon-mobile-auth {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -134px -343px;
  width: 32px;
  height: 32px;
}
.icon-mobile-bigmac {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -404px;
  width: 19px;
  height: 25px;
}
.icon-mobile-cart-small {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -260px -343px;
  width: 28px;
  height: 28px;
}
.icon-mobile-delivery-small {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -123px -272px;
  width: 51px;
  height: 23px;
}
.icon-mobile-menu {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -38px -343px;
  width: 32px;
  height: 32px;
}
.icon-mobile-request-call-small {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -53px;
  width: 25px;
  height: 25px;
}
.icon-mobile-search-input {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -43px;
  width: 16px;
  height: 17px;
}
.icon-mobile-search-small {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -315px;
  width: 22px;
  height: 23px;
}
.icon-mobile-search {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -308px;
  width: 32px;
  height: 32px;
}
.icon-modal-close {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -304px;
  width: 13px;
  height: 13px;
}
.icon-move-top {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -364px;
  width: 16px;
  height: 8px;
}
.icon-order-average {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -36px;
  width: 36px;
  height: 35px;
}
.icon-order-back {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -455px -124px;
  width: 4px;
  height: 7px;
}
.icon-order-checked-white {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -281px -272px;
  width: 21px;
  height: 19px;
}
.icon-order-checked {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -215px -377px;
  width: 26px;
  height: 24px;
}
.icon-order-complete {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -211px;
  width: 33px;
  height: 33px;
}
.icon-order-delivery {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -174px -299px;
  width: 44px;
  height: 38px;
}
.icon-order-gift {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -237px -174px;
  width: 36px;
  height: 38px;
}
.icon-order-paysystem-bank {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -46px -299px;
  width: 42px;
  height: 42px;
}
.icon-order-paysystem-bonus {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -87px -66px;
  width: 33px;
  height: 47px;
}
.icon-order-paysystem-cash {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -197px -136px;
  width: 66px;
  height: 33px;
}
.icon-order-paysystem-creditcard {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -128px -174px;
  width: 59px;
  height: 47px;
}
.icon-order-paysystem-imposed {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px -89px;
  width: 38px;
  height: 48px;
}
.icon-order-paysystem-privat24 {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -246px;
  width: 52px;
  height: 53px;
}
.icon-order-paysystem {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -267px -299px;
  width: 39px;
  height: 32px;
}
.icon-order-personal-data {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -218px -299px;
  width: 49px;
  height: 32px;
}
.icon-order-rating-bad {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -135px -377px;
  width: 27px;
  height: 27px;
}
.icon-order-rating-excellent {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -27px -377px;
  width: 27px;
  height: 27px;
}
.icon-order-rating-good {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -81px -377px;
  width: 27px;
  height: 27px;
}
.icon-order-rating-normal {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -54px -377px;
  width: 27px;
  height: 27px;
}
.icon-order-rating-unacceptable {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -377px;
  width: 27px;
  height: 27px;
}
.icon-order-status-cancel {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -102px -343px;
  width: 32px;
  height: 32px;
}
.icon-order-status-canceled {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -70px -343px;
  width: 32px;
  height: 32px;
}
.icon-order-status-confirm {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -224px;
  width: 23px;
  height: 23px;
}
.icon-order-status-courier-done {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -166px -343px;
  width: 32px;
  height: 30px;
}
.icon-order-status-done {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -78px;
  width: 22px;
  height: 28px;
}
.icon-order-status-init {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -106px;
  width: 24px;
  height: 24px;
}
.icon-order-status-novaposhta-done {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -52px -272px;
  width: 71px;
  height: 26px;
}
.icon-order-status-pickup-done {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px -265px;
  width: 42px;
  height: 32px;
}
.icon-order-status-ukrposhta-done {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -250px -246px;
  width: 72px;
  height: 20px;
}
.icon-order-status-warehouse-done {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -343px;
  width: 38px;
  height: 34px;
}
.icon-order-status-warehouse-process {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px -244px;
  width: 33px;
  height: 33px;
}
.icon-order-summary-delivery-address {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -184px -404px;
  width: 20px;
  height: 16px;
}
.icon-order-summary-delivery-method {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -175px;
  width: 25px;
  height: 22px;
}
.icon-order-summary-delivery-time {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -90px -404px;
  width: 18px;
  height: 18px;
}
.icon-order-summary-phone {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -72px -404px;
  width: 18px;
  height: 19px;
}
.icon-order-summary-user {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -357px -377px;
  width: 19px;
  height: 19px;
}
.icon-phone {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -54px -404px;
  width: 18px;
  height: 19px;
}
.icon-platon {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -52px -246px;
  width: 100px;
  height: 26px;
}
.icon-plus-big {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -28px;
  width: 25px;
  height: 25px;
}
.icon-plus-green {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -17px -429px;
  width: 17px;
  height: 17px;
}
.icon-popup-close {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -317px;
  width: 12px;
  height: 13px;
}
.icon-portmone {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -128px -221px;
  width: 112px;
  height: 15px;
}
.icon-product-delivery {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -130px -299px;
  width: 44px;
  height: 38px;
}
.icon-product-help {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -34px -429px;
  width: 17px;
  height: 17px;
}
.icon-product-offer-choose {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -383px;
  width: 7px;
  height: 13px;
}
.icon-product-payment {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -198px -343px;
  width: 34px;
  height: 28px;
}
.icon-product-preview {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -78px;
  width: 16px;
  height: 16px;
}
.icon-profile-account {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -108px -404px;
  width: 18px;
  height: 18px;
}
.icon-profile-addressbook-add {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: 0px -429px;
  width: 17px;
  height: 18px;
}
.icon-profile-addressbook-edit {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -204px -404px;
  width: 18px;
  height: 16px;
}
.icon-profile-addressbook-set-primary {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -237px;
  width: 14px;
  height: 14px;
}
.icon-profile-addressbook {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -302px -272px;
  width: 19px;
  height: 19px;
}
.icon-profile-delivery-profile-add {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -338px -299px;
  width: 29px;
  height: 30px;
}
.icon-profile-delivery-type {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -153px;
  width: 25px;
  height: 22px;
}
.icon-profile-delivery-value {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -164px -404px;
  width: 20px;
  height: 16px;
}
.icon-profile-discount {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -197px;
  width: 20px;
  height: 27px;
}
.icon-profile-discounts {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -319px -377px;
  width: 19px;
  height: 19px;
}
.icon-profile-logout {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -174px -272px;
  width: 22px;
  height: 22px;
}
.icon-profile-manager-message {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -145px -404px;
  width: 19px;
  height: 17px;
}
.icon-profile-manager {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -261px -377px;
  width: 20px;
  height: 22px;
}
.icon-profile-menu-toggle {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -388px;
  width: 22px;
  height: 16px;
}
.icon-profile-order-cancel {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -291px;
  width: 13px;
  height: 13px;
}
.icon-profile-order-pay {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -273px -228px;
  width: 20px;
  height: 18px;
}
.icon-profile-orders {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px 0px;
  width: 16px;
  height: 21px;
}
.icon-profile-reviews {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -222px -404px;
  width: 18px;
  height: 15px;
}
.icon-profile-total-sum {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -196px -272px;
  width: 23px;
  height: 21px;
}
.icon-profile-wishlist-askbuy {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -300px -377px;
  width: 19px;
  height: 19px;
}
.icon-profile-wishlist-remove {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -206px;
  width: 13px;
  height: 17px;
}
.icon-profile-wishlist {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -223px;
  width: 15px;
  height: 14px;
}
.icon-request-call-mobile {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -306px -299px;
  width: 32px;
  height: 32px;
}
.icon-request-call {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -281px -377px;
  width: 19px;
  height: 20px;
}
.icon-si-left {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -293px -228px;
  width: 25px;
  height: 14px;
}
.icon-si-right {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -429px 0px;
  width: 15px;
  height: 23px;
}
.icon-signup-checkbox {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -240px -404px;
  width: 18px;
  height: 12px;
}
.icon-signup-code {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -187px -174px;
  width: 50px;
  height: 42px;
}
.icon-signup-man {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -360px;
  width: 18px;
  height: 28px;
}
.icon-signup-offer {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -270px;
  width: 23px;
  height: 23px;
}
.icon-signup-password {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -273px 0px;
  width: 50px;
  height: 65px;
}
.icon-signup-phone {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -273px -181px;
  width: 45px;
  height: 47px;
}
.icon-stock-alt-big {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -368px 0px;
  width: 36px;
  height: 36px;
}
.icon-swipe-close-white {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -338px -377px;
  width: 19px;
  height: 19px;
}
.icon-swipe-close {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -376px -377px;
  width: 19px;
  height: 19px;
}
.icon-thumb-down {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -132px;
  width: 16px;
  height: 15px;
}
.icon-thumb-up {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -147px;
  width: 16px;
  height: 15px;
}
.icon-top-cart {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px 0px;
  width: 25px;
  height: 28px;
}
.icon-viewed {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -280px;
  width: 16px;
  height: 11px;
}
.icon-vodafone {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -404px -130px;
  width: 24px;
  height: 23px;
}
.icon-wishlist-add {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -192px;
  width: 16px;
  height: 14px;
}
.icon-wishlist-remove {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -444px -178px;
  width: 16px;
  height: 14px;
}
.icon-zoom-in {
  background-image: url(/build/images/icons-1569335653470.png);
  background-position: -323px -46px;
  width: 43px;
  height: 43px;
}
