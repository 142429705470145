@mixin image($width: auto, $height: auto) {
  display: flex;
  width: $width;
  height: $height;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: auto;
  }
}