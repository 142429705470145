@include media-breakpoint-up(lg) {
  .category-wrapper {
    &[v-cloak] {
      display: flex !important;
      aside {
        .child-categories {
          height: $category_child_height;
          margin: $category_item_padding_height $category_item_padding_width;
          background-image:
                  linear-gradient( 100deg, rgba(236, 236, 236, 0), rgba(236, 236, 236, 0.5) 50%, rgba(236, 236, 236, 0) 100% ),
                  linear-gradient( white $line_category_height, transparent 0 );

          background-repeat: repeat-y;

          background-size:
                  $category_child_height $line_category_height, /* highlight */
                  $line_category_width $category_child_repeat;

          background-position:
                  -150% 0, /* highlight */
                  $line_category_position;


          animation: loading-categories 1.5s infinite;
        }
      }
      .category-description-wrapper {
        width: calc(100% - #{$filter_width} - 14px);
      }
    }

    aside {
      .filter-group-wrapper.is-loading {
        height: $filter_wrapper_height;
        margin: 24px;

        background-image:
                linear-gradient( 100deg, rgba(236, 236, 236, 0), rgba(236, 236, 236, 0.5) 50%, rgba(236, 236, 236, 0) 80% ),
                linear-gradient( white $filter_group_height, transparent 0 ),
                linear-gradient( white $filter_item_checkbox_height, transparent 0 ),
                linear-gradient( white $filter_item_title_height, transparent 0 ),
                linear-gradient( white $filter_item_checkbox_height, transparent 0 ),
                linear-gradient( white $filter_item_title_height, transparent 0 ),
                linear-gradient( white $filter_item_checkbox_height, transparent 0 ),
                linear-gradient( white $filter_item_title_height, transparent 0 );

        background-repeat: repeat-y;

        background-size:
                $filter_wrapper_height $filter_wrapper_repeat, /* highlight */
                $filter_group_width $filter_wrapper_repeat,
                $filter_item_checkbox_width $filter_wrapper_repeat,
                $filter_item_title_width $filter_wrapper_repeat,
                $filter_item_checkbox_width $filter_wrapper_repeat,
                $filter_item_title_width $filter_wrapper_repeat,
                $filter_item_checkbox_width $filter_wrapper_repeat,
                $filter_item_title_width $filter_wrapper_repeat;

        background-position:
                350% 0, /* highlight */
                $filter_group_position,
                $filter_item_checkbox_position1,
                $filter_item_title_position1,
                $filter_item_checkbox_position2,
                $filter_item_title_position2,
                $filter_item_checkbox_position3,
                $filter_item_title_position3;

        animation: loading-filters 1.5s infinite;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .category-wrapper {
    padding: 0 15px;
    > section {
      width: 100%;
      .products-container {
        justify-content: space-around;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .category-wrapper {
    &[v-cloak] {
      display: block !important;
    }
    aside {
      display: none;
    }
  }
  .mobile-filter,
  .mobile-sort {
    .swipe-content {
      width: 460px;
      transform: translateX(-460px);
    }
  }

  .mobile-filter {
    .swipe-content {
      .child-categories {
        ul {
          li {
            a {
              padding: 20px 33px 20px 21px;
              font-size: 16px;
              &.important {
                color: $important_color;
              }
            }
          }
        }
      }
      .filter-group,
      .filter-price {
        .filter-title {
          font-size: 16px;
        }
        .filter-options {
          ul {
            li {
              a {
                font-size: 16px;
                padding: 16px;
              }
            }
          }
        }
      }
    }
  }

  @include block('reviews-last') {
    padding: 0 15px;
  }
}

@include media-breakpoint-down(sm) {
  .category-wrapper {
    > section {
      .sort-panel {
        a {
          &.filter-btn,
          &.sort-btn {
            text-align: center;
            width: 50%;
            justify-content: center;
            padding: 14px 0;
            font-size: 16px;
          }
        }
      }
      .filter-panel {
        padding: 0 35px;
        justify-content: space-between;
        .filter-item {
          padding: 12px 30px 12px 23px;
          width: 45%;
          text-align: center;
          font-size: 15px;
          margin-right: 0;
          span.close {
            right: 15px;
            top: 12px;
          }
        }
      }
    }
  }

  .mobile-filter,
  .mobile-sort {
    .swipe-content {
      width: 100%;
      transform: translateX(-100%);
    }
  }
}

@include media-breakpoint-down(sm) {
  .category-wrapper {
    .category-banner {
      width: 50%;
      padding: 0;
      img {
        max-width: 100%;
      }
    }
    > section {
      .sort-panel {
        a {
          &.filter-btn,
          &.sort-btn {
            padding: 14px 0;
            font-size: 16px;
          }
        }
      }
      .filter-panel {
        padding: 0 10px;
        .filter-item {
          width: 48%;
        }
      }
      //.products-container {
      //  align-items: stretch;
      //}
    }

    .category-description-wrapper {
      .category-description {
        .short-description {
          display: none;
        }
      }
    }
  }
}