@import "../variables";

.menu-blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0,0,0,0.7);
}
.blured {
  main,
  .top-menu,
  .middle-menu {
    filter: blur(1px);
  }
}

nav.main-menu {
  > ul {
    z-index: 8;
    display: flex;
    justify-content: space-between;
    position: relative;
    > li {
      flex-grow: 1;
      height: 48px;
      line-height: 48px;
      @for $i from 1 through length($main-menu-colors) {
        &:nth-child(#{$i}) {
          background-color: #{nth($main-menu-colors, $i)};
        }
      }
      &.has-children {
        > a:after {
          display: block;
          content: "";
          position: absolute;
          top: 35px;
          left: 50%;
          background: url(/build/images/menu_arrows_sprite.png) no-repeat -4px -5px;
          width: 5px;
          height: 3px;
        }
      }
      //&:nth-child(3),
      &:hover {
        > div {
          display: block;
        }
      }
      > a {
        position: relative;
        display: block;
        text-align: center;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        white-space: nowrap;
        > span.label-sale {
          display: block;
          position: absolute;
          top: -7px;
          left: 50%;
          margin-left: -17px;
          color: #fff;
          width: 33px;
          height: 17px;
          background: #e64f88;
          font-size: 12px;
          line-height: 16px;
          border-radius: 4px;
          border: 1px solid rgba(0,0,0,0.16);
        }
        .icon-label-markdown-mini {
          position: absolute;
          top: -9px;
          left: calc(50% - 10px);
        }
      }
      > div {
        position: absolute;
        left: 0;
        display: none;
        width: 100%;
        background: #fff;
        border: 1px solid #d0d0d1;
        border-top: none;
        z-index: 30;
        padding-top: 8px;
      }
      > .brands {
        height: 260px;
        padding-top: 6px;
        > ul {
          position: relative;
          display: flex;
          //justify-content: center;
          > li {
            margin: 5px 3px;
            &:not(.empty-letter) {
              &:hover {
                > ul {
                  display: flex;
                  padding-top: 6px;
                  &:before {
                    display: block;
                    content: "";
                    margin-top: 5px;
                    border-top: 1px solid #d0d0d1;
                    position: absolute;
                    width: 1149px;
                    left: 9px;
                  }
                }
                > a {
                  color: #fff;
                  background: $secondary-button-color;
                  border-radius: 50%;
                  cursor: pointer;
                }
              }
            }
            > a {
              display: block;
              text-align: center;
              width: 35px;
              height: 35px;
              line-height: 35px;
              color: #474747;
              font-size: 14px;
              text-decoration: none;
            }
            &.empty-letter {
              > a {
                color: #a5a5a5;
                cursor: default;
              }
            }
            > ul {
              position: absolute;
              left: 0;
              display: none;
              width: 100%;
              > li {
                text-align: center;
                padding: 20px 14px 14px;
                a {
                  display: block;
                  color: $important_color;
                  line-height: 22px;
                  text-decoration: none;
                  width: 95px;
                  &:hover {
                    text-decoration: underline;
                  }
                }
                //img {
                //  display: block;
                //  margin: 0 auto;
                //  padding: 10px 0;
                //  max-width: 100px;
                //}
                .brand-logo {
                  display: block;
                  width: 92px;
                  height: 60px;
                  margin-bottom: 5px;
                  background: no-repeat center;
                  background-size: contain;
                }
              }
            }
          }
        }
        .brand-items-wrapper {
          &:before {
            display: block;
            content: "";
            margin-top: 5px;
            border-top: 1px solid #d0d0d1;
            position: absolute;
            width: 1149px;
            left: 9px;
          }
          .brand-items {
            display: flex;
            justify-content: center;
            //padding-top: 20px;
            > div {
              text-align: center;
              padding: 20px 14px 14px;
              a {
                display: block;
                color: $important_color;
                line-height: 22px;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              }
              //img {
              //  display: block;
              //  margin: 0 auto;
              //  padding: 10px 0;
              //  max-width: 100px;
              //}
              .brand-logo {
                display: block;
                width: 92px;
                height: 60px;
                margin-bottom: 5px;
                background: no-repeat center;
                background-size: contain;
              }
            }
          }
        }
        .brand-search {
          position: absolute;
          bottom: 10px;
          right: 10px;
          .btn {
            &.brand-all-btn {
              width: 129px;
              color: #fff;
              font-size: 14px;
              text-align: center;
              //border-radius: 5px;
              margin-right: 10px;
            }
          }
          .search-form {
            margin-right: 16px;
            .form {
              position: relative;
              input[type="text"] {
                width: 490px;
                height: 42px;
                border: 1px solid #c0c0c0;
                padding: 0 30px 0 15px;
                box-shadow: none;
                background: #fff;
              }
              input[type="submit"] {
                position: absolute;
                top: 17px;
                right: 10px;
                background: url("/build/images/icon_search_mini.png") no-repeat 0 -1px;
                width: 17px;
                height: 17px;
              }
              input[type="text"]:focus + input[type="submit"] {
                background-position: 0 -21px;
              }
            }
            .placeholder {
              a {
                font-size: 14px;
                color: #474747;
                border-bottom: 1px dotted #474747;
                padding-bottom: 2px;
                margin-right: 14px;
                text-transform: none;
                text-decoration: none;
              }
            }
          }
        }
      }
      > .children {
        > ul {
          position: relative;
          > li {
            max-width: 250px;
            line-height: normal;
            &.has-children {
              > a:after {
                display: block;
                content: "";
                position: absolute;
                top: 19px;
                right: 4px;
                background: url(/build/images/menu_arrows_sprite.png) no-repeat -5px -23px;
                width: 3px;
                height: 5px;
              }
            }

            //&:last-child,
            &:hover {
              background: $important_color;
              .grid {
                display: flex;
                justify-content: flex-start;
              }
              > a {
                border-bottom: 1px dotted transparent;
                color: #fff;
                &:after {
                  background-position: -5px -13px;
                }
              }
            }
            > a {
              position: relative;
              display: block;
              padding: 11px 0;
              border-bottom: 1px dotted #b9b8b8;
              color: $important_color;
              margin: 0 10px 0 14px;
              font-size: 15px;
              text-decoration: none;
            }
            .grid {
              $paddingLeft: 250px;
              position: absolute;
              display: none;
              left: $paddingLeft;
              top: 0;
              padding: 21px 24px;
              height: 100%;
              width: calc(100% - #{$paddingLeft});
              .row {
                &.column-container {
                  columns: 2;
                  column-fill: auto;
                  height: 100%;
                }
                .group {
                  margin-bottom: 10px;
                  p {
                    color: #4272e0;
                    font-weight: bold;
                  }
                }
                ul {
                  > li {
                    line-height: 28px;
                    width: 265px;
                    > a {
                      color: #474747;
                      font-size: 14px;
                      text-decoration: none;
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }

                  &.important {
                    //margin-left: 30px;
                    > li {
                      > p {
                        color: #fe584c;
                        margin-bottom: 0;
                        font-size: 12px;
                      }
                      > a {
                        display: inline-block;
                        font-size: 16px;
                        color: $important_color;
                        padding: 2px 16px;
                        border: 1px solid $important_color;
                        text-decoration: none;
                        margin-bottom: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}