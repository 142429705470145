.offer-item {
  background: #fff;
  padding: 12px;
  .image {
    padding-top: 30px;
    margin: 0 auto;
    @include image(250px, 250px);
  }
  .rating-widget {
    margin: 5px auto;
  }
  .code {
    font-size: 12px;
    width: 124px;
    margin: 0 auto;
    border: 2px dotted #ffaf8b;
    text-align: center;
    line-height: 17px;
    color: $secondary-color;
    > span {
      color: #3c3c3c;
    }
  }
  .names {
    margin-top: 10px;
    text-align: center;
    height: 90px;
    .primary-name {
      //color: #333333;
      a {
        @include default-link(#333333);
      }
    }
    .secondary-name {
      position: relative;
      margin-top: 10px;
      color: $secondary-color;
      padding: 0 2px;
    }
  }
  .unavailable-text,
  .price-container {
    margin-bottom: 20px;
  }
  .price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 33px;
    font-size: 15px;
    .label {
      margin-right: 5px;
    }
    .price-current {
      color: #333333;
    }
    &.with-discount {
      .price-current {
        border: 1px solid #e9bac4;
        color: #d98596;
        padding: 7px 11px;
        border-radius: 2px;
        > span {
          font-weight: 600;
        }
      }
      .price-old {
        color: #d0d0d0;
        text-decoration: line-through;
        margin-left: 9px;
      }
    }
  }
  .unavailable-text {
    color: #333333;
    font-size: 15px;
    text-align: center;
    min-height: 32px;
    line-height: 32px;
  }
}