@include media-breakpoint-down(sm) {
  .order-make {
    padding: 15px;
    width: auto;
    margin: auto;
    .actions {
      .btn {
        width: auto;
      }
      .row {
        flex-basis: 48%;
      }
    }

    > .grid {
      flex-wrap: wrap;
      > .row {
        width: 100%;
        margin: 10px 0;
        text-align: center;
        .grid {
          justify-content: center;
        }
      }
    }

    .user-budget {
      text-align: center;
    }

    .payments-details-container,
    .payments-container {
      .grid {
        flex-wrap: wrap;
        .row {
          width: 100%;
        }
      }

      .popup-container {
        display: inline-block;
        margin-top: 10px;
      }
    }

    p.hint {
      text-align: center;
      margin: 10px auto 0 auto;
    }
  }
}

@include media-breakpoint-down(xs) {
  .order-make {
    .actions {
      flex-wrap: wrap;
      .row {
        flex-basis: 100%;
      }
    }
  }
}