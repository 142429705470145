.pagination {
  $size: 37px;
  ul {
    display: flex;
    justify-content: center;
    li {
      display: block;
      width: $size;
      height: $size;
      line-height: $size;
      text-align: center;
      a {
        display: block;
        @include default-link();
      }
      a,
      span {
        font-size: 15px;
      }
      &.is-active {
        background-color: #d7d7d7;
        border-radius: 100%;
        a,
        span {
          color: #fff;
        }
      }
    }
  }
}