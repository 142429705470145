#root.order-mode {
  .top-menu {
    top: 0;
    background: #ececec;
    color: $secondary-color;
    > .grid {
      > .row {
        &:first-child {
          flex-basis: 44%;
        }
        .city-chooser-container {
          .city-chooser {
            span {
              border-bottom: 1px dotted $secondary-color;
              &:after {
                color: $secondary-color;
              }
            }
          }
        }
        .auth-form-container {
          .profile-link {
            text-align: left;
            a {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }

  @include block('layout') {
    @include element('header') {
      .middle-menu {
        > .grid {
          > .row {
            &.phones {
              padding-left: 20px;
            }
            &.logo {
              flex-grow: 1;
              text-align: center;
            }

            &.request-call {
              padding-right: 144px;
            }
          }
        }
      }
    }

    @include element('footer') {
      padding-top: 0;
    }
  }

  .order-page {
    h4 {
      font-size: 16px;
      text-align: center;
      margin-bottom: 30px;
    }

    h5 {
      width: 260px;
      padding: 18px 0;
      border-bottom: 1px solid #ededed;
      margin-bottom: 14px;
      text-align: center;
    }

    .alert-success {
      font-size: 15px;
    }

    form {
      text-align: center;
      .form-group {
        .input-group {
          position: relative;
          i.icon {
            position: absolute;
            left: 15px;
            top: 11px;
            &.icon-mail {
              left: 17px;
              top: 15px;
            }
          }
          .form-control {
            padding: 0 40px;
          }
        }
        //width: 300px;
        label {
          padding: 5px 0;
          span.required {
            color: #ff0000;
          }
        }
        .form-control {
          background: #f4f3f5;
          text-align: center;
          border: 1px solid #b3b3b3;
          &.completed,
          &:disabled {
            background: #fff;
          }
        }
        .hint {
          padding: 8px 0;
          span {
            &.username {
              font-weight: bold;
            }
          }
          &.small {
            font-size: 13px;
            //line-height: 12px;
          }
        }
        textarea.form-control {
          text-align: left;
        }
      }

      .m-t-20 {
        margin-top: 20px;
      }

      .icon-wrapper__center {
        justify-content: center;
        width: 100%;
        span {
          padding: 0 10px;
        }
      }

      .actions {
        margin: 30px 0;
        .btn {
          .icon-wrapper {
            i.icon {
              margin-right: 10px;
            }
          }
        }
      }

      .custom-checkbox {
        padding: 8px 0;
        > span {
          color: $link-secondary-color;
        }
      }

      .checkbox-container {
        > .grid {
          justify-content: flex-start;
          align-items: center;
          .row:last-child {
            padding-left: 10px;
          }
        }
      }

      .custom-select {
        &.is-disabled {
          background: #fff;
          //color: #000;
        }
        //.current-value {
        //  background: inherit;
        //}

        .dropdown {
          background-color: #fff;
          border: 1px solid #b3b3b3;
          .form-group {
            padding: 7px;
            .form-control {
              border: 1px solid #b3b3b3;
            }
          }
          ul {
            background: none;
            li {
              overflow: hidden;
              white-space: nowrap;
              color: $secondary-color;
              &:hover {
                background-color: #dfdfdf;
              }
              &.is-active {
                background-color: #daeaf5;
              }
            }
          }
          .empty-result {
            padding: 5px 20px;
          }
        }
      }
    }

    .delivery-step-content {
      .form-group {
        margin-bottom: 15px;
      }
      .paysystem-description {
        margin-top: 10px;
        color: #e89191;
      }
    }

    // wizard redefined default styles
    .vue-form-wizard {
      .wizard-navigation {
        .wizard-nav {
          display: flex;
          justify-content: center;
          .wizard-icon-container {
            &:checked {

            }
          }
          .wizard-icon-container,
          .wizard-icon-circle {
            display: flex;
            height: 70px;
            margin: auto;
            i.icon {
              margin: auto;
            }
          }
          li {
            display: flex;
            &:not(:last-child) {
              //flex-grow: 1;
            }
            &:not(:last-child):after {
              display: block;
              content: '';
              background: url('/build/images/steps_right.png');
              width: 8px;
              height: 14px;
              //margin: 85px auto 0 auto;
              margin: auto 30px;
              flex-shrink: 0;
            }
            &.active {
              a {
                background: #ececec;
              }
            }
            span.stepTitle {
              display: block;
              padding: 15px 31px;
              color: $secondary-color;
              min-width: 158px;
              text-align: center;
              &.active {
                //background: #ececec;
              }
            }
          }
        }
      }
      .wizard-tab-content {
        margin-top: 20px;
        padding: 5px;
        background: #ececec;
        display: flex;
        .wizard-tab-container {
          flex-grow: 1;
          background: #fff;
          > header {
            display: flex;
            justify-content: center;
          }
          form {
            position: relative;
            .loader-wrapper {
              &.is-loading {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, 0.4);
              }
            }
          }
          .grid.center {
            justify-content: center;
            align-items: flex-start;
            > .row {
              padding: 0 30px;
              width: 340px;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              .form-group {
                width: 100%;
              }

              .field-house-container,
              .field-flat-container,
              .field-date-container,
              .field-time-container {
                flex-basis: 48%;
              }
            }
          }
        }
        a.link {
          @include default-link($link-secondary-color);
          text-decoration: underline;
          text-align: center;
        }
        .total-container {
          display: flex;
          width: 311px;
          margin-left: 5px;
          .v-spinner {
          margin: auto;
          }
          > div.container {
            width: 100%;
          }
          header {
            text-align: center;
            padding: 18px 0;
          }
          .cart-carousel {
            display: none;
          }
          .cart-items {
            background: #fff;
            .vb-content {
              max-height: 300px;
            }
            .cart-item {
              &:not(:last-child) {
                border-bottom: 1px solid #ececec;
              }
            }
          }
        }
      }
    }
    .paysystem-list {
      &.select-mode {
        .paysystem:not(.is-selected) {
          opacity: .3;
        }
      }
      form > .grid {
        flex-wrap: wrap;
        justify-content: space-around;
        > .row {
          width: 30%;
        }
      }
    }

    .cart-item {
      align-items: center;
      padding: 14px 0;
      .image {
        width: 70px;
        height: 70px;
        display: flex;
        flex-shrink: 0;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto;
        }
      }
      .names {
        flex-grow: 1;
        padding-left: 5px;
        p {
          font-size: 13px;
          color: #6d6d6d;
          margin-bottom: 12px;
          text-transform: uppercase;
          a {
            @include default-link();
            color: inherit;
          }
          &.alias {
            color: #949494;
          }
        }
        .grid {
          justify-content: flex-start;
          align-items: center;
          .quantity {
            background: #e9e9e7;
            color: $secondary-color;
            padding: 4px 6px;
            font-size: 13px;
          }
          .separator {
            margin: 0 15px;
          }
          .price {

          }
        }
      }
    }

    .totals {
      padding: 21px 25px;
      .bonus-container {
        justify-content: flex-end;
        span {
          padding-right: 5px;
        }
      }
      table {
        width: 100%;
        &:nth-of-type(1) {
          margin-bottom: 5px;
        }
        &:nth-of-type(2) {
          margin-top: 5px;
          border-top: 1px solid #fff;
        }
        tr {
          td {
            padding: 10px 0;
            &:last-child {
              text-align: right;
            }
            span {
              color: #f16e26;
            }
          }
        }
      }
    }

    .paysystem {
      position: relative;
      cursor: pointer;
      padding: 35px 0;
      text-align: center;
      i.icon-order-checked {
        position: absolute;
        top: 20px;
      }
      .paysystem-name {
        margin-top: 20px;
      }
      .paysystem-description {
        margin-top: 10px;
        color: #e89191;
      }
    }

    .summary {
      padding: 0 36px;
      header {
        padding: 25px 0;
        text-align: center;
      }
      > .grid {
        > .row {
          width: 50%;
          .icon-wrapper {
            margin-bottom: 22px;
            justify-content: space-between;
            i.icon {
              margin-right: 15px;
            }
            &.bonus-container {
              justify-content: flex-end;
              i.icon {
                margin-right: 0;
              }
            }
            span {
              flex-basis: 85%;
            }
          }
          &:first-child {
            > .grid {
              border-right: 1px solid #ededed;
              > .row {
                width: 50%;
              }
            }
          }
          &:last-child {
            > .grid {
              justify-content: center;
              > .row {
                width: 48%;
              }
            }
          }
        }
      }
      .VueCarousel {
        margin-bottom: 30px;
      }
      .cart-item {
        .names {
          p {
            font-size: 14px;
          }
        }
      }
      .paysystem {
        cursor: default;
      }
      .totals {
        table:nth-of-type(2) {
          border-color: #ededed;
        }
      }
    }
    .offer-text {
      margin: 18px 0;
      font-size: 11px;
      text-align: center;
      a {
        @include default-link();
      }
    }
    .confirm {
      //margin-top: 80px;
    }

    .pickup-address-description {
      padding: 9px;
      margin-top: 10px;
      background: #87e49b;
      color: #3b3c44;
    }
  }
}

#pickup-map {
  margin: 20px 0;
  width: 100%;
  height: 280px;
}

@include block('bonus-hint') {
  @include element('mark') {
    font-weight: bold;
    color: #e74c3c;
  }
}