@include media-breakpoint-down(md) {
  .contacts-page {
    padding: 15px;
  }
}

@include media-breakpoint-down(sm) {
  .contacts-page {
    .grid {
      .row {
        flex-basis: 50%;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .contacts-page {
    .grid {
      .row {
        flex-basis: 100%;
      }
    }
  }
}