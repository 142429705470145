.breadcrumbs {
  margin-top: 10px;
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      border: 1px solid #ececec;
      &:not(:last-child) {
        border-right: none;
      }

      a,
      > span {
        display: flex;
        align-items: center;
        height: 40px;
      }
      a {
        @include default-link($secondary-color);
        padding: 0 44px 0 22px;
        background: #fff url("/build/images/breadcrumb-bg.png") 90% 50% no-repeat;
      }
      > span {
        padding: 0 20px;
      }

      &.home,
      &.more {
        background: #fff;
        a {
          padding: 0 20px;
          background: #fff;
        }
      }
    }
  }
}