.delivery-page {
  @include page_header;
  padding-bottom: 40px;

  &-container {
    > header {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      margin: 20px 0;
    }

    .search-form {
      margin: 20px 0;
      justify-content: center;
      align-items: center;
      .form-group {
        padding: 0 35px;
        margin-bottom: 0;
      }

      .custom-select {
        width: 315px;
        .current-value {
          padding: 0 10px;
        }
        .dropdown {
          background: #ececec;
          .form-control {
            background: #fff;
            text-align: center;
          }
        }
        ul {
          li {
            padding: 0 10px;
          }
        }
      }
    }

    .deliveries,
    .paysystems {
      justify-content: center;
      flex-wrap: wrap;
      .row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 232px;
        height: 232px;
        border: 1px solid #d9d9d9;
        p {
          text-align: center;
          margin-bottom: 18px;
        }

      }
    }

    .deliveries {
      .row {
        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.26);
        }
      }
    }
  }
}

.modal-delivery {
  .modal-content {
    width: 850px;
    text-align: center;
    .icon-container {
      margin: 60px 0 33px 0;
    }
    .label {
      margin-bottom: 55px;
    }
  }
}