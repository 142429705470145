.product-page {
  padding-top: 15px;

  .stock-single-item {
    margin-bottom: 45px;
  }

  > header {
    text-align: center;
    margin-bottom: 36px;
    font-size: inherit;
    span {
      display: block;
      &.product-name {
        font-size: 16px;
        color: $secondary-color;
        margin-bottom: 8px;
      }
      &.original-name {
        font-size: 14px;
        color: #a4a4a4;
      }
    }
  }
  header.title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }

  @include block('unavailable') {
    margin: 24px 0;
    @include element('text') {
      color: #333333;
      font-size: 18px;
      min-height: 32px;
      line-height: 32px;
      font-weight: bold;
    }

    @include element('last-price') {

    }
  }

  > .grid {
    .row {
      &.properties {
        //flex-grow: 1;
        width: 390px;
        .brand-logo {
          margin-bottom: 22px;
        }
        .properties-list {
          > div {
            margin-bottom: 14px;
            span {
              font-weight: 600;
            }
            ul {
              li:before {
                display: inline;
                content: '- ';
              }
            }
          }
        }
      }
      &.image {
        width: 369px;
        margin-right: 37px;
        position: relative;
        .label-container {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          .tooltip {
            width: 150px;
            .tooltip-inner {
              font-size: 14px;
            }
          }
        }
        .VueCarousel,
        .VueCarousel-wrapper,
        .VueCarousel-inner {
          height: inherit;
        }
        .VueCarousel {
          height: 369px;
          .VueCarousel-slide {
            a {
              @include image(100%, 100%);
              //display: flex;
              //width: 100%;
              //height: 100%;
              //img {
              //  margin: auto;
              //}
            }
          }
        }
        .stock-block {
          margin-top: 40px;
          border: 1px solid #ececec;
          padding: 19px 12px;
          &.grid {
            align-items: center;
            justify-content: flex-start;
            .icon-row {
              margin-right: 18px;
            }
          }
          a {
            @include default-link();
            span {
              display: block;
              color: $secondary-color;
              &.title {
                color: #333;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      &.actions {
        width: 370px;
        .info {
          flex-wrap: wrap;
          align-items: center;
          .row {
            width: 50%;
            padding: 8px 0;
            &:nth-of-type(2n) {
              a.icon-wrapper {
                justify-content: flex-end;
              }
            }
            &.code {
              .code-inner {
                display: inline;
                //background: #ee8759;
                padding: 8px 16px;
                //color: #fff;
                border: 1px dashed #ffaf8b;
                span {
                  font-weight: bold;
                }
              }
            }
            a.icon-wrapper {
              @include default-link($secondary-color);
              span {
                margin-left: 10px;
              }
            }
          }
        }
        .discontinued-text {
          margin-top: 70px;
          span {
            display: inline-block;
            border: 1px solid #e89191;
            color: #e89191;
            padding: 12px 18px;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .store-info {
          font-size: 14px;
          padding: 7px;
        }
        .discount-reason-block {
          margin-top: 20px;
          span {
            margin-left: 17px;
          }
        }
        .product-without-discount {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #ececec;
          margin-top: 20px;
          padding: 10px 0;
          > div {
            margin-left: 10px;
            a {
              @include default-link(#4998cc);
            }
            p {
              text-align: center;
              padding-top: 2px;
              span {
                font-weight: 600;
                font-size: 17px;
              }
            }
          }
        }
        .buy-container {
          margin-top: 14px;
          button {
            width: 48%;
          }
        }
        .info-block {
          margin-top: 17px;
          align-items: center;
          justify-content: flex-start;
          > .row:first-child {
            width: 70px;
          }
          p {
            color: $secondary-color;
            &.title {
              color: #333;
              margin-bottom: 12px;
            }
            span {
              color: #78a664;
            }
          }
          i.icon-product-payment {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .alternative-products {
    margin-top: 50px;
    > .grid {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .description-title {
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    h2 {
      font-weight: normal;
      color: #c2c2c2;
      font-size: 15px;
    }
  }
  .seo-categories {
    margin-bottom: 30px;
    @include element('title') {
      font-weight: bold;
      margin-bottom: 8px;
    }
    @include element('link') {
      @include default-link($link-secondary-color);
      padding-right: 15px;
    }
  }
  .scroll-spy-container {
    margin-top: 40px;
    position: relative;
    padding-right: 410px;
    header.title {
      text-align: left;
      margin-bottom: 20px;
    }
    .spy-block {
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s all ease;
      background: #fff;
      z-index: 2;

      @include block('offers-chooser') {
        margin: 14px 0 0;
      }

      @include block('product-price') {
        margin: 14px 0;
      }
    }
    .product-spy-content {
      width: 390px;
      border: 7px solid #ececec;
      padding: 10px 14px;
      a.icon-wrapper {
        @include default-link($secondary-color);
        span {
          margin-left: 10px;
        }
      }
      .header {
        margin-top: 6px;
        margin-bottom: 18px;
      }
      .grid {
        .image {
          $w: 120px;
          width: $w;
          height: $w;
          text-align: center;
          margin-right: 15px;
          flex-shrink: 0;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: auto;
          }
        }
        .names {
          flex-grow: 1;
          p {
            margin-bottom: 8px;
          }
          .product-name {
            color: $secondary-color;
          }
          .original-name {
            font-size: 13px;
            color: #a4a4a4;
          }
          .code {
            span {
              font-weight: bold;
            }
          }
        }
      }
      .buy-container {
        button {
          width: 48%;
        }
      }
      @include block('unavailable') {
        margin: 14px 0;
      }
      .discount-reason-block {
        margin-top: 20px;
        span {
          margin-left: 17px;
        }
      }
      .discontinued-text {
         margin-top: 20px;
         span {
           display: inline-block;
           border: 1px solid #e89191;
           color: #e89191;
           padding: 12px 18px;
           font-size: 18px;
           font-weight: bold;
         }
       }
    }
  }

  .description {
    a {
      @include default-link($link-secondary-color);
    }
    p {
      margin-bottom: 20px;
    }
    //margin-bottom: 40px;
  }

  .seo-h1 {
    font-size: 18px;
  }

  @include block('product-warning') {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px dashed #ff002b;
    
    @include element('header') {
      color: #ee8759;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  .reviews {
    > .grid {
      width: 100%;
      .row.comments {
        padding-left: 90px;
        width: 100%;
        p.not-found {
          margin-bottom: 20px;
        }
      }
    }
    .stats {
      .rating-container {
        > div {
          margin: 8px 0;
        }
        .recommendation {
          margin: 0 0 20px;
        }
        .centered {
          display: flex;
          justify-content: center;
        }
        .rating {
          .grid {
            margin: 8px 0;
            > .row:nth-of-type(2) {
              margin: 0 16px;
            }
          }
          .progress-bar {
            width: 193px;
            height: 6px;
            background: #ececec;
            .indicator {
              background-color: #4998cc;
            }
          }
        }
        button {
          display: block;
          margin: 21px auto 0;
          padding: 12px 35px;
          background-color: #ececec;
          &:hover {
            background-color: #e6e6e6;
          }
        }
      }
    }
    .list {
      &.is-loading {
        opacity: 0.3;
      }
      margin-top: 40px;
      .loader {
        padding: 30px 0;
      }
      .review-item {
        .answers {
          .show-more {
            padding: 20px 0 0 30px;
            .icon-wrapper {
              i.icon {
                margin-left: 12px;
                transition: 0.3s all ease;
                display: block;
              }
            }
            a {
              @include default-link(#4998cc);
            }
            &.is-active {
              i.icon {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    .pagination {
      margin: 30px 0;
    }
  }

  &.is-discontinued {
    > .grid {
      .row {
        &.actions {
          .info {
            .row {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @include block('offers-chooser'){
    margin: 24px 0;
    @include block('simple') {
      @include element('list') {
        justify-content: flex-start;
      }
      @include element('list-item') {
        margin-right: 5px;
      }
    }
    @include block('extended') {
      margin: 0;
    }
  }

  @include block('product-bonus') {
    margin-left: 16px;
    @include element('value') {
      font-size: 13px;
    }
  }

  @include block('product-price') {
    @include modifier('large') {
      min-height: 33px;
    }
  }

  .product-review-tabs {
    .tabs {
      margin-bottom: 20px;
      ul {
        li {
          &.is-active {
            a {
              background: $light-grey-button-color;
              border-color: transparent;
            }
          }
          a {
            @include default-link();
            border: 1px solid $light-grey-button-color;
            display: inline-block;
            font-weight: bold;
            font-size: 14px;
            padding: 15px 35px;
            span {
              background: #ec8b38;
              padding: 4px;
              border-radius: 100%;
              color: #fff;
              height: 25px;
              width: 25px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

@include block('product-list') {
  margin-bottom: 60px;
  @include modifier('similar') {
    margin-bottom: 20px;
    @include block('product-list') {
      @include element('title') {
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }
  @include element('title') {
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  @include element('content') {
    display: flex;
    flex-wrap: wrap;
  }
}

.modal-zoom {
  .modal-content {
    width: 880px;
    //height: 880px;
    background: #fff;
    padding: 46px 46px 0;
    .zoomer {
      width: 480px;
      height: 480px;
      margin: 0 auto;
    }
    > .header {
      margin-bottom: 20px;
      .names {
        width: 470px;
        h2 {
          font-size: 19px;
          color: $main-color;
          margin-bottom: 12px;
        }
        h3 {
          font-size: 14px;
          color: #a4a4a4;
        }
      }
      .actions {
        align-items: center;
        .price {
          margin-right: 22px;
          font-size: 19px;
          color: #333;
          padding: 5px 15px;
          text-align: center;
          span {
            font-weight: bold;
          }
          &.has-discount {
            border: 1px solid #e19dab;
            color: #e19dab;
            font-size: 17px;
          }
        }
        .btn-buy {
          width: 160px;
        }
      }
    }
    .zoomer-carousel {
      width: 420px;
      margin: 0 auto;
      padding: 20px 0;
      .VueCarousel-slide {
        a {
          border: 1px solid transparent;
          &.active {
            border-color: #f4f4f4;
          }
          @include image(80px, 80px);
        }
      }
    }
  }
}

.modal-add-review {
  .modal-content {
    width: 860px;
    > header {
      margin: 5px 0 15px;
      p {
        margin: 0;
        text-align: center;
      }
    }
    form {
      .stars-rating {
        justify-content: center;
        li {
          background: url('/build/images/stars_sprite_big.png') no-repeat -22px 0;
          width: 20px;
          height: 20px;
          &.is-active {
            background-position: -1px 0;
          }
        }
      }
      .rating-container {
        * {
          text-align: center !important;
        }
      }
      .grid {
        flex-wrap: wrap;
        .row {
          flex-basis: 48%;
        }
      }
      .form-group {
        * {
          text-align: left;
        }
        label {
          padding: 10px 5px;
        }
        .form-control {
          &:not(.custom-select) {
            padding: 0 20px;
          }
          &.custom-select {
            .current-value {
              padding: 0 20px;
            }
          }
          background: #fff;
          //text-align: left;
        }
        textarea {
          height: 120px;
          &.form-control {
            padding: 20px !important;
          }
          &[name="content"] {
            height: 122px;
          }
        }
      }
      .custom-select {
        .dropdown {
          background-color: #fff;
          border: 1px solid #e9e9e9;
          .form-group {
            padding: 7px;
            .form-control {
              border: 2px solid #ececec;
            }
          }
          ul {
            background: none;
            li {
              padding: 5px 20px;
              //font-size: 15px;
              color: $secondary-color;
              &:hover {
                background-color: #dfdfdf;
              }
              &.is-active {
                background-color: #daeaf5;
              }
            }
          }
          .empty-result {
            padding: 5px 20px;
          }
        }
      }
      a.recaptcha {
        @include default-link($link-secondary-color);
      }
      .link {
        @include default-link(#4998cc);
        margin-left: 22px;
        text-decoration: underline;
        padding: 5px 0;
        i.icon {
          margin-left: 10px;
        }
      }
      .files {
        input[type="file"] {
          display: none;
        }
        .progress-bar {
          width: 100%;
          height: 5px;
          background: #ececec;
          .indicator {
            background-color: #4998cc;
          }
        }
        .images {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          a {
            @include image(70px, 70px);
            position: relative;
            margin: 5px;
            .remove-container {
              z-index: 2;
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.7);
              .icon {
                position: absolute;
                top: calc(50% - 10px);
                left: calc(50% - 10px);
              }
            }
          }
        }
      }
      .extra-data {
        justify-content: flex-start;
        margin-top: 10px;
        .row {
          flex-basis: 180px;
        }
      }
      .actions {
        align-items: flex-end;
        .form-group {
          margin-bottom: 0;
          button {
            text-align: center;
            &.is-active {
              background: #e3e3e3;
            }
            i.icon {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}