@include media-breakpoint-down(lg) {
  .product-page {
    padding: 30px 15px 0 15px;

    > .grid .row.actions {
      .info {
        .row {
          width: 100%;
          &:nth-of-type(2n) a.icon-wrapper {
            justify-content: flex-start;
          }
        }
        .wish,
        .ask {
          order: 2;
        }
      }
    }

    .reviews {
      > .grid .row.comments {
        padding-left: 30px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .product-page {
    > .grid {
      flex-wrap: wrap;
      justify-content: space-around;
      .row {
        &.properties {
          width: 100%;
          order: 2;
          .properties-list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            > div {
              width: 50%;
            }
          }
          .brand-logo {
            margin: 30px 0;
          }
        }
        &.actions {
          width: 33%;
          .buy-container button {
            width: 100%;
          }
        }
        &.image {
          width: 50%;
        }
      }
    }

    .scroll-spy-container {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-only(lg) {
  .product-page {
    .reviews {
      .stats .rating-container .rating .progress-bar {
        width: 150px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .product-page {
    > .grid {
      .row {
        &.actions,
        &.image {
          width: 100%;
        }

        &.image {
          margin-right: 0;
        }

        &.actions {
          .info {
            margin: 10px 0;
            .row {
              width: 50%;
              &.code {
                text-align: right;
              }
              &.ask {
                a.icon-wrapper {
                  justify-content: flex-end;
                }
              }
            }
          }
        }

        &.properties {
          .brand-logo {
            text-align: center;
          }
        }
      }
    }

    .reviews > .grid {
      flex-wrap: wrap;
      justify-content: center;
      .row {
        &.comments {
          padding-left: 0;
        }
        .rating-container {
          margin-bottom: 20px;
        }
      }
    }

    h5 {
      text-align: center;
    }
  }

  .product-page .description-title,
  .product-page .scroll-spy-container h4 {
    text-align: center;
  }

  .modal-add-review .modal-content form {
    .grid {
      flex-wrap: wrap;
      .row.form-group {
        flex-basis: 100%;
      }

      &.actions {
        > .row:last-child {
          margin: 20px 0;
          flex-basis: 100%;
        }
      }
    }

    .extra-data .row {
      flex-basis: auto;
    }
  }

  .modal-zoom .modal-content {
    > .header {
      flex-wrap: wrap;
      .actions {
        margin-top: 10px;
      }
    }
    .zoomer {
      width: 460px;
      height: 460px;
    }
    .zoomer-carousel {
      width: auto;
    }
  }
}

@include media-breakpoint-down(xs) {
  .product-page > .grid .row {
    &.image .VueCarousel {
      height: 269px;
    }
    &.actions .info .row {
      &.code {
        width: 100%;
        text-align: left;
        margin: 10px 0;
      }
    }
    &.properties .properties-list {
      > div {
        width: 100%;
      }
    }
  }

  .modal-zoom .modal-content {
    .zoomer {
      width: 320px;
      height: 320px;
    }
  }
}
