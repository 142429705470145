.tabs {
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 100%;
      text-align: center;
      a {
        display: block;
      }
    }
  }
}
.tabs-content {

}