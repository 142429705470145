@import "../components/slider";

.slider-content {
  .VueCarousel {
    overflow: hidden;
    &:hover {
      .VueCarousel-navigation-button {
        display: block;
      }
    }
    .VueCarousel-navigation-button {
      display: none;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.4 35.4'%3E%3Cstyle%3E.a%7Bfill:%23010101%7D%3C/style%3E%3Cpath class='a' d='M27.1 17L9.9 0 8.4 1.4l16.3 16.3L8.4 34l1.6 1.4 17.1-17z'/%3E%3C/svg%3E") no-repeat 2px 16px;
      height: 76px;
      width: 45px;
      text-indent: -9999px;
      top: 50%;
      z-index: 5;
      &:not(.VueCarousel-navigation--disabled):hover {
        background-color: rgba(255,255,255,0.75);
      }

      &.VueCarousel-navigation-prev {
        transform: rotate(180deg);
        margin-top: -40px;
      }

      &.VueCarousel-navigation-next {
        right: 45px;
      }

      &.VueCarousel-navigation--disabled {
        opacity: 0.2;
      }
    }
  }
}

.main-tabs {
  margin-bottom: 32px;
  .tabs {
    background-color: #ececec;
    ul {
      padding: 7px 15px 0 15px;
      margin-top: 12px;
      > li {
        &.is-active {
          background: #fff;
          box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 1), 0px 0px 4px 0px rgba(35, 77, 156, 0.1);
          a {
          }
        }
        a {
          padding: 12px 0 21px 0;
          font-size: 15px;
          color: #333333;
          text-decoration: none;
        }
      }
    }
  }
  .tabs-content {
    padding: 16px 0;
    .tab-name {
      background-color: #f3f3f5;
      text-align: center;
      padding: 26px 0;
      color: #555555;
      border-top: 1px solid #e6e6e8;
      margin-bottom: 18px;
      font-size: 20px;
    }
    .VueCarousel {
      margin: 0 $gutter;
      .VueCarousel-wrapper {
        margin: 0px 0 -150px 0;
        padding: 0px 0px 150px 0;
        z-index: 1;
        .VueCarousel-slide {
          padding-top: 10px;
        }
        .VueCarousel-inner {
          max-height: $product-item-height;
        }
      }
      .VueCarousel-navigation-button {
        background: url('/build/images/vue-slider-arrows.png') no-repeat 0 0;
        width: 14px;
        height: 24px;
        text-indent: -9999px;
        top: 40%;
        z-index: 5;
        &.VueCarousel-navigation-prev {
          background-position: -1px -1px;
        }
        &.VueCarousel-navigation-next {
          background-position: -20px -1px;
        }
        &.VueCarousel-navigation--disabled {
          opacity: 0.2;
        }
      }
      .product-wrapper {
        margin: 0 auto;
      }
    }
  }
  .btn-info {
    height: 47px;
    line-height: 47px;
    font-size: 15px;
    color: #333333;
  }
  .tabs-content {
    .show-more {
      width: 270px;
      margin: 0 auto;
      min-height: $product-item-height;
      height: 100%;
      text-align: center;
      display: flex;
      .icon-wrapper {
        width: 64px;
        height: 64px;
        margin: 0 auto 8px auto;
        border-radius: 50%;
        justify-content: center;
      }
      &.new {
        .icon-wrapper {
          background-color: #8dc973;
        }
      }
      &.top {
        .icon-wrapper {
          background-color: #8bc3e8;
        }
      }
      &.stock {
        .icon-wrapper {
          background-color: #8d4e98;
        }
      }
      > a {
        display: block;
        margin: auto;
        font-size: 16px;
        color: $secondary-color;
        font-weight: 600;
        line-height: 26px;
        text-decoration: none;
      }
    }
  }
}