@include media-breakpoint-down(sm) {
  @include block('review-item') {
    @include element('name') {
      flex-basis: 140px;
    }
    @include element('header') {
      flex-wrap: wrap;
    }
    @include element('recommendations') {
      order: 3;
      margin-top: 10px;
      width: 100%;
    }
  }
}

@include media-breakpoint-down(xs) {
  @include block('review-item') {
    @include element('name') {
      flex-basis: 140px;
    }
    @include element('recommendations-content') {
      justify-content: space-between;
    }
    @include element('recommendations-row') {
      padding: 0;
    }
    @include element('parameters') {
      flex-wrap: wrap;
    }
    @include element('parameters-row') {
      width: 100%;
    }
    @include element('footer') {
      .grid {
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    @include element('recommendation-label') {
      order: 3;
      text-align: center;
      margin-top: 10px;
      padding-right: 0;
    }
  }
}