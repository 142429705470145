@include media-breakpoint-up(sm) {
  @include block('product-item') {
    @include element('hidden') {
      .btn-small {
        font-size: 13px !important;
      }
    }
  }

  .product-wrapper {
    .product-item {
      .label-offer-container {
        position: absolute;
        top: 5px;
        left: 5px;
        justify-content: center;
        width: 100%;
        .label {
          color: #fff;
          padding: 6px 19px;
          font-weight: 300;
          &.label-discount {
            &.black-friday {
              padding-left: 5px;
              $r: 80px;
              background: url('/build/images/bf_discount_bg.png') no-repeat;
              height: $r;
              width: $r;
              line-height: $r;
              font-weight: bold;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  @include block('product-item') {
    @include element('names') {
      height: auto;
    }
    @include element('hidden') {
      .buy-container {
        .btn-buy {
          padding: 12px 10px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  @include block('product-wrapper') {
    width: 45%;
    min-height: auto;
  }

  @include block('product-item') {
    @include element('background') {
      border-width: 4px;
    }
    @include element('image') {
      height: 220px;
    }
    @include element('alias-name') {
      font-size: 11px;
    }
    @include element('hidden') {
      .buy-container-wrapper {
        width: 80%;
      }
      .buy-container {
        width: 100%;
        margin: 8px 0;
        .icon-wrapper {
          display: none;
        }
      }
      .available-reminder-container .btn {
        width: 100%;
      }
    }

    @include modifier('average') {
      @include block('product-item') {
        @include element('image') {
          height: 140px;
        }
      }
    }
  }

  @include block('product-bonus') {
    display: none;
  }

  @include block('product-price') {
    font-size: 14px;
  }

  .offers-chooser {
    margin-top: 7px;
  }

  .product-wrapper {
    .product-item {
      .label-offer-container {
        .label {
          font-size: 14px;
          padding: 9px 19px;
        }
      }
      .rating-widget {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;
        a {
          margin-left: 0;
        }
      }
    }
  }
  .modal-offer-choose {
    .modal-content {
      padding: 12px 16px;
      .modal-close {
        top: 13px;
      }
      .modal-title {
        flex-basis: 100%;
        flex-wrap: wrap;
        padding: 0;
        .row {
          flex-basis: inherit;
          &.names {
            //flex-basis: inherit;
            flex-basis: 94%;
            h3 {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 0;
            }
          }
          &.info {
            padding-top: 5px;
            margin-right: 0;
            display: flex;
            justify-content: inherit;
            .stars {
              order: -1;
            }
          }
        }
      }
      .chooser {
        flex-wrap: wrap;
        .preview,
        .list-horizontal {
          flex-basis: 100%;
        }
        .list-horizontal {
          ul {
            padding: 10px 7px;
          }
        }
        .preview {
          height: 269px;
          text-align: center;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .product-wrapper {
    .product-item {
      &.is-active {
        .hidden {
          height: auto;
        }
      }
      .hidden {
        height: 35px;
        .offers-chooser {
          margin-top: 6px;
        }
      }
    }
  }
  .modal-offer-choose {
    .modal-content {
      .modal-title {
        .row {
          &.names {
            h3 {
              font-size: 12px;
              font-weight: bold;
              margin-bottom: 0;
            }
            h4 {
              font-size: 11px;
            }
          }
        }
      }
      .chooser {
        .preview {
          height: 180px;
          img {
            max-width: 118px;
          }
        }
      }
    }
  }
}