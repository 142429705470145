@include block('offers-chooser') {
  flex-basis: 100%;
  margin-top: 14px;
  font-size: 13px;
  color: $secondary-color;
  @include block('simple') {
    @include element('list') {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
    }
    @include element('list-item') {
      padding: 9px 15px;
      &:hover {
        cursor: pointer;
      }

      @include state('active') {
        background: #eeeeee;
      }
      @include state('disabled') {
        color: $secondary-color;
        opacity: 0.5;
      }

    }
  }
  @include block('extended') {
    $btn-width: 38px;
    position: relative;
    margin: 0 auto;
    max-width: 193px;
    height: 32px;
    line-height: 32px;
    padding: 0 7px;
    border: 1px solid #eeeeee;
    overflow: hidden;
    @include element('overlay') {
      position: absolute;
      top: 0;
      right: $btn-width;
      width: 70px;
      height: 100%;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
    @include element('btn-choose') {
      position: absolute;
      top: 0;
      right: 0;
      width: $btn-width;
      height: 100%;
      line-height: 0;
      border-left: 1px solid #eeeeee;
      background: #fff;
      &:hover {
        background: #eeeeee;
      }

      &.icon-wrapper {
        justify-content: center;
      }
    }
  }
}