@import "../variables";

a.btn {
  display: inline-block;
}
.btn {
  outline: none;
  border: none;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  line-height: normal;
  padding: 12px 10px;
  &:hover {
    cursor: pointer;
  }
  //&:not(.btn-block) {
  //  padding: 0 10px;
  //}
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.btn-small {
    padding: 9px 10px;
    font-size: 12px;
  }
  &.btn-medium {
    padding: 12px 10px;
  }
  &.btn-big {
    padding: 15px 10px;
    font-size: 15px;
  }
  &.btn-primary {
    background: $primary-button-color;
    &:hover {
      background: $primary-button-color-hover;
    }
  }
  &.btn-secondary {
    background: $secondary-button-color;
    &:hover {
      background: $secondary-button-color-hover;
    }
  }
  &.btn-danger {
    background-color: $danger-button-color;
    &:hover {
      background-color: $danger-button-color-hover;
    }
  }
  &.btn-info {
    background-color: $info-button-color;
    &:hover {
      background-color: $info-button-color-hover;
    }
  }
  &.btn-orange {
    background-color: $orange-button-color;
    &:hover {
      background-color: $orange-button-color-hover;
    }
  }
  &.btn-grey {
    color: $secondary-color;
    background-color: $grey-button-color;
    &:hover {
      background-color: $grey-button-color-hover;
    }
  }
  &.btn-light-grey {
    color: #767676;
    background-color: $light-grey-button-color;
    &:hover {
      background-color: $light-grey-button-color-hover;
    }
  }
  &.btn-inverse {
    background: $inverse-button-color;
    color: $main-color;
    &:hover {
      color: #fff !important;
      background-color: $inverse-button-color-hover;
    }
  }
  &.btn-link {
    color: $main-color;
    background: none;
    font-family: inherit;
    &:hover {
      background: none;
    }
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
      //background-color: inherit;
    }
  }
}