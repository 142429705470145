.signup-page {
  width: 566px;
  margin: 0 auto;
  padding-bottom: 192px;
  .man-in-the-black {
    position: relative;
    margin: 30px 73px 15px 73px;
    height: 28px;
    background: url('/build/images/dot_small_bg.jpg') center center repeat-x;
    .grid {
      height: 100%;
      align-items: center;
    }
    .dot {
      width: 13px;
      height: 13px;
      background: #969696;
      border-radius: 100%;
    }
    .man {
      position: absolute;
      top: 0;
      background: #fff;
      transition: .7s all ease-in-out;
      transform: translateX(0);
    }
  }
  h4 {
    font-size: 15px;
  }

  .vue-form-wizard {
    .wizard-navigation {
      .wizard-nav {
        justify-content: space-between;
        li {
          display: flex;
          &:not(:last-child) {
            flex-grow: 1;
          }
          a {
            display: block;
            width: 161px;
            height: 45px;
            line-height: 45px;
            background: #ececec;
            color: $secondary-color;
            text-align: center;
          }
          &.active a {
            background: $primary-button-color;
            color: #fff;
          }
          &:not(:last-child):after {
            display: block;
            content: '';
            background: url('/build/images/steps_right.png');
            width: 8px;
            height: 14px;
            margin: auto;
          }
        }
      }
    }
  }

  form {
    padding-top: 52px;
    .form-group {
      margin-bottom: 12px;
      label:not(.checkbox) {
        font-size: 16px;
        font-weight: 300;
        margin: 20px 0;
        text-align: center;
      }
      .form-control {
        text-align: center;
      }
      .help-block-error {
        text-align: center;
      }
    }
    a {
      @include default-link();
      &.link {
        color: $secondary-color;
        border-bottom: 1px solid #c2c2c2;
        &.support-link {
          display: inline-block;
          margin: 15px 25px;
        }
      }
    }

    .custom-checkbox {
      margin-top: 35px;
      .checkbox {
        width: 24px;
        height: 24px;
        //background: #cecece;
        background: rgba(206, 206, 206, 0.4);
        border-radius: 2px;
        &.is-checked {
          background: #8fc875;
        }
      }
      > span {
        .icon-wrapper {
          span {
            padding: 0 15px;
            font-size: 12px;
            color: $secondary-color;
          }
        }
      }
    }

    .links {
      margin-top: 20px;
    }

    .send-code {
      .row:nth-of-type(1) {
        padding: 0 25px;
      }
      .row:nth-of-type(2) {
        flex-grow: 1;
        .grid {
          .row {
            padding: 0 15px;
          }
        }
      }
    }

    .support-text {
      text-align: center;
      margin: 23px 0;
      a {
        margin-left: 10px;
      }
    }
  }
}

.modal-support-call {
  .modal-content {
    width: 400px;
  }
}