@include media-breakpoint-down(md) {
  .signup-page {
    margin-top: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .signup-page {
    width: auto;
    padding: 15px;
    .vue-form-wizard .wizard-navigation .wizard-nav {
      justify-content: center;
      li {
        &:not(:last-child) {
          flex-grow: 0;
          &:after {
            margin: auto 15px;
          }
        }
        a {
          width: auto;
          height: auto;
          line-height: normal;
          padding: 15px;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .signup-page {
    .man-in-the-black {
      display: none;
    }
    .wizard-navigation {
      margin-top: 15px;
    }
    form .links {
      flex-wrap: wrap;
      .row {
        width: 100%;
        &:not(:empty) {
          margin: 5px 0;
        }
        &:nth-of-type(1) {
          padding: 0;
        }
        &:nth-of-type(2) {
          .grid {
            .row {
              padding: 0;
            }
          }
        }
        .grid {
          flex-wrap: wrap;
          .row {
            width: 100%;
          }
        }
      }
    }
  }
}